import React, { useState, useEffect, useRef } from 'react';
import Lead from '../leads/Lead';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {  Select, SideNav, SideNavItem, Button } from 'react-materialize';
import { connect } from 'react-redux';
import firebase from '../../config/fbConfig';
import { updateLeadStatus } from '../../store/actions/leadActions';
import linkedinlogo from '../../img/linkedinlogo.png';

const NewLeadsTable = ({ contacts, userProfile, updateState, updateLead, auth, CRM }) => {

  const [update, setUpdate] = useState(0)

  const handleSelect = (e, contact) => {
    const target = {
        id: e.target.id,
        value: e.target.value
    }
    updateLead(target, contact);
  }

  const refreshToken = userProfile.refresh_token; //Salesforce Refresh Token

  const [hubspotDetails, setHubspotDetails] = useState({
    refreshToken: '',
    accessToken: '',
    expiresAt: ''
  })

  const uid = useRef('')

  useEffect(() => {
    uid.current = auth.uid
  }, [])

  useEffect(() => {
    setHubspotDetails({
      refreshToken: userProfile.hubspotRefreshToken,
      accessToken: userProfile.hubspotAccessToken,
      expiresAt: userProfile.expiresAt
    })
  }, [userProfile])

  const data = contacts ? contacts.map(item => {
    if (item.emailAddress === '') {
      item.emailAddress = item.mailFromHunter;
    }
    return item
  }).filter(item => {
    //Only show if replied and connection accepted.
    return (item.replyReceived === 'Yes' || item.didAcceptConnection === 'Yes')
  }).sort((a, b) => {
    const x = a.lastName.toLowerCase();
    const y = b.lastName.toLowerCase();
    if (x < y) {return -1;}
    if (x > y) {return 1;}
    return 0;
  }) : []

  // var contactsList = _.map(contacts, function(item) {
  //   if (item.emailAddress === '') {
  //     item.emailAddress = item.mailFromHunter;
  //   }
  //   if (item.replyReceived === 'Yes' || item.didAcceptConnection === 'Yes')
  //     return item;
  // });

  // // Remove undefines from the array
  // contactsList = _.without(contactsList, undefined);

  // const data = contactsList;

  const columns = [
    {
      Header: () => <b>Connection Date</b>,
      accessor: 'connectionDate'
    },
    {
      Header: () => <b>First Name</b>,
      accessor: 'firstName'
    },
    {
      Header: () => <b>Last Name</b>,
      accessor: 'lastName'
    },
    {
      Header: () => <b>Company</b>,
      accessor: 'company'
    },
    { Header: () => <b>Title</b>, accessor: 'jobTitle' },
    // { Header: 'Second Title', accessor: 'jobTitle2' },
    {
      Header: () => <b>Email</b>,
      accessor: 'emailAddress',
      Cell: props => (
        <a href={`mailto:${props.original.emailAddress}`}>{props.original.emailAddress}</a>
      )
    },
    // {
    //   Header: 'Phone',
    //   accessor: 'firstPhone'
    // },
    {
      Header: () => <b>Status</b>,
      accessor: 'leadStatus',
      Cell: props => (
            <div className="input-field">
              <Select
                id="leadStatus"
                name="leadStatus"
                onChange={e => handleSelect(e, props.original.id)}>
                <option
                  value={props.original.leadStatus || 'No Status'}
                  >
                  {props.original.leadStatus || 'No Status'}
                </option>
                <option value="No Status">No Status</option>
                <option value="Hot">Hot</option>
                <option value="Warm">Warm</option>
                <option value="Cold">Cold</option>
                <option value="Meeting Booked">Meeting Booked</option>
                <option value="Replied">Replied</option>
                <option value="Nurture">Nurture</option>
                <option value="Not Interested">Not Interested</option>
                <option value="Do not contact">Don't Contact</option>
              </Select>
            </div>
        )
    },
    {
      Header: () => <b>Contact Type</b>,
      accessor: 'contactType',
      Cell: props => (
          <div className="input-field">
            <Select
              id="contactType"
              onChange={e => handleSelect(e, props.original.id)}>
              <option
                value={props.original.contactType || 'Prospect'}
                >
                {props.original.contactType || 'Prospect'}
              </option>
              <option value="Prospect">Prospect</option>
              <option value="Customer">Customer</option>
              <option value="Personal">Personal</option>
              <option value="Vendor Booked">Vendor Booked</option>
              <option value="Partner">Partner</option>
              <option value="Competitor">Competitor</option>
            </Select>
          </div>
      )
      // Cell: props => (
      //   <Select
      //     id="contactType"
      //     name="contactType"
      //     style={{ position: 'relative', zIndex: '999' }}
      //     onChange={e => handleSelect(e, props.original.id)}>
      //     <option value={props.original.contactType || 'Prospect'} default>
      //       {props.original.contactType || 'Prospect'}
      //     </option>
      //     <option value="Prospect">Prospect</option>
      //     <option value="Customer">Customer</option>
      //     <option value="Personal">Personal</option>
      //     <option value="Vendor">Vendor Booked</option>
      //     <option value="Partner">Partner</option>
      //     <option value="Competitor">Competitor</option>
      //   </Select>
      // )
    },
    {
      Header:() => <b>LinkedIn</b>,
      accessor: 'profileUrl',
      Cell: props => (
        <div>
          <a href={props.original.profileUrl} target="_blank">
            <img src={linkedinlogo} width="30" />
          </a>
        </div>
      )
    },
    {
      Header: () => <b>Reply Received</b>,
      accessor: 'replyReceived',
      Cell: props => <div>{props.original.replyReceived || 'No'}</div>
    },
    {
      Header: () => <b>Salesforce</b>,
      accessor: 'addedToCRM',
      Cell: props => (
        <Button
          disabled={props.original.addedToCRM}
          onClick={() => {
            var contact = props.original;

            var infoForSalesForce = {
              FirstName: contact.firstName,
              LastName: contact.lastName,
              Email: contact.emailAddress,
              Phone: contact.firstPhone,
              City: contact.city,
              Street: contact.companyAddress,
              Title: contact.jobTitle,
              Description: contact.technologies,
              Company: contact.company,
              Country: contact.country
            }; //Ok let's deal with this later.

            const salesForceRefreshToken = refreshToken;

            if (salesForceRefreshToken) {
              updateState();

              var addSalesForceLead = firebase
                .functions()
                .httpsCallable('addSalesForceLead');

              addSalesForceLead({
                refresh_token: refreshToken,
                contact: infoForSalesForce
              })
                .then(function(result) {
                  firebase
                    .firestore()
                    .collection('contacts')
                    .doc(contact.id)
                    .update({ addedToCRM: true })
                    .then(() => {
                      updateState();
                      props.original.addedToCRM = true
                      setUpdate(update + 1)
                      console.log('Contact updated');
                      alert('Lead added to CRM');
                    });
                })
                .catch(error => {
                  updateState();
                  alert(error.message);
                });
            } else {
              alert('No CRM Linked');
            }
          }}
          className="btn-small blue darken-2"
          id="crm">
          {!props.original.addedToCRM && (
            <div>
              <i className="material-icons left">add_circle</i>SF
            </div>
          )}
          {props.original.addedToCRM && <div>Added</div>}
        </Button>
      )
    },
    {
      Cell: props => (
        <React.Fragment>
          <SideNav
            options={{ edge: 'right' }}
            trigger={<a className="lead-link">See More</a>}>
            <SideNavItem style={{ textAlign: 'left' }} icon="person_pin">
              {props.original.firstName + ' ' + props.original.lastName}
            </SideNavItem>
            <div style={{ textAlign: 'left', padding: '20px' }}>
              <div>
                <p>Company: {props.original.company || 'None Provided'}</p>
                <p>Title: {props.original.jobTitle || 'None Provided'}</p>
                <p>Phone: {props.original.firstPhone || 'None Provided'}</p>
                <p>
                  Work Email:{' '}
                  {props.original.emailAddress || props.original.mail}
                </p>
                <p>
                  Other Email: {props.original.otherEmail || 'None Provided'}
                </p>
                <p>Status: </p>
                <div className="input-field">
                  <Select
                    id="leadStatus"
                    onChange={e => handleSelect(e, props.original.id)}>
                    <option
                      value={props.original.leadStatus || 'No Status'}
                      >
                      {props.original.leadStatus || 'No Status'}
                    </option>
                    <option value="No Status">No Status</option>
                    <option value="Hot">Hot</option>
                    <option value="Warm">Warm</option>
                    <option value="Cold">Cold</option>
                    <option value="Meeting Booked">Meeting Booked</option>
                    <option value="Replied">Replied</option>
                    <option value="Nurture">Nurture</option>
                    <option value="Not Interested">Not Interested</option>
                    <option value="Do not contact">Don't Contact</option>
                  </Select>
                </div>
                <p>Contact Type: </p>
                <div className="input-field">
                  <Select
                    id="contactType"
                    onChange={e => handleSelect(e, props.original.id)}>
                    <option
                      value={props.original.contactType || 'Prospect'}
                      >
                      {props.original.contactType || 'Prospect'}
                    </option>
                    <option value="Prospect">Prospect</option>
                    <option value="Customer">Customer</option>
                    <option value="Personal">Personal</option>
                    <option value="Vendor Booked">Vendor Booked</option>
                    <option value="Partner">Partner</option>
                    <option value="Competitor">Competitor</option>
                  </Select>
                </div>
                <p>
                  <a href={props.original.profileUrl} target="_blank">
                    LinkedIn Profile
                  </a>
                </p>
                <p>Industry: {props.original.industry || 'None Provided'}</p>
                <p>
                  Company City: {props.original.companyCity || 'None Provided'}
                </p>
                <p>
                  Company Country:{' '}
                  {props.original.companyCountry || 'None Provided'}
                </p>
                <p>
                  Company Employees:{' '}
                  {props.original.numEmployees || 'None Provided'}
                </p>
                {/* TODO: Remember to check first if these are empty before rendering them  */}
                <p>
                  <a href={props.original.facebookUrl} target="_blank">
                    Company Facebook Page
                  </a>
                </p>
                <p>
                  <a href={props.original.twitterUrl} target="_blank">
                    Company Twitter Page
                  </a>
                </p>
              </div>
            </div>
          </SideNav>
        </React.Fragment>
      )
    }
  ];

  if (CRM.hubspot) {
    columns.splice(10, 0, {
      Header: () => <b>Hubspot</b>,
      accessor: 'addedToHubspot',
      Cell: props => (
        <Button
          disabled={props.original.addedToHubspot}
          onClick={() => {
            const contact = props.original;

            const infoForHubspot = {
              properties: [
                {
                  "property": "firstname",
                  "value": contact.firstName
                },
                {
                  "property": "lastname",
                  "value": contact.lastName
                },
                {
                  "property": "website",
                  "value": contact.companyWebsite
                },
                {
                  "property": "photo",
                  "value": contact.imgUrl
                },
                {
                  "property": "company",
                  "value": contact.company
                },
                {
                  "property": "phone",
                  "value": contact.firstPhone
                },
                {
                  "property": "address",
                  "value": contact.companyAddress
                },
                {
                  "property": "city",
                  "value": contact.city
                },
                {
                  "property": "linkedinbio",
                  "value": contact.description
                }
              ]
            }

            if (hubspotDetails.refreshToken) {
              updateState();

              const addHubspotContact = firebase
                .functions()
                .httpsCallable('addHubspotContact');

              const hubspotData = {
                refreshToken: hubspotDetails.refreshToken,
                accessToken: hubspotDetails.accessToken,
                expiresAt: hubspotDetails.expiresAt,
                userId: uid.current,
                contact: infoForHubspot,
                contactEmail: contact.emailAddress
              }
              console.log('hubspotData:', hubspotData)

              addHubspotContact(hubspotData)
                .then(() => {
                  firebase
                  .firestore()
                  .collection('contacts')
                  .doc(contact.id)
                  .update({ addedToHubspot: true })
                  .then(() => {
                    props.original.addedToHubspot = true
                    setUpdate(update + 1)
                  });
                  // updateLead({id: "addedToHubspot", value: true}, contact.id)
                })
                .catch(error => {
                  alert(error.message);
                });
            } else {
              alert('No CRM Linked');
            }
          }}
          className="btn-small orange darken-2"
          id="crm">
          {!props.original.addedToHubspot && (
            <div>
              <i className="material-icons left">add_circle</i>HS
            </div>
          )}
          {props.original.addedToHubspot && <div>Added</div>}
        </Button>
      )
    })
  }

  return (
    <div
      style={{
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: '#fff'
      }}>
      <ReactTable
        className="NLTDash"
        data={data}
        columns={columns}
        defaultPageSize={20}
        getTdProps={() => {
          return {
            style: {
              overflowY: 'visible',
            }
          }
        }}
      />
    </div>

    // Let's try a react table here..

    // <div className="leads-table">
    //   <div className="card">
    //     <table className="responsive-table centered highlight">
    //       <thead>
    //         <tr>
    //           <th>First Name</th>
    //           <th>Last Name</th>
    //           <th>Company</th>
    //           <th>Work Email</th>
    //           {/* <th>Other Email</th> */}
    //           <th>Phone</th>
    //           <th>Status</th>
    //           <th>LinkedIn</th>
    //           <th>Replies</th>
    //           {/* <th>Connection Date</th> */}
    //           <th>Add To CRM</th>
    //           {/* <th>Action</th> */}
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {contacts &&
    //           contacts.map(contact => {
    //             if (
    //               contact.replyReceived === 'Yes' ||
    //               contact.didAcceptConnection === 'Yes'
    //             ) {
    //               return (
    //                 <Lead
    //                   contact={contact}
    //                   key={contact.id}
    //                   refreshToken={refreshToken}
    //                   updateState={updateState}
    //                 />
    //               );
    //             } else {
    //               console.log('not replied yet');
    //             }
    //           })}
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    // userProfile: state.firebase.profile
  };
};


const mapDispatchToProps = dispatch => {
  return {
    updateLeadStatus: (state, contact) =>
      dispatch(updateLeadStatus(state, contact))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewLeadsTable);
