import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import User from './User';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    width: '90%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const Users = (props) => {
  const userEmail = useRef('');
  const customers = useRef([]);
  const [role, setRole] = useState('');
  const [users, setUsers] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    userEmail.current = props.auth.email;
  }, []);

  useEffect(() => {
    if (!userEmail.current) {
      return;
    }

    firebase
      .firestore()
      .collection('users')
      .where('email', '==', userEmail.current)
      .get()
      .then((user) => {
        user.forEach((doc) => {
          setRole(doc.data().role);

          if (doc.data().customers) {
            customers.current = doc.data().customers;
          }
        });
      })
      .catch((err) =>
        console.log(`An error occurred while fetching user: ${err}`)
      );
  }, [userEmail.current]);

  useEffect(() => {
    if (!props.users) {
      return;
    }

    let usersArray;
    if (role === 'agency') {
      const matches = [];
      usersArray = props.users.filter((user) => {
        customers.current.forEach((customer) => {
          if (user.email === customer) {
            matches.push(user);
          }
        });
      });
      usersArray = matches;
    } else if (role === 'admin' || role === 'developer') {
      usersArray = props.users;
    }
    setUsers(usersArray);

    return () => {
      setUsers([]);
    };
  }, [role]);

  if (!props.auth.uid) return <Redirect to="/signin" />;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className="card-content">
            <div className="h2">
              {role === 'admin' || role === 'developer'
                ? 'Manage Users'
                : 'Manage Customers'}
            </div>
            <table className="responsive-table highlight">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users
                    .sort((a, b) => {
                      const x = a.firstName.toLowerCase();
                      const y = b.firstName.toLowerCase();
                      if (x < y) {
                        return -1;
                      }
                      if (x > y) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((user) => {
                      return <User user={user} key={user.id} role={role} />;
                    })}
              </tbody>
            </table>
          </div>
          {(role === 'admin' || role === 'developer') && (
            <div style={{ position: 'fixed', bottom: '20px', right: '30px' }}>
              <span> Invite User</span>{' '}
              <Link className="btn btn-floating green" to="/invite-user/">
                <i className="material-icons">add</i>{' '}
              </Link>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.firestore.ordered.users,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'users' }])
)(Users);
