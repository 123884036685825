import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import SingleStep from './SingleStep';
import ConnectMsg from './ConnectMsg';
import ThanksMsges from './ThanksMsges';

let id = '';

const LiCampaignSteps = props => {
  const { steps, auth, connectmsges, thanksMsg } = props;
  id = props.id;

  const [state, setState] = useState({
    connectMsg: [],
    thanksMsg: []
  });

  useEffect(() => {
    setState({
      connectMsg: connectmsges,
      thanksMsg: thanksMsg
    });
  }, [connectmsges, thanksMsg]);

  if (!auth.uid) return <Redirect to="/signin" />;

  const inviteBtn = (
    <span>
      <Link
        to={`/add-connect-msg/${props.id}`}
        className="btn-floating btn-large waves-effect waves-light green">
        <i className="material-icons">add</i> Add
      </Link>{' '}
      Invitation Message
      <br />
    </span>
  );

  const thanksBtn = (
    <span>
      <Link
        to={`/add-thanks-msg/${props.id}`}
        className="btn-floating btn-large waves-effect waves-light blue">
        <i className="material-icons">add</i>
      </Link>{' '}
      Thanks For Connecting Message
      <br />
    </span>
  );

  if (steps) {
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">
              <b>Campaign Messaging:</b> {id}
            </span>
            <div className="connect-messages">
              <h5>Invitation Message:</h5>
              <ConnectMsg connectmsges={state.connectMsg} campaign={id} />
              {state.connectMsg.length === 0 && inviteBtn}
            </div>
            <div className="thank-youmessages">
              <h5>Thanks for Connecting Message:</h5>
              <ThanksMsges thanksMsg={state.thanksMsg} campaign={id} />
              {state.thanksMsg.length === 0 ? thanksBtn : null}
            </div>
            <div className="follow-up-messages">
              <h5>Follow Up Messages</h5>
              <SingleStep steps={steps} campaign={id} />
              <Link
                to={`/add-step/${props.id}`}
                className="btn-floating btn-large waves-effect waves-light blue">
                <i className="material-icons">add</i>
              </Link>{' '}
              Add New Follow Up Message
            </div>
          </div>
          <div style={{ position: 'fixed', bottom: '10px', left: '30px' }}>
            <Link className="btn red lighten-1" to={'/li-campaign'}>
              {'< Back'}
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const steps = state.firestore.ordered.steps;
  const connectmsges = state.firestore.ordered.connectmsges
    ? state.firestore.ordered.connectmsges
    : [];
  const thanksMsg = state.firestore.ordered.thanksmsg
    ? state.firestore.ordered.thanksmsg
    : [];
  return {
    steps,
    auth: state.firebase.auth,
    id: id,
    connectmsges,
    thanksMsg
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'steps',
      where: ['campaign', '==', props.id],
      orderBy: ['createdAt', 'asc']
    },
    { collection: 'connectmsges', where: ['campaign', '==', props.id] },
    { collection: 'thanksmsg', where: ['campaign', '==', props.id] }
  ])
)(LiCampaignSteps);
