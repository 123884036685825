import React from 'react'
import linkedinlogo from '../../img/linkedinlogo.png'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const Campaigns = (props) => {
  if (!props.auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="container">
        <div className="card">
            <div className="card-content">
                <span className="card-title">
                    Campaigns
                </span>

                <Link to="/li-campaign">
                  <div className="card">
                      <div className="card-content">
                          <h6><img src={linkedinlogo} width="20" alt="LinkedIn Logo"/> LinkedIn Campaigns</h6>
                      </div>
                  </div>
                </Link>

                <Link to="/li-slr-campaigns">
                  <div className="card">
                      <div className="card-content">
                          <h6><img src={linkedinlogo} width="20" alt="LinkedIn Logo"/> LinkedIn SL Robotics Campaigns</h6>
                      </div>
                  </div>
                </Link>

                <div className="card">
                    <div className="card-content">
                        <h6><i className="material-icons">mail</i>Email Campaigns</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(Campaigns)