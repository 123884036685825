import React, { Component } from 'react';
import ActivityIndicator from 'react-activity-indicator';
import 'react-table/react-table.css';
import { FilePicker } from 'react-file-picker';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import ReactTable from 'react-table';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '30%',
    bottom: '10%',
    position: 'fixed',
    left: '30%',
    right: 'auto',
    width: '30%',
  },
};

export class InfluencerUploads extends Component {
  constructor() {
    super();

    this.state = {
      fileName: '',
      showLoading: false,
      uploadProgress: 0,
      fileUploaded: '',
      // Preview Table States
      keysToMatch: [],
      jsonOfData: [],
      previewOfData: [],
      headerList: require('./influencerColumns.json'),
      importedData: [],
      selectedHeaderToChange: '',
    };
  }

  // This function gets called when you select the CSV
  parseInitialUpload(fileObject) {
    var jsonOfData = this;

    var tempJsonOfData = [];
    var count = 0;

    const config = {
      // download: true,
      header: true,
      // preview: 12,
      skipEmptyLines: true,
      error: function (error) {
        console.log(error, 'Error');
      },
      step: function (row) {
        var currentRow = row.data;
        const keys = Object.keys(currentRow);

        keys.forEach((key) => {
          const spaceIndex = key.indexOf(' ');
          if (spaceIndex > 0) {
            var newKey = key.replace(/ /g, '_');
            currentRow[newKey] = currentRow[key];
            delete currentRow[key];
          }
          const hashIndex = key.lastIndexOf('#');
          if (hashIndex > 0) {
            newKey = newKey.replace('#', 'No');
            currentRow[newKey] = currentRow[key];
            delete currentRow[key];
          }
        });
        count = count + 1;
        tempJsonOfData.push(currentRow);
      },
      complete: function (results, file) {
        console.log('The data', tempJsonOfData);
        jsonOfData.setState({ jsonOfData: tempJsonOfData });
      },
    };

    Papa.parse(fileObject, config);
  }

  // Match the column with the data

  setHeaderVariable(key) {
    // Full data array

    // key = the item the user selected to click on.

    var array = this.state.jsonOfData;

    array.forEach((item) => {
      if (this.state.selectedHeaderToChange === key) {
        // Do nothing
      } else {
        console.log(item[this.state.selectedHeaderToChange]);
        item[this.state.selectedHeaderToChange] = item[key];
        delete item[key];
      }
    });

    this.setState({ jsonOfData: array });

    this.setState({ modalIsOpen: false });
  }

  confirmUpload() {
    var uploadProgress = this;
    var fileUploaded = this;
    var showLoading = this;
    var fileName = this;

    uploadProgress.setState({
      uploadProgress: 'Current Progress ' + 0 + ' %',
    });

    showLoading.setState({ showLoading: true });
    showLoading.setState({ showLoading: true });
    showLoading.setState({ showLoading: true });

    fileUploaded.setState({ fileUploaded: '' });

    console.log(this.state.jsonOfData);

    var modifiedArray = [];

    this.state.jsonOfData.forEach((item) => {
      modifiedArray.push(item);
    });
    // Convert back to CSV
    var csv = Papa.unparse(modifiedArray);
    // Rename CSV to something unique so it doesn't overwrite another one.

    var csvName =
      'Upload_' +
      [...Array(10)].map((i) => (~~(Math.random() * 36)).toString(36)).join('');

    var storageRef = firebase.storage().ref();

    var fileRef = storageRef.child(`/influencerUploads/${csvName}.csv`);
    // Upload the file.

    var uploadTask = fileRef.putString(csv);

    uploadTask.on(
      'state_changed',
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        uploadProgress.setState({
          uploadProgress: 'Current Progress ' + Math.round(progress) + ' %',
        });
      },
      function (error) {
        console.log(error, 'Error');
        // Handle unsuccessful uploads
      },
      function () {
        uploadProgress.setState({ uploadProgress: '' });
        // Handle successful uploads on complete
        const functions = firebase.functions();
        var fromCloudStorageToInfluencerTable = functions.httpsCallable(
          'fromCloudStorageToInfluencerTable'
        );
        fromCloudStorageToInfluencerTable({
          fileName: uploadTask.snapshot.metadata.name,
          randomText: 'Hello, this is random text',
        })
          .then(function (result) {
            console.log(result);
            // File has been uploaded.
            fileName.setState({
              fileName: '',
            });
            showLoading.setState({
              showLoading: false,
            });
            fileUploaded.setState({
              fileUploaded:
                'Your file has been uploaded. Please allow a few minutes for your changes to reflect.',
            });
          })
          .catch((err) => {
            showLoading.setState({
              showLoading: false,
            });
            console.log(err);
            alert('There has been an error');
          });
      }
    );

    // End of function.
  }

  render() {
    console.log(this.state.jsonOfData.slice(0, 10));

    const data = this.state.jsonOfData.slice(0, 10);

    // Theses are the columns in the bigquery table.

    const columns = this.state.headerList.map((item) => {
      return {
        Header: item.Header,
        accessor: item.accessor,
        Cell: (row) => (
          <div
            onClick={() => {
              console.log(row.column.Header);
              this.setState({ selectedHeaderToChange: row.column.Header });
              // Open the modal to pick the header.
              this.setState({ modalIsOpen: true });
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: row.value === '' ? '#ff0000' : '#666',
                fontSize: 12,
                cursor: 'pointer',
              }}
            >
              {row.value || 'No Match'}
            </p>
          </div>
        ),
      };
    });

    return (
      <div style={{ marginTop: 20 }}>
        {this.state.typeOfFile !== '' && (
          <div>
            {!this.state.fileName && (
              <div>
                <FilePicker
                  extensions={['csv']}
                  maxSize={10000000}
                  onChange={(fileObject) => {
                    this.parseInitialUpload(fileObject);
                    this.setState({ fileObject: fileObject });
                    this.setState({ fileUploaded: '' });
                    this.setState({
                      fileName: fileObject.name,
                    });
                  }}
                  onError={(errMsg) => {
                    console.log(errMsg, 'Error');
                    this.setState({ error: errMsg });
                  }}
                >
                  <button
                    onClick={() => {
                      this.setState({ fileUploaded: '' });
                    }}
                    className="btn green"
                    style={{ marginTop: '15px' }}
                  >
                    Pick CSV
                  </button>
                </FilePicker>
                <p
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  {this.state.error}
                </p>
              </div>
            )}
            {this.state.fileName && !this.state.showLoading && (
              <div>
                <p
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  {this.state.fileName}
                </p>
                <p style={{ paddingBottom: 20 }}>
                  Match your CSV fields with the fields below.
                </p>
                <p style={{ paddingBottom: 20 }}>
                  Click on an item to change it's value.
                </p>
                {/* This table will be populated with the correct data */}
                <div style={{ marginBottom: 20 }}>
                  <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={10}
                    showPagination={false}
                  />
                </div>
                <button
                  className="btn green"
                  style={{ marginTop: '15px' }}
                  onClick={() => {
                    //Upload the object here.
                    this.confirmUpload();
                  }}
                >
                  Confirm and Upload
                </button>
                <br />
                <button
                  className="btn red"
                  style={{ marginTop: '15px' }}
                  onClick={() => {
                    this.setState({ fileName: '' });
                    this.setState({ fileObject: [] });
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
            {this.state.showLoading && (
              <div>
                <p>{this.state.uploadProgress}</p>
                <ActivityIndicator
                  number={3}
                  diameter={20}
                  borderWidth={1}
                  duration={200}
                  activeColor="#66D9EF"
                  borderColor="white"
                  borderRadius="50%"
                />
              </div>
            )}
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
              {this.state.fileUploaded}
            </div>
          </div>
        )}
        <div>
          <Modal
            ariaHideApp={false}
            id="Modal"
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            contentLabel="Match Column"
            style={customStyles}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>{this.state.selectedHeaderToChange}</div>
              <div
                onClick={() => {
                  this.setState({ modalIsOpen: false, cursor: 'pointer' });
                }}
              >
                Close
              </div>
            </div>
            {this.state.jsonOfData.length > 0 ? (
              <div>
                {Object.keys(this.state.jsonOfData[0]).map((key) => (
                  <div
                    onClick={() => {
                      this.setHeaderVariable(key);
                    }}
                    key={Math.random()}
                    style={{
                      borderBottom: 'solid',
                      borderBottomColor: '#666',
                      borderBottomWidth: 1,
                      cursor: 'pointer',
                    }}
                  >
                    <p>{key}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p>Please select a CSV first</p>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(InfluencerUploads);
