import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signIn, signInWithGoogle } from '../../store/actions/authActions';
import { Link, Redirect } from 'react-router-dom';
import google from '../../img/google-dark.png';

const SignIn = (props) => {
  const [state, setState] = useState({
    email: '',
    password: ''
  })

  const handleChange = e => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.signIn(state);
  };

  const googleSignin = () => {
    props.signInWithGoogle()
  }

  const { authError, auth } = props;
  if (auth.uid) return <Redirect to="/" />;

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <form className="white" onSubmit={handleSubmit}>
            <h5 className="grey-text text-darken-3">Sign In</h5>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" onChange={handleChange} />
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                onChange={handleChange}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}} className="input-field">
              <button className="btn green lighten-1">Login</button>
              <span style={{margin: '0 10px'}} className="grey-text"> - OR - </span>
              <img src={google} alt="" width="200" style={{cursor: 'pointer'}} onClick={googleSignin}/>
            </div>
            <div className="input-field">
              <div className="red-text center">
                {authError ? <p>{authError}</p> : null}
              </div>
            </div>
            <div className="input-field">
              <Link to="/password-reset/">Forgot Password?</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    signInWithGoogle: () => dispatch(signInWithGoogle())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
