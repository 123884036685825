import "react-table/react-table.css";
import "./BQQueries.css";

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Modal from "react-modal";
import ReactTable from "react-table";
import { Row } from "react-materialize";
import Select from "react-select";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import TwitterIcon from "@material-ui/icons/Twitter";
import _ from "lodash";
import firebase from "firebase/app";
import moment from "moment";

const customStyles = {
  content: {
    top: "30%",
    bottom: "10%",
    position: "fixed",
    left: "30%",
    right: "auto",
    width: "30%",
  },
  sideBar: {
    width: 300,
    height: "100vh",
    backgroundColor: "#ffffff",
    overflow: "scroll",
    padding: 20,
    borderRight: "solid 1px #d2d2d2",
  },
  sideBarInput: {
    border: "solid 1px #ffffff",
    fontSize: 12,
    color: "#666",
    padding: 5,
    marginTop: 5,
    width: "90%",
  },
  modalInput: {
    border: "solid 1px #d2d2d2",
    fontSize: 12,
    borderRadius: 10,
    padding: 5,
    marginTop: 5,
    width: "100%",
  },
  buttonStyle: {
    height: 50,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#005ebc",
    color: "#ffffff",
    fontWeight: "bold",
    marginRight: 30,
  },
  sidebarButtonStyle: {
    height: 50,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#005ebc",
    color: "#ffffff",
    fontWeight: "bold",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 10,
  },
};

export class BQQueries extends Component {
  state = {
    count: 0,
    // companySearch: '',
    returnedData: [],
    showLoading: false,
    showRecords: "",
    editableQuery: true,
    updatingQuery: false,
    // Field States
    industryQueryArray: [],
    excludeIndustryQueryArray: [],
    excludeCompanyQueryArray: [],
    includeCompanyQueryArray: [],
    titleQueryArray: [],
    jobTitleQueryArray: [],
    excludeJobTitleQueryArray: [],
    employeeSizeQueryArray: [],
    emailValidationQueryArray: [],
    emailValidationDateQueryArray: [],
    usStateQueryArray: [],
    onlyIncludeUSA: true,
    onlyIncludeLinkedIn: true,
    citiesInUSAQueryArray: [],
    includeCompanyDomain: "",
    excludedCompanyDomain: "",
    includeLinkedInDomain: "",
    // Selector Lists
    industryList: require("../queries/resources/industries.json"),
    countryList: require("../queries/resources/countries.json"),
    titleList: require("../queries/resources/title_levels.json"),
    employeeSizeList: require("../queries/resources/employeeSize.json"),
    usStateList: require("../queries/resources/us-states.json"),
    usCityList: require("../queries/resources/usaCities"),
    emailValidationTypes: [
      {
        value: "Catch All",
        label: "Catch All",
      },
      {
        value: "Complainer",
        label: "Complainer",
      },
      {
        value: "SpamTrap",
        label: "SpamTrap",
      },
      {
        value: "Undeliverable",
        label: "Undeliverable",
      },
      {
        value: "Unknown",
        label: "Unknown",
      },
      {
        value: "Verified",
        label: "Verified",
      },
      {
        value: "Bot",
        label: "Bot",
      },
    ],
    emailValidationDates: [
      {
        value: this.getUnixDate(30),
        label: "30 Days",
      },
      {
        value: this.getUnixDate(60),
        label: "60 Days",
      },
      {
        value: this.getUnixDate(90),
        label: "90 Days",
      },
    ],
    arrayOfCitiesList: [],
    limit: 3000,
    offset: 0,
    countOfQuery: 0,
    queryString: "",
    postDownloadMessage: "",
    queryTitle: "",
    savedQueryString: "",
    querySavedMessage: "",
    savedQueryArray: [],
    selectedSavedQueryString: "",
    userID: firebase.auth().currentUser.uid,
    user: {},
    displayName: "",
    completeQueryArrayState: [],
    selectedSavedQueryID: "",
    selectedSavedQueryTitle: "",
    // Include Domain States VIA CSV Upload
    resultsFromDomainIncludeCSV: [],
    showSelectHeaderDialog: false,
    headersToSelect: [],
    // Exclude Domain States VIA CSV Upload
    resultsFromDomain_ExcludeCSV: [],
    showSelectHeaderDialog_Exclude: false,
    headersToSelect_Exclude: [],
    // Include LinkedIN URL States VIA CSV Upload
    resultsFromLinkedIncludeCSV: [],
    showSelectHeaderDialog_LinkedIn: false,
    headersToSelect_LinkedIn: [],
    // Include company domains via string input
    includeCompanyDomainStringArray: [],
    //Exclude company domains via string input
    excludeCompanyDomainStringArray: [],
    // Others
    validatedLeads: 0,
    loading: false,
    validationProcessStatus: "",
    leadsSub: 0,
    progress: 0,
    showQueryBuilder: false,
    addingQueryToDB: false,
    showSnack: false,
    snackMessage: "",
    countOnTheFly: 0,
    countOnTheFlyLoading: false,
    creditsLoaded: false,
    creditBalance: 0,
    showValidationModal: false,
    contactSaveCount: 0,
    validationLoading: false,
    savedListName: "",
  };

  componentDidMount() {
    // If the user has saved queries, get them here..
    this.getSavedQueries();
    this.getCredits();

    var displayName = this;

    // Getting the user object into the state.
    firebase
      .firestore()
      .collection("users")
      .doc(this.state.userID)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log("DOCUMENT", doc.data());
          displayName.setState({
            user: { ...doc.data(), uid: doc.id },
            displayName: doc.data().firstName + " " + doc.data().lastName,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }

  getCredits() {
    firebase
      .firestore()
      .collection("credits")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((snap) => {
        console.log(snap);
        if (snap.exists) {
          console.log("Getting the credits");
          this.setState({ creditBalance: snap.data().credits });
          this.setState({ creditsLoaded: true });
        } else {
          this.setState({ creditBalance: 0 });
          this.setState({ creditsLoaded: true });
        }
      });
  }

  getUnixDate(days) {
    return moment().subtract("days", days).unix();
  }

  //This only returns the count of a query for display.
  getCount() {
    var getCountOfQuery = firebase.functions().httpsCallable("getCountOfQuery");
    var query = "";
    if (this.state.selectedSavedQueryString === "") {
      query = `${this.buildingNewArray()}`;
    } else {
      query = this.state.selectedSavedQueryString;
    }
    var countOfQuery = this;
    var countOnTheFly = this;
    var countOnTheFlyLoading = this;
    countOnTheFlyLoading.setState({ countOnTheFlyLoading: true });

    getCountOfQuery({
      query: query,
    })
      .then(function (result) {
        countOfQuery.setState({
          countOfQuery: `There are ${result.data[0].f0_} results in the query.`,
        });
        countOnTheFly.setState({ countOnTheFly: result.data[0].f0_ });
        countOnTheFlyLoading.setState({ countOnTheFlyLoading: false });
        console.log(result.data[0].f0_, "Should be a count here.");
      })
      .catch((err) => {
        console.log("No results");
        console.log(err, "Error");
        countOnTheFlyLoading.setState({ countOnTheFlyLoading: false });
        countOnTheFly.setState({ countOnTheFly: 0 });
      });
  }

  //This function sends a query to the BQ table and returns the first 3000 results.
  callFilterFunction() {
    this.getCount();
    var showRecords = this;
    var showLoading = this;
    showLoading.setState({ showLoading: true });
    var companySearch = this;
    var returnedData = this;
    var queryString = this;
    var sampleBQ_Query = firebase.functions().httpsCallable("sampleBQ_Query");

    var query = "";

    if (this.state.selectedSavedQueryString === "") {
      query = `${this.buildingNewArray()}`;
      queryString.setState({ queryString: query });
    } else {
      query = this.state.selectedSavedQueryString;
      queryString.setState({ queryString: query });
    }

    companySearch.setState({ companySearch: "" });

    sampleBQ_Query({
      query: query,
      limit: this.state.limit,
      offset: this.state.offset,
    })
      .then(function (result) {
        returnedData.setState({ returnedData: result.data });
        showLoading.setState({ showLoading: false });
        if (result.data.length === 0) {
          showRecords.setState({
            showRecords: "There are no records to show.",
          });
        } else {
          showRecords.setState({
            showRecords: `You are currently viewing the first ${result.data.length}.`,
          });
        }
      })
      .catch((err) => {
        showLoading.setState({ showLoading: false });
        console.log(err, "Error");
      });
  }

  //This function creates a new temporary BQ table, and then it exports that table into Cloud Storage.
  //The user then gets a download link for the file that lasts 24 hours.

  downloadCSVFunction() {
    this.setState({ showLoading: true });
    var query = "";
    if (this.state.selectedSavedQueryString === "") {
      query = `${this.buildingNewArray()}`;
      console.log("query:", query);
      this.logQuery(query);
    } else {
      query = this.state.selectedSavedQueryString;
      console.log("query:", query);
      this.logQuery(query);
    }

    var makeANewTable = firebase.functions().httpsCallable("makeANewTable");

    var postDownloadMessage = this;
    postDownloadMessage.setState({
      postDownloadMessage: "",
    });

    var showLoading = this;
    var showSnack = this;
    makeANewTable({
      query: query,
      userID: firebase.auth().currentUser.uid,
      email: firebase.auth().currentUser.email,
    }).then(function (result) {
      showLoading.setState({ showLoading: false });

      showSnack.showSnack(
        "Success: A link to your file will be emailed to you once processing has been completed"
      );
    });
  }

  //Handle when a user changes their Job Title selection.
  handleJobTitleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  handleQueryTitleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  //Handle when a user changes their Industry that they want to include.
  handleIndustryChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  //Handle when a user changes their Industry that they want to exclude.
  handleExcludeIndustryChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });

    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  //Handle when a user changes their Title Level selection.
  handleTitleLevelChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  //Handle when a user changes their Company Size selection.
  handleEmployeeSizeChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  handleCityChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  handleEmailValidationChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  handleEmailValidationDateChange = (option, e) => {
    var tempArray = [];
    tempArray.push(option);
    this.setState({
      ...this.state,
      [e.name]: tempArray,
    });
    setTimeout(() => {
      this.getCount();

      this.checkSearchStatus();
    }, 500);
  };

  handleOnlyIncludeUSA = (e) => {
    this.setState({ onlyIncludeUSA: !this.state.onlyIncludeUSA });
    setTimeout(() => {
      this.getCount();
    }, 500);
  };

  handleOnlyIncludeLinkedIn = (e) => {
    this.setState({ onlyIncludeLinkedIn: !this.state.onlyIncludeLinkedIn });
    setTimeout(() => {
      this.getCount();
    }, 500);
  };

  // This is where you'll be handling the parsing of the CSV..

  createWebsiteIncludeQuery(key) {
    console.log(key);
    var showSelectHeaderDialog = this;
    var includeCompanyDomain = this;
    showSelectHeaderDialog.setState({ showSelectHeaderDialog: false });

    var fullArray = this.state.resultsFromDomainIncludeCSV;

    var stringOfDomains = [];

    fullArray.forEach((item) => {
      stringOfDomains.push(item[key].trim());
    });

    stringOfDomains = stringOfDomains.join();

    includeCompanyDomain.setState({
      includeCompanyDomain: stringOfDomains,
    });
  }

  createLinkedInIncludeQuery(key) {
    var showSelectHeaderDialog_LinkedIn = this;
    var includeLinkedInDomain = this;

    showSelectHeaderDialog_LinkedIn.setState({
      showSelectHeaderDialog_LinkedIn: false,
    });

    var fullArray = this.state.resultsFromLinkedIncludeCSV;

    console.log(fullArray, "FA");

    var stringOfDomains = [];

    fullArray.forEach((item) => {
      stringOfDomains.push(item[key].trim());
    });

    stringOfDomains = stringOfDomains.join();

    includeLinkedInDomain.setState({
      includeLinkedInDomain: stringOfDomains,
    });
  }

  createWebsiteExcludeQuery(key) {
    console.log(key);
    var showSelectHeaderDialog_Exclude = this;
    var excludedCompanyDomain = this;
    showSelectHeaderDialog_Exclude.setState({
      showSelectHeaderDialog_Exclude: false,
    });

    var fullArray = this.state.resultsFromDomain_ExcludeCSV;

    var stringOfDomains = [];

    fullArray.forEach((item) => {
      stringOfDomains.push(item[key].trim());
    });

    console.log(stringOfDomains, "Exclude Domain");

    stringOfDomains = stringOfDomains.join();

    excludedCompanyDomain.setState({
      excludedCompanyDomain: stringOfDomains,
    });
  }

  // If a user changes their saved query.
  handleSavedQueryChange = (e) => {
    //Hide build query fields.
    this.setState({ selectedSavedQueryID: e.id });
    this.setState({ selectedSavedQueryTitle: e.label });
    this.setState({ selectedSavedQueryString: e.value });
    if (e.array) {
      this.setState({ editableQuery: true });
      this.setState({ updatingQuery: true });
      console.log("Aqui", e.value);
      var queryArray = JSON.parse(e.array, "Value");

      // Have an array with all the different fields in here:

      var jobTitleQueryArrayTemp = []; // Doing this
      var excludeJobTitleQueryArrayTemp = [];
      var industryQueryArrayTemp = [];
      var excludeIndustryQueryArrayTemp = [];
      var includeCompanyQueryArrayTemp = [];
      var excludeCompanyQueryArrayTemp = [];
      var titleQueryArrayTemp = [];
      var employeeSizeQueryArrayTemp = [];
      var emailValidationQueryArrayTemp = [];
      var emailValidationDateQueryArrayTemp = [];
      var usStateQueryArrayTemp = [];
      var citiesInUSAQueryArrayTemp = [];
      var includeCompanyDomainTemp = "";
      var excludedCompanyDomainTemp = [];
      var includeLinkedInDomainTemp = [];

      // Only Include USA
      if (e.value.includes("Country = 'US'")) {
        this.setState({ onlyIncludeUSA: true });
      }

      if (e.value.includes("Person_Linkedin_Url IS NOT NULL")) {
        this.setState({ onlyIncludeLinkedIn: true });
      }

      queryArray.forEach((item) => {
        item.forEach((value) => {
          console.log(value, "VALUE");
          // Include Job Title
          if (value.queryField === "Title" && value.excluded !== true) {
            jobTitleQueryArrayTemp.push(value);
            this.setState({
              jobTitleQueryArray: jobTitleQueryArrayTemp,
            });
          }
          // Exclude Job Title
          if (value.queryField === "Title" && value.excluded === true) {
            excludeJobTitleQueryArrayTemp.push(value);
            this.setState({
              excludeJobTitleQueryArray: excludeJobTitleQueryArrayTemp,
            });
          }
          // Include Industry
          if (value.queryField === "Industry" && value.excluded !== true) {
            industryQueryArrayTemp.push(value);
            this.setState({
              industryQueryArray: industryQueryArrayTemp,
            });
          }
          // Exclude Industry
          if (value.queryField === "Industry" && value.excluded === true) {
            excludeIndustryQueryArrayTemp.push(value);
            this.setState({
              excludeIndustryQueryArray: excludeIndustryQueryArrayTemp,
            });
          }
          // Include Company
          if (value.queryField === "Company_Name" && value.excluded !== true) {
            includeCompanyQueryArrayTemp.push(value);
            this.setState({
              includeCompanyQueryArray: includeCompanyQueryArrayTemp,
            });
          }
          // Exclude Company
          if (value.queryField === "Company_Name" && value.excluded === true) {
            excludeCompanyQueryArrayTemp.push(value);
            this.setState({
              excludeCompanyQueryArray: excludeCompanyQueryArrayTemp,
            });
          }
          // Job Level
          if (value.queryField === "Job_Level") {
            titleQueryArrayTemp.push(value);
            this.setState({
              titleQueryArray: titleQueryArrayTemp,
            });
          }
          // Employee Size
          if (value.queryField === "No_Employees") {
            employeeSizeQueryArrayTemp.push(value);
            this.setState({
              employeeSizeQueryArray: employeeSizeQueryArrayTemp,
            });
          }
          // Email Validation Types
          if (value.queryField === "ValidationStatus") {
            emailValidationQueryArrayTemp.push(value);
            this.setState({
              emailValidationQueryArray: emailValidationQueryArrayTemp,
            });
          }
          // Email Validation Date
          if (value.queryField === "formatted_verified_date") {
            emailValidationDateQueryArrayTemp.push(value);
            this.setState({
              emailValidationDateQueryArray: emailValidationDateQueryArrayTemp,
            });
          }
          // Exclude Domains
          if (value.queryField === "Website" && value.excluded === true) {
            if (excludedCompanyDomainTemp.length === 0) {
              excludedCompanyDomainTemp = value.queryValue;
              this.setState({
                excludedCompanyDomain: excludedCompanyDomainTemp,
              });
              console.log(excludedCompanyDomainTemp, "TempString");
            } else {
              excludedCompanyDomainTemp =
                excludedCompanyDomainTemp + `, ${value.queryValue}`;
              this.setState({
                excludedCompanyDomain: excludedCompanyDomainTemp,
              });
              console.log(excludedCompanyDomainTemp, "TempString");
            }
          }
          // Include Domains
          if (value.queryField === "Website" && value.excluded !== true) {
            if (includeCompanyDomainTemp.length === 0) {
              includeCompanyDomainTemp = value.queryValue;
              this.setState({
                includeCompanyDomain: includeCompanyDomainTemp,
              });
              console.log(includeCompanyDomainTemp, "TempString");
            } else {
              includeCompanyDomainTemp =
                includeCompanyDomainTemp + `, ${value.queryValue}`;
              this.setState({
                includeCompanyDomain: includeCompanyDomainTemp,
              });
              console.log(includeCompanyDomainTemp, "TempString");
            }
          }
          // Include LinkedIn Domains
          if (value.queryField === "Person_Linkedin_Url") {
            if (includeLinkedInDomainTemp.length === 0) {
              includeLinkedInDomainTemp = value.queryValue;
              this.setState({
                includeLinkedInDomain: includeLinkedInDomainTemp,
              });
            } else {
              includeLinkedInDomainTemp =
                includeLinkedInDomainTemp + `, ${value.queryValue}`;
              this.setState({
                includeLinkedInDomain: includeLinkedInDomainTemp,
              });
            }
          }
          // US State
          if (value.queryField === "State") {
            usStateQueryArrayTemp.push(value);
            this.setState({
              usStateQueryArray: usStateQueryArrayTemp,
            });
          }
          // US City In State
          if (value.queryField === "City") {
            citiesInUSAQueryArrayTemp.push(value);
            this.setState({
              citiesInUSAQueryArray: citiesInUSAQueryArrayTemp,
            });
          }
        });
      });

      this.setState({ showQueryBuilder: true });
    } else {
      this.setState({ editableQuery: false });
    }
  };

  handleUSStateChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option,
    });
    setTimeout(() => {
      this.getCount();
      this.checkSearchStatus();
      this.checkForCitiesInStates();
    }, 500);

    //So what you need to do is figure out the array of states that are there,
    //and then you need to filter the JSON and only show the cities in states that have been selected.
  };

  // When a user selects a state, we need to look for cities in that state to show them.

  checkForCitiesInStates() {
    var includedStates = this.state.usStateQueryArray;
    var stateNames = [];
    includedStates.forEach((state) => {
      stateNames.push(state.label);
    });

    var citiesArray = this.state.usCityList;

    var availableCities = [];
    // So we've got this array of all the cities.

    stateNames.forEach((state) => {
      var cities = _.map(citiesArray, function (array) {
        if (array.state === state) return array;
      });

      // Remove undefines from the array
      cities = _.without(cities, undefined);

      availableCities.push(cities);
    });

    var arrayOfCities = [];

    availableCities.forEach((cities) => {
      cities.forEach((city) => {
        arrayOfCities.push({
          value: city.city,
          label: `${city.city} (${city.state})`,
        });
      });
    });

    arrayOfCities = _.orderBy(arrayOfCities, "label"); // Use Lodash to sort array by 'name'

    this.setState({ arrayOfCitiesList: arrayOfCities });
  }

  // Updating a query that the user has previously saved.
  async updateQuery() {
    await this.buildingNewArray();

    setTimeout(() => {
      firebase
        .firestore()
        .collection("queries")
        .doc(this.state.selectedSavedQueryID)
        .update({
          queryString: this.state.savedQueryString,
          userID: firebase.auth().currentUser.uid,
          queryName: this.state.selectedSavedQueryTitle,
          time: new Date(),
          array: JSON.stringify(this.state.completeQueryArrayState),
        });
    }, 500);
  }

  // Saving a query for the user to use later.

  async saveQuery() {
    this.setState({ addingQueryToDB: true });
    await this.buildingNewArray();

    setTimeout(() => {
      firebase
        .firestore()
        .collection("queries")
        .add({
          queryString: this.state.savedQueryString,
          userID: firebase.auth().currentUser.uid,
          queryName: this.state.queryTitle,
          time: new Date(),
          array: JSON.stringify(this.state.completeQueryArrayState),
        })
        .then((result) => {
          var tempSavedQueryArray = this.state.savedQueryArray;

          tempSavedQueryArray.push({
            queryString: this.state.savedQueryString,
            userID: firebase.auth().currentUser.uid,
            label: this.state.queryTitle,
            value: this.state.savedQueryString,
            id: result.id,
            array: JSON.stringify(this.state.completeQueryArrayState),
          });
          this.getSavedQueries();
          this.setState({ savedQueryArray: tempSavedQueryArray });
          this.setState({ savedQueryString: "" });
          this.setState({ queryName: "" });
          this.setState({ addingQueryToDB: false });
          this.setState({ savingQuery: false });
          this.setState({ showQueryBuilder: false });
          this.showSnack("Query Saved!");
        });
    }, 500);
  }

  showSnack(snackMessage) {
    this.setState({ snackMessage: snackMessage });

    this.setState({ showSnack: true });
  }

  //This function builds the query string from the selected options.
  buildingNewArray() {
    //Defining the variables:

    var async = require("async");
    var completeQueryArray = [];
    var industryQueryArray = this.state.industryQueryArray;
    var excludeIndustryQueryArray = this.state.excludeIndustryQueryArray;
    var titleQueryArray = this.state.titleQueryArray;
    var jobTitleQueryArray = this.state.jobTitleQueryArray;
    var excludeJobTitleQueryArray = this.state.excludeJobTitleQueryArray;
    var employeeSizeQueryArray = this.state.employeeSizeQueryArray;
    var onlyIncludeUSA = this.state.onlyIncludeUSA;
    var onlyIncludeLinkedIn = this.state.onlyIncludeLinkedIn;
    var usStateQueryArray = this.state.usStateQueryArray;
    var citiesInUSAQueryArray = this.state.citiesInUSAQueryArray;
    var excludeCompanyQueryArray = this.state.excludeCompanyQueryArray;
    var includeCompanyQueryArray = this.state.includeCompanyQueryArray;
    var includeCompanyDomain = this.state.includeCompanyDomain;
    var excludedCompanyDomain = this.state.excludedCompanyDomain;
    var includeLinkedInDomain = this.state.includeLinkedInDomain;
    var emailValidationQueryArray = this.state.emailValidationQueryArray;
    var emailValidationDateQueryArray = this.state
      .emailValidationDateQueryArray;
    var includeCompanyDomainStringArray = this.state
      .includeCompanyDomainStringArray;
    var excludeCompanyDomainStringArray = this.state
      .excludeCompanyDomainStringArray;
    var savedQueryString = this;
    var completeQueryArrayState = this;

    //Here we create arrays for each queried item.

    async.waterfall(
      [
        //Job Title Function
        function (done) {
          if (jobTitleQueryArray.length > 0) {
            completeQueryArray.push(jobTitleQueryArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        //Exclude Job Title Function
        function (completeQueryArray, done) {
          if (excludeJobTitleQueryArray.length > 0) {
            var modifiedExcludeJobTitleQueryArrayArray = excludeJobTitleQueryArray;
            modifiedExcludeJobTitleQueryArrayArray.forEach((element) => {
              element.queryField = "Title";
              // element.queryValue = element.queryValue;
              element.excluded = true;
            });
            completeQueryArray.push(modifiedExcludeJobTitleQueryArrayArray);
          } else {
            //nothing
          }
          done(null, completeQueryArray);
        },
        //Industry To Include Function
        function (completeQueryArray, done) {
          if (industryQueryArray.length > 0) {
            var modifiedIndustryArray = industryQueryArray;
            modifiedIndustryArray.forEach((element) => {
              element.queryField = "Industry";
              element.queryValue = element.value;
              element.excluded = false;
            });
            completeQueryArray.push(modifiedIndustryArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        //Exclude Industry Function
        function (completeQueryArray, done) {
          if (excludeIndustryQueryArray.length > 0) {
            var modifiedExcludeIndustryArray = excludeIndustryQueryArray;
            modifiedExcludeIndustryArray.forEach((element) => {
              element.queryField = "Industry";
              element.queryValue = element.value;
              element.excluded = true;
            });
            completeQueryArray.push(modifiedExcludeIndustryArray);
          } else {
            //nothing
          }
          done(null, completeQueryArray);
        },
        //Exclude Company Function
        function (completeQueryArray, done) {
          if (excludeCompanyQueryArray.length > 0) {
            var modifiedExcludeCompanyArray = excludeCompanyQueryArray;
            modifiedExcludeCompanyArray.forEach((element) => {
              element.queryField = "Company_Name";
              // element.queryValue = element.queryValue;
              element.excluded = true;
            });
            completeQueryArray.push(modifiedExcludeCompanyArray);
          } else {
            //nothing
          }
          done(null, completeQueryArray);
        },
        //Include Company Function
        function (completeQueryArray, done) {
          if (includeCompanyQueryArray.length > 0) {
            completeQueryArray.push(includeCompanyQueryArray);
          } else {
            //nothing
          }
          done(null, completeQueryArray);
        },
        //Job_Level Function
        function (completeQueryArray, done) {
          if (titleQueryArray.length > 0) {
            var modifiedTitleLevelArray = titleQueryArray;
            modifiedTitleLevelArray.forEach((element) => {
              element.queryField = "Job_Level";
              element.queryValue = element.value;
            });
            completeQueryArray.push(modifiedTitleLevelArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        //Email Validation Type Function
        function (completeQueryArray, done) {
          if (emailValidationQueryArray.length > 0) {
            var modifiedEmailValidationQueryArray = emailValidationQueryArray;
            modifiedEmailValidationQueryArray.forEach((element) => {
              element.queryField = "ValidationStatus";
              element.queryValue = element.value;
            });
            completeQueryArray.push(modifiedEmailValidationQueryArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        //Email Validation Date Function
        function (completeQueryArray, done) {
          if (emailValidationDateQueryArray.length > 0) {
            var modifiedEmailValidationDateQueryArray = emailValidationDateQueryArray;
            modifiedEmailValidationDateQueryArray.forEach((element) => {
              element.queryField = "formatted_verified_date";
              element.queryValue = element.value;
            });
            completeQueryArray.push(modifiedEmailValidationDateQueryArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        //Company/Employee Size Query Function
        function (completeQueryArray, done) {
          if (employeeSizeQueryArray.length > 0) {
            var modifiedEmployeeSizeQueryArray = employeeSizeQueryArray;

            modifiedEmployeeSizeQueryArray.forEach((element) => {
              element.queryField = "No_Employees";
              element.queryValue = element.value;
            });
            console.log(modifiedEmployeeSizeQueryArray);
            completeQueryArray.push(modifiedEmployeeSizeQueryArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        // Include LinkedIn Domain Function
        function (completeQueryArray, done) {
          if (includeLinkedInDomain.length > 0) {
            var modifiedIncludeLinkedInDomain = includeLinkedInDomain.split(
              ","
            );

            var transformedArray = [];

            modifiedIncludeLinkedInDomain.forEach((item) => {
              if (item[0] === " ") {
                item = item.substr(1, item.length - 1);
              }
              transformedArray.push({
                queryField: "Person_Linkedin_Url",
                queryValue: item,
              }); // Here you can format it like you need to.
            });
            completeQueryArray.push(transformedArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },

        //Include Company Domain VIA Upload Function

        function (completeQueryArray, done) {
          if (includeCompanyDomain.length > 0) {
            // Ok so here you just need a string of the all.

            var modifiedIncludeCompanyDomain = includeCompanyDomain.split(",");

            var transformedArray = [];

            modifiedIncludeCompanyDomain.forEach((item) => {
              if (item[0] === " ") {
                item = item.substr(1, item.length - 1);
              }
              transformedArray.push({
                queryField: "Website",
                queryValue: item,
              }); // Here you can format it like you need to.
              console.log(transformedArray, "TA");
            });
            completeQueryArray.push(transformedArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },

        //Include Company Domain Via String Function
        function (completeQueryArray, done) {
          if (includeCompanyDomainStringArray.length > 0) {
            completeQueryArray.push(includeCompanyDomainStringArray);
          } else {
            //nothing
          }
          done(null, completeQueryArray);
        },

        //Exclude Company Domain  VIA Upload Function

        function (completeQueryArray, done) {
          if (excludedCompanyDomain.length > 0) {
            var modifiedExcludedCompanyDomain = excludedCompanyDomain.split(
              ","
            );

            var transformedArray = [];

            modifiedExcludedCompanyDomain.forEach((item) => {
              if (item[0] === " ") {
                item = item.substr(1, item.length - 1);
              }
              transformedArray.push({
                queryField: "Website",
                queryValue: item,
                excluded: true,
              }); // Here you can format it like you need to.
              console.log(transformedArray, "TA");
            });
            completeQueryArray.push(transformedArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },

        //Exclude Company Domain VIA String Function
        function (completeQueryArray, done) {
          if (excludeCompanyDomainStringArray.length > 0) {
            var modifiedExcludeCompanyDomainStringArray = excludeCompanyDomainStringArray;
            modifiedExcludeCompanyDomainStringArray.forEach((element) => {
              element.queryField = "Website";
              // element.queryValue = element.queryValue;
              element.excluded = true;
            });
            completeQueryArray.push(modifiedExcludeCompanyDomainStringArray);
          } else {
            //nothing
          }
          done(null, completeQueryArray);
        },

        //US State Query Function
        function (completeQueryArray, done) {
          if (onlyIncludeUSA) {
            if (usStateQueryArray.length > 0) {
              var modifiedUsStateQueryArray = usStateQueryArray;
              modifiedUsStateQueryArray.forEach((element) => {
                element.queryField = "State";
                element.queryValue = element.value;
              });
              completeQueryArray.push(modifiedUsStateQueryArray);
            } else {
              //Nothing
            }
          }
          done(null, completeQueryArray);
        },
        //US City Query Function
        function (completeQueryArray, done) {
          if (citiesInUSAQueryArray.length > 0) {
            var modifiedCitiesInUSAQueryArray = citiesInUSAQueryArray;
            modifiedCitiesInUSAQueryArray.forEach((element) => {
              element.queryField = "City";
              element.queryValue = element.value;
            });
            completeQueryArray.push(modifiedCitiesInUSAQueryArray);
          } else {
            //Nothing
          }
          done(null, completeQueryArray);
        },
        //This is the function that brings it together and makes the array with the strings.
        function (completeQueryArray, done) {
          //An array to push all the strings into - combined later.

          var queryArrayOfStrings = [];

          var count = 0;

          console.log("completeQueryArray", completeQueryArray);

          completeQueryArray.forEach((element) => {
            console.log(element);

            count = count + 1;

            var queryArrayForString = [];

            //If there is only one value in the field. eg: Industry = Accounting ONLY.

            var query = ``;

            if (element.length === 1) {
              //If we're excluding a value in the query.
              if (element[0].excluded) {
                if (count === 1) {
                  if (element[0].queryField === "Title") {
                    const queryElement = element[0].queryValue;
                    query = `WHERE LOWER(${element[0].queryField}) NOT LIKE LOWER('%${queryElement}%')`;
                  } else {
                    query = `WHERE LOWER(${element[0].queryField}) NOT IN ('${element[0].queryValue}')`;
                  }
                  queryArrayForString.push(query);
                } else if (count > 1) {
                  if (element[0].queryField === "Title") {
                    const queryValue = element[0].queryValue;
                    query = `AND LOWER(${
                      element[0].queryField
                    }) NOT LIKE LOWER('%${queryValue.toLowerCase()}%')`;
                  } else {
                    query = `AND ${element[0].queryField} NOT IN ('${element[0].queryValue}')`;
                  }
                  queryArrayForString.push(query);
                }
                //If the value is Title, we need to handle variable capitalization.
              } else if (
                element[0].queryField === "Title" ||
                element[0].queryField === "Company_Name"
              ) {
                //If count = 1, this is the first string and it needs to start with WHERE.
                if (count === 1) {
                  query = `WHERE LOWER(${element[0].queryField}) LIKE LOWER('%${element[0].queryValue}%')`;
                  queryArrayForString.push(query);
                } else if (count > 1) {
                  //If count > 1, this is not the first string and it needs to start with AND.
                  query = `AND LOWER(${element[0].queryField}) LIKE LOWER('%${element[0].queryValue}%')`;
                  queryArrayForString.push(query);
                }
              } else if (element[0].queryField === "formatted_verified_date") {
                if (count === 1) {
                  query = `WHERE SAFE_CAST(${element[0].queryField}  as FLOAT64) > ${element[0].queryValue}`;
                  queryArrayForString.push(query);
                } else if (count > 1) {
                  query = `AND SAFE_CAST(${element[0].queryField}  as FLOAT64) > ${element[0].queryValue}`;
                  queryArrayForString.push(query);
                }
              } else if (element[0].queryField === "No_Employees") {
                if (count === 1) {
                  if (element[0].queryValue === "Not Listed") {
                    query = `WHERE ${element[0].queryField} IS NULL`;
                  } else {
                    query = `WHERE ${element[0].queryField} LIKE '%${
                      element[0].queryValue
                    }%' OR ${element[0].queryField.toLowerCase()} LIKE '%${
                      element[0].queryValue.charAt(0).toUpperCase() +
                      element[0].queryValue.slice(1)
                    }%'`;
                  }
                  queryArrayForString.push(query);
                } else if (count > 1) {
                  //If count > 1, this is not the first string and it needs to start with AND.
                  query = `AND ${element[0].queryField} LIKE '%${element[0].queryValue}%'`;
                  queryArrayForString.push(query);
                }
              } else {
                console.log("Other fields");
                // If the query field is not an excluded field or Title, build the string normally.
                if (count === 1) {
                  query = `WHERE ${element[0].queryField} = '${element[0].queryValue}'`;
                  queryArrayForString.push(query);
                } else if (count > 1) {
                  query = `AND ${element[0].queryField} = '${element[0].queryValue}'`;
                  queryArrayForString.push(query);
                }
              }
              //End of the single field query.
            } else {
              //If there is more than one query per field. EG: Industry = Accounting & Hacking.
              var i = 0;
              var excludedValueArray = [];
              for (i = 0; i < element.length; i++) {
                //If we're excluding a value in the query.
                if (element[0].excluded) {
                  excludedValueArray.push(`'${element[i].queryValue}'`);

                  if (count === 1) {
                    if (i === element.length - 1 && excludedValueArray !== []) {
                      if (element[i].queryField === "Title") {
                        const specificFirstValue = excludedValueArray[0].replace(
                          /'/gi,
                          ""
                        );
                        query = `WHERE LOWER(${element[i].queryField}) NOT LIKE LOWER('%${specificFirstValue}%')`;
                        for (i = 1; i < excludedValueArray.length; i++) {
                          const specificSecondValue = excludedValueArray[
                            i
                          ].replace(/'/gi, "");
                          query =
                            query +
                            ` OR LOWER(${element[0].queryField}) NOT LIKE LOWER('%${specificSecondValue}%')`;
                        }
                        query = queryArrayForString.push(query);
                      } else {
                        query = excludedValueArray.join(",");
                        query = `WHERE ${element[i].queryField} NOT IN (${query})`;
                        query = queryArrayForString.push(query);
                      }
                    } else {
                      //
                    }
                  } else if (count > 1) {
                    if (i === element.length - 1 && excludedValueArray !== []) {
                      if (element[i].queryField === "Title") {
                        const specificFirstValue = excludedValueArray[0].replace(
                          /'/gi,
                          ""
                        );
                        query = `AND LOWER(${element[i].queryField}) NOT LIKE LOWER('%${specificFirstValue}%')`;
                        for (i = 1; i < excludedValueArray.length; i++) {
                          const specificSecondValue = excludedValueArray[
                            i
                          ].replace(/'/gi, "");
                          query =
                            query +
                            ` OR LOWER(${element[0].queryField}) NOT LIKE LOWER('%${specificSecondValue}%')`;
                        }
                      } else {
                        query = excludedValueArray.join(",");
                        query = `AND ${element[i].queryField} NOT IN (${query})`;
                      }
                      query = queryArrayForString.push(query);
                    } else {
                      //
                    }
                  }
                  //If the value is Title, we need to handle variable capitalization.
                } else if (element[0].queryField === "Title") {
                  if (i === 0) {
                    if (count === 1) {
                      query = `WHERE LOWER(${element[0].queryField}) LIKE LOWER('%${element[0].queryValue}%')`;
                      queryArrayForString.push(query);
                    } else if (count > 1) {
                      query = `OR LOWER(${element[0].queryField}) LIKE LOWER('%${element[0].queryValue}%')`;
                      queryArrayForString.push(query);
                    }
                  } else if (i !== 0) {
                    query = ` OR LOWER(${element[i].queryField}) LIKE LOWER('%${element[i].queryValue}%')`;
                    queryArrayForString.push(query);
                  } else {
                    break;
                  }
                } else if (element[0].queryField === "No_Employees") {
                  if (i === 0) {
                    if (count === 1) {
                      if (element[i].queryValue === "Not Listed") {
                        query = `WHERE ${element[0].queryField} IS NULL`;
                      } else {
                        query = `WHERE ${element[0].queryField} LIKE '%${element[0].queryValue}%'`;
                      }
                      queryArrayForString.push(query);
                    } else if (count > 1) {
                      console.log("In count 2", element[i].queryValue);
                      // query = `AND ${element[0].queryField} IS NULL`;
                      query = `AND ${element[0].queryField} LIKE '%${element[0].queryValue}%'`;
                      queryArrayForString.push(query);
                    }
                  } else if (i !== 0) {
                    if (element[i].queryValue === "Not Listed") {
                      query = ` OR ${element[i].queryField} IS NULL'`;
                    } else {
                      query = ` OR LOWER(${element[i].queryField}) LIKE LOWER('%${element[i].queryValue}%')`;
                    }
                    queryArrayForString.push(query);
                  } else {
                    break;
                  }
                } else if (
                  element[0].queryField === "formatted_verified_date"
                ) {
                  if (count === 1) {
                    query = `WHERE SAFE_CAST(${element[0].queryField} as FLOAT64) > ${element[0].queryValue}`;
                    queryArrayForString.push(query);
                  } else if (count > 1) {
                    query = `AND SAFE_CAST(${element[0].queryField} as FLOAT64) > ${element[0].queryValue}`;
                    queryArrayForString.push(query);
                  }
                } else {
                  // Just add the values normally.
                  if (i === 0) {
                    if (count === 1) {
                      query = `WHERE ${element[0].queryField} = '${element[0].queryValue}'`;
                      queryArrayForString.push(query);
                    } else if (count > 1) {
                      query = `AND ${element[0].queryField} = '${element[0].queryValue}'`;
                      queryArrayForString.push(query);
                    }
                  } else if (i !== 0) {
                    query = ` OR ${element[i].queryField} = '${element[i].queryValue}'`;
                    queryArrayForString.push(query);
                  } else {
                    break;
                  }
                }
              }
            }

            // If there's an OR in the query, we need to put some brackets around it.

            const fullQuery = queryArrayForString.join("");
            if (fullQuery.includes("OR") && fullQuery.includes("Title")) {
              const insertStart = "(";
              const insertEnd = ")";
              const startIndex = fullQuery.indexOf("LOWER");
              const endIndex = fullQuery.length;

              //'Hello RegExp!'.replace(/^(.{6})(.)/, `$1Lovely $2`);
              var modifiedStringWithBrackets = fullQuery.replace(
                new RegExp(`^(.{${startIndex}})(.)`),
                `$1${insertStart}$2`
              );

              modifiedStringWithBrackets = modifiedStringWithBrackets.replace(
                new RegExp(`^(.{${endIndex}})(.)`),
                `$1${insertEnd}$2`
              );

              queryArrayOfStrings.push(modifiedStringWithBrackets);
            } else {
              queryArrayOfStrings.push(fullQuery);
            }
          });
          done(null, queryArrayOfStrings);
        },
        //Function that builds the query strings from the array above.
        function (queryArrayOfStrings, done) {
          queryArrayOfStrings.forEach((element) => {
            console.log(element, "ELEMENT");
            if (element.includes("OR")) {
              if (
                element.startsWith("WHERE") &&
                element.indexOf("Title") === -1
              ) {
                var index = queryArrayOfStrings.indexOf(element); //The index of the element.
                element = element.replace(
                  new RegExp(`^(.{${6}})(.)`),
                  `$1${"("}$2`
                );
                element = element + ")";
                queryArrayOfStrings[index] = element;
                // queryArrayOfStrings.element = element;
              }
              if (
                element.startsWith("AND") &&
                element.indexOf("Title") === -1
              ) {
                index = queryArrayOfStrings.indexOf(element); //The index of the element.
                element = element.replace(
                  new RegExp(`^(.{${4}})(.)`),
                  `$1${"("}$2`
                );
                element = element + ")";
                queryArrayOfStrings[index] = element;
              }
            }
          });

          if (onlyIncludeUSA) {
            queryArrayOfStrings.push("AND Country = 'US'");
          }

          if (onlyIncludeLinkedIn) {
            queryArrayOfStrings.push("AND Person_Linkedin_Url IS NOT NULL");
          }

          savedQueryString.setState({
            savedQueryString: queryArrayOfStrings.join("\n"),
          });

          completeQueryArrayState.setState({
            completeQueryArrayState: completeQueryArray,
          });

          completeQueryArray = queryArrayOfStrings.join("\n"); //Converts into string..
          done(null, "done");
          // completeQueryArray.forEach(element => {
          //   console.log('Element:', element);
          // });
          console.log(completeQueryArray, "CQA");
          return completeQueryArray;
        },
      ],
      function end(err) {
        if (err) {
          throw new Error(err);
        } else {
          console.log(completeQueryArray, "Final Query String"); //Here is the final string. Where it gets pushed it through.
        }
      }
    );
    return completeQueryArray;
  }

  logQuery(query) {
    var getCountOfQuery = firebase.functions().httpsCallable("getCountOfQuery");

    const userID = this.state.userID;
    const displayName = this.state.displayName;

    getCountOfQuery({
      query: query,
    }).then(function (result) {
      firebase
        .firestore()
        .collection("queryLogs")
        .add({
          query: query,
          userID: userID,
          displayName: displayName,
          time: String(new Date()),
          count: result.data[0].f0_,
          sortingTime: Math.floor(Date.now() / 1000) * -1,
        })
        .then(() => {
          console.log("Query Logged");
        });
    });
  }

  // This toggles whether a user can make the query or not.
  // If they were able to search with no data, they'd return the whole DB.
  // With millions of results.

  checkSearchStatus() {
    if (this.state.industryQueryArray.length > 0) {
      return false;
    }
    if (this.state.excludeIndustryQueryArray.length > 0) {
      return false;
    }
    if (this.state.titleQueryArray.length > 0) {
      return false;
    }
    if (this.state.jobTitleQueryArray.length > 0) {
      return false;
    }
    if (this.state.excludeCompanyQueryArray.length > 0) {
      return false;
    }
    if (this.state.includeCompanyQueryArray.length > 0) {
      return false;
    }
    if (this.state.excludeJobTitleQueryArray.length > 0) {
      return false;
    }
    if (this.state.emailValidationQueryArray.length > 0) {
      return false;
    }
    if (this.state.emailValidationDateQueryArray.length > 0) {
      return false;
    }
    if (this.state.onlyIncludeUSA) {
      return false;
    }
    if (this.state.onlyIncludeLinkedIn) {
      return false;
    }
    if (this.state.selectedSavedQueryString) {
      return false;
    }
    if (this.state.includeCompanyDomain !== "") {
      return false;
    }
    if (this.state.includeCompanyDomainStringArray.length > 0) {
      return false;
    }
    if (this.state.excludeCompanyDomainStringArray.length > 0) {
      return false;
    }
    if (this.state.includeLinkedInDomain !== "") {
      return false;
    }
    if (this.state.excludedCompanyDomain !== "") {
      return false;
    }
    if (this.state.employeeSizeQueryArray.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  //Remove an item from the Job Title Query Array
  removeJobTitleItem(item) {
    var tempJobTitleQueryArray = this.state.jobTitleQueryArray;
    const index = tempJobTitleQueryArray.indexOf(item);
    tempJobTitleQueryArray.splice(index, 1);

    this.setState({ jobTitleQueryArray: tempJobTitleQueryArray });
    setTimeout(() => {
      this.getCount();
    }, 500);
  }

  removeExcludedJobTitleItem(item) {
    var tempExcludedJobTitleQueryArray = this.state.excludeJobTitleQueryArray;
    const index = tempExcludedJobTitleQueryArray.indexOf(item);
    tempExcludedJobTitleQueryArray.splice(index, 1);

    this.setState({
      excludeJobTitleQueryArray: tempExcludedJobTitleQueryArray,
    });
    setTimeout(() => {
      this.getCount();
    }, 500);
  }

  removeExcludedCompanyItem(item) {
    var tempExcludedCompanyQueryArray = this.state.excludeCompanyQueryArray;
    const index = tempExcludedCompanyQueryArray.indexOf(item);
    tempExcludedCompanyQueryArray.splice(index, 1);

    this.setState({
      excludeCompanyQueryArray: tempExcludedCompanyQueryArray,
    });
    setTimeout(() => {
      this.getCount();
    }, 500);
  }

  removeIncludeCompanyDomainStringArray(item) {
    var tempIncludeCompanyDomainStringArray = this.state
      .includeCompanyDomainStringArray;
    const index = tempIncludeCompanyDomainStringArray.indexOf(item);
    tempIncludeCompanyDomainStringArray.splice(index, 1);

    this.setState({
      includeCompanyDomainStringArray: tempIncludeCompanyDomainStringArray,
    });
    setTimeout(() => {
      this.getCount();
    }, 500);
  }

  removeExcludedCompanyDomainStringArray(item) {
    var tempExcludeCompanyDomainStringArray = this.state
      .excludeCompanyDomainStringArray;
    const index = tempExcludeCompanyDomainStringArray.indexOf(item);
    tempExcludeCompanyDomainStringArray.splice(index, 1);

    this.setState({
      excludeCompanyDomainStringArray: tempExcludeCompanyDomainStringArray,
    });
    setTimeout(() => {
      this.getCount();
    }, 500);
  }

  removeIncludedCompanyItem(item) {
    var tempIncludedCompanyQueryArray = this.state.includeCompanyQueryArray;
    const index = tempIncludedCompanyQueryArray.indexOf(item);
    tempIncludedCompanyQueryArray.splice(index, 1);

    this.setState({
      includeCompanyQueryArray: tempIncludedCompanyQueryArray,
    });
    setTimeout(() => {
      this.getCount();
    }, 500);
  }

  getSavedQueries() {
    var result = [];
    var savedQueryArray = this;

    firebase
      .firestore()
      .collection("queries")
      .where("userID", "==", this.state.userID)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          result.push({
            label: doc.data().queryName,
            value: doc.data().queryString,
            id: doc.id,
            array: doc.data().array,
          });
        });
        return result;
      })
      .then(function (result) {
        savedQueryArray.setState({ savedQueryArray: result });
      });
  }

  validateContacts() {
    this.setState({ validationLoading: true });

    firebase
      .firestore()
      .collection("contactsToValidate")
      .add({
        query: `${this.buildingNewArray()}`,
        userID: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
        date: moment().format("X"),
        processed: false,
        count: this.state.contactSaveCount,
        listName: this.state.savedListName,
      })
      .then(() => {
        this.showSnack("Validation Started. Please keep an eye on your email.");
        this.setState({ validationLoading: false });
        this.setState({ showValidationModal: false });
        this.setState({ savedListName: "" });
      })
      .catch((err) => {
        this.showSnack("There has been an error, please contact support");
        this.setState({ validationLoading: false });
        this.setState({ showValidationModal: false });
        this.setState({ savedListName: "" });
      });
  }

  render() {
    const data = this.state.returnedData;
    const validated = [];
    data.forEach((datapoint) => {
      if (datapoint.ValidationStatus) {
        validated.push(datapoint);
      }
    });

    if (this.state.user.role === "admin") {
      var columns = [
        {
          Header: "Title",
          accessor: "Title",
          className: "font-bold ml-6",
        },
        { Header: "First Name", accessor: "First_Name" },
        { Header: "Last Name", accessor: "Last_Name" },
        {
          Header: "Email",
          accessor: "Email",
          Cell: (props) => (
            <div style={{ textAlign: "center" }}>
              <Tooltip title={props.value}>
                <a href={`mailto:${props.value}`}>
                  <EmailIcon
                    style={{
                      color: "#005ebc",
                      fontSize: 16,
                    }}
                  />
                </a>
              </Tooltip>
            </div>
          ),
        },
        { Header: "Industry", accessor: "Industry" },
        {
          Header: "Website",
          accessor: "Website",
          Cell: (props) => (
            <div>
              {props.value !== null && (
                <div style={{ textAlign: "center" }}>
                  <Tooltip title={props.value}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        props.value.includes("http")
                          ? `${props.value}`
                          : `https://${props.value}`
                      }
                    >
                      <LanguageIcon
                        style={{
                          color: "#005ebc",
                          fontSize: 16,
                        }}
                      />
                    </a>
                  </Tooltip>
                </div>
              )}
            </div>
          ),
        },
        { Header: "Company Name", accessor: "Company_Name" },
        { Header: "City", accessor: "City" },
        { Header: "State", accessor: "State" },
        { Header: "Country", accessor: "Country" },
        { Header: "Technologies", accessor: "Technologies" },
        { Header: "Job Level", accessor: "Job_Level" },
        { Header: "Email Status", accessor: "Email_Status" },
        { Header: "First Phone", accessor: "First_Phone" },
        { Header: "Corporate Phone", accessor: "Corporate_Phone" },
        { Header: "# Employees", accessor: "No_Employees" },
        { Header: "Annual Revenue", accessor: "Annual_Revenue" },
        {
          Header: "Personal LinkedIn",
          accessor: "Person_Linkedin_Url",
          Cell: (props) => (
            <div style={{ textAlign: "center" }}>
              {props.value !== null && (
                <Tooltip title={props.value}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.value.includes("http")
                        ? `${props.value}`
                        : `https://${props.value}`
                    }
                  >
                    <LinkedInIcon
                      style={{
                        color: "#005ebc",
                        fontSize: 16,
                      }}
                    />
                  </a>
                </Tooltip>
              )}
            </div>
          ),
        },
        {
          Header: "Company LinkedIn",
          accessor: "Company_Linkedin_Url",
          Cell: (props) => (
            <div style={{ textAlign: "center" }}>
              {props.value !== null && (
                <Tooltip title={props.value}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.value.includes("http")
                        ? `${props.value}`
                        : `https://${props.value}`
                    }
                  >
                    <LinkedInIcon
                      style={{
                        color: "#005ebc",
                        fontSize: 16,
                      }}
                    />
                  </a>
                </Tooltip>
              )}
            </div>
          ),
        },
        {
          Header: "Facebook",
          accessor: "Facebook_Url",
          Cell: (props) => (
            <div style={{ textAlign: "center" }}>
              {props.value !== null && (
                <Tooltip title={props.value}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.value.includes("http")
                        ? `${props.value}`
                        : `https://${props.value}`
                    }
                  >
                    <FacebookIcon
                      style={{
                        color: "#005ebc",
                        fontSize: 16,
                      }}
                    />
                  </a>
                </Tooltip>
              )}
            </div>
          ),
        },
        {
          Header: "Twitter",
          accessor: "Twitter_Url",
          Cell: (props) => (
            <div style={{ textAlign: "center" }}>
              {props.value !== null && (
                <Tooltip title={props.value}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.value.includes("http")
                        ? `${props.value}`
                        : `https://${props.value}`
                    }
                  >
                    <TwitterIcon
                      style={{
                        color: "#005ebc",
                        fontSize: 16,
                      }}
                    />
                  </a>
                </Tooltip>
              )}
            </div>
          ),
        },
        { Header: "Keywords", accessor: "Keywords" },
        { Header: "Contact Address 1", accessor: "Contact_Address_1" },
        { Header: "Contact Address 2", accessor: "Contact_Address_2" },
        { Header: "Contact Address 3", accessor: "Contact_Address_3" },
        { Header: "Zip Code", accessor: "Zip_Code" },
        { Header: "Company Address", accessor: "Company_Address" },
        { Header: "Company Address 2", accessor: "Company_Address_2" },
        { Header: "Company City", accessor: "Company_City" },
        { Header: "Company State", accessor: "Company_State" },
        { Header: "Company Zip", accessor: "Company_Zip_Code" },
        { Header: "Company Country", accessor: "Company_Country" },
        { Header: "Company Phone", accessor: "Company_Phone" },
        { Header: "SIC Code", accessor: "SIC_Code" },
        { Header: "Naics Code", accessor: "Naics_Code" },
        { Header: "Validation Status ID", accessor: "ValidationStatusId" },
        { Header: "Validation Status", accessor: "ValidationStatus" },
        { Header: "EmailDomain Group IS", accessor: "EmailDomainGroupId" },
        { Header: "Email Domain Group", accessor: "EmailDomainGroup" },
        { Header: "SEO Description", accessor: "SEO_Description" },
        { Header: "EOF", accessor: "EOF" },
        {
          Header: "Number_of_Retail_Locations",
          accessor: "Number_of_Retail_Locations",
        },
      ];
    } else {
      columns = [
        {
          Header: "Title",
          accessor: "Title",
          className: "font-bold ml-6",
        },
        { Header: "First Name", accessor: "First_Name" },
        { Header: "Industry", accessor: "Industry" },
        {
          Header: "Website",
          accessor: "Website",
          Cell: (props) => (
            <div style={{ textAlign: "center" }}>
              {props.value !== null && (
                <Tooltip title={props.value}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.value.includes("http")
                        ? `${props.value}`
                        : `https://${props.value}`
                    }
                  >
                    <LanguageIcon
                      style={{
                        color: "#d2d2d2",
                        fontSize: 16,
                      }}
                    />
                  </a>
                </Tooltip>
              )}
            </div>
          ),
        },
        { Header: "Company Name", accessor: "Company_Name" },
        { Header: "City", accessor: "City" },
        { Header: "State", accessor: "State" },
        { Header: "Country", accessor: "Country" },
      ];
    }

    const industryList = this.state.industryList;
    const titleList = this.state.titleList;
    const employeeSizeList = this.state.employeeSizeList;
    const usStateList = this.state.usStateList;
    const arrayOfCitiesList = this.state.arrayOfCitiesList;
    const emailValidation = this.state.emailValidationTypes;
    const emailValidationDates = this.state.emailValidationDates;

    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* Sidebar */}
          <div style={customStyles.sideBar}>
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              Build A New Query
            </div>
            <div
              style={{
                border: "solid 1px #d2d2d2",
                borderRadius: 5,
                marginTop: 10,
              }}
            >
              <div>
                <input
                  placeholder="Included Job Titles"
                  style={customStyles.sideBarInput}
                  type="text"
                  id="jobTitleSearch"
                  onChange={this.handleJobTitleChange}
                  InputLabelProps={{
                    style: { color: "#666" },
                  }}
                  InputProps={{
                    classes: {
                      focused: { border: "none" },
                      notchedOutline: { color: "#ffffff" },
                    },
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.target.value !== "") {
                      var tempJobTitleQueryArray = this.state
                        .jobTitleQueryArray;
                      tempJobTitleQueryArray.push({
                        queryField: "Title",
                        queryValue: e.target.value,
                      });
                      e.target.value = "";

                      this.setState({
                        jobTitleQueryArray: tempJobTitleQueryArray,
                      });
                      setTimeout(() => {
                        this.jobTitlesEnd.scrollIntoView({
                          behavior: "smooth",
                        });
                      }, 300);
                    }
                  }}
                />
              </div>
              <div
                ref="jobTitleRef"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {this.state.jobTitleQueryArray.map((item) => {
                  return (
                    <div key={Math.random()} style={{ padding: 2 }}>
                      <Chip
                        style={{
                          borderRadius: 0,
                          margin: 2,
                          fontSize: 12,
                        }}
                        label={item.queryValue}
                        onDelete={() => {
                          this.removeJobTitleItem(item);
                        }}
                        deleteIcon={
                          <div
                            style={{
                              justifyContent: "center",
                              color: "#000000",
                              fontSize: 10,
                              fontWeight: "bold",
                              paddingTop: 3,
                              paddingLeft: 5,
                            }}
                          >
                            X
                          </div>
                        }
                      />
                    </div>
                  );
                })}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.jobTitlesEnd = el;
                  }}
                ></div>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                // style={customStyles.selectInput}
                options={titleList}
                isMulti
                value={this.state.titleQueryArray}
                name="titleQueryArray"
                placeholder="Select Title Level"
                onChange={this.handleTitleLevelChange}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Select
                options={employeeSizeList}
                isMulti
                value={this.state.employeeSizeQueryArray}
                name="employeeSizeQueryArray"
                placeholder="Select Company Size"
                onChange={this.handleEmployeeSizeChange}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                // style={customStyles.selectInput}
                options={industryList}
                isMulti
                value={this.state.industryQueryArray}
                name="industryQueryArray"
                placeholder="Select Industry"
                onChange={this.handleIndustryChange}
              />
            </div>
            <Button
              disabled={this.state.showLoading}
              onClick={() => {
                alert("This feature is coming soon.");
              }}
              style={customStyles.sidebarButtonStyle}
            >
              <p>Select Technographics</p>
            </Button>
            <Button
              disabled={this.state.showLoading}
              onClick={() => {
                this.setState({ showQueryBuilder: true });
              }}
              style={customStyles.sidebarButtonStyle}
            >
              <p>See More Options</p>
            </Button>
            <Button
              disabled={this.state.showLoading}
              onClick={() => {
                this.callFilterFunction();
                // this.buildingNewArray();
                this.setState({ showQueryBuilder: false });
              }}
              style={customStyles.sidebarButtonStyle}
            >
              <p>Run Query</p>
            </Button>
            {this.state.returnedData.length > 0 && (
              <Button
                onClick={() => {
                  this.setState({ showValidationModal: true });
                }}
                disabled={this.checkSearchStatus()}
                style={customStyles.sidebarButtonStyle}
              >
                Validate & Save
              </Button>
            )}
          </div>
          {/* Table of results */}
          <div
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderBottom: "solid 1px #d2d2d2",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 80,
                  paddingLeft: 30,
                }}
              >
                <div
                  className="input-field"
                  style={{
                    width: 300,
                    maxHeight: 50,
                    marginRight: 30,
                  }}
                >
                  <Select
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: "#fff",
                        borderColor: "#9e9e9e",
                        minHeight: "50px",
                        height: "50px",
                        boxShadow: state.isFocused ? null : null,
                      }),

                      valueContainer: (provided, state) => ({
                        ...provided,
                        height: "50px",
                        padding: "0 6px",
                      }),

                      input: (provided, state) => ({
                        ...provided,
                        margin: "0px",
                      }),
                      indicatorSeparator: (state) => ({
                        display: "none",
                      }),
                      indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: "50px",
                      }),
                    }}
                    options={this.state.savedQueryArray}
                    name="industryQueryArray"
                    placeholder="Select Saved Query"
                    onChange={this.handleSavedQueryChange}
                  />
                </div>
                {this.state.returnedData.length > 0 && (
                  <Button
                    onClick={() => {
                      this.downloadCSVFunction();
                    }}
                    disabled={this.checkSearchStatus()}
                    style={customStyles.buttonStyle}
                  >
                    Download CSV Of Result
                  </Button>
                )}
                {this.state.countOnTheFly > 0 &&
                !this.state.countOnTheFlyLoading ? (
                  <div>
                    <b>
                      Estimated Results:{" "}
                      {this.state.countOnTheFly.toLocaleString()}
                    </b>
                  </div>
                ) : (
                  <div>
                    {!this.state.countOnTheFlyLoading && (
                      <b>No results for this query.</b>
                    )}
                  </div>
                )}
                {this.state.countOnTheFlyLoading && (
                  <div>
                    <b>Getting count...</b>
                  </div>
                )}
                {this.state.showLoading && (
                  <div style={{ marginLeft: 30 }}>
                    <CircularProgress />
                  </div>
                )}
                <div style={{ flex: 1 }}></div>
                {this.state.creditsLoaded && (
                  <div style={{ marginRight: 80 }}>
                    <b>
                      You have {this.state.creditBalance} credits remaining in{" "}
                      {moment().format("MMMM")}
                    </b>
                  </div>
                )}
                {/* <Button
                  disabled={this.state.showLoading}
                  onClick={() => {
                    this.setState({ showQueryBuilder: true });
                  }}
                  style={customStyles.buttonStyle}
                >
                  <p>Save List To User</p>
                </Button> */}
              </div>
              <ReactTable
                style={{ width: "auto", maxWidth: "75vw", margin: 30 }}
                className="-striped -highlight border-0"
                defaultPageSize={15}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                noDataText="No query results"
                data={data}
                columns={columns}
              />
            </div>
          </div>
        </div>
        <Snackbar
          style={{ marginTop: 50 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.showSnack}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({ showSnack: false });
            this.setState({ snackMessage: false });
          }}
          message={this.state.snackMessage}
        />
        <Dialog
          open={this.state.showValidationModal}
          onClose={() => {
            // Close
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle id="form-dialog-title">
            Validate & Save Contacts
          </DialogTitle>
          <DialogContent>
            <div style={{ marginTop: 10 }}>
              <p>How many contacts would you like to save?</p>
              <input
                placeholder={`Max ${this.state.creditBalance}`}
                style={customStyles.modalInput}
                type="number"
                id="contactSaveCount"
                onChange={(e) => {
                  this.setState({ contactSaveCount: parseInt(e.target.value) });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <p>What would you like to call this list?</p>
              <input
                placeholder={`Your List Name`}
                style={customStyles.modalInput}
                type="text"
                id="savedListName"
                onChange={(e) => {
                  this.setState({ savedListName: e.target.value });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <p>
                We will validate your contacts and save them in your contacts
                list. You will get an email once the list has completed
                processing.
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.validationLoading}
              onClick={() => {
                // Cancel
                this.setState({ showValidationModal: false });
              }}
              color="primary"
            >
              Close
            </Button>
            <Button
              disabled={this.state.validationLoading}
              onClick={() => {
                if (this.state.contactSaveCount === 0) {
                  this.showSnack("No credits allocated");
                } else if (this.state.returnedData.length === 0) {
                  this.showSnack("No contacts in result");
                } else if (
                  this.state.contactSaveCount > this.state.creditBalance
                ) {
                  this.showSnack("You do not have enough credits");
                } else if (this.state.savedListName === "") {
                  this.showSnack("Please name this list.");
                } else {
                  this.validateContacts();
                }
                // Save query to DB and send prompt.
              }}
              color="primary"
            >
              {this.state.validationLoading ? "Loading..." : "Start Validation"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Query Builder Modal */}
        <Dialog
          open={this.state.showQueryBuilder}
          onClose={() => {
            // Close
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"lg"}
        >
          <DialogTitle id="form-dialog-title">Build A New Query</DialogTitle>
          <DialogContent>
            {/* Start of query fields */}
            {this.state.editableQuery ? (
              <div>
                {/* Start of the whole form */}
                {/* Include Job Titles */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Col 1 */}
                  <div style={{ width: "50%", margin: 10 }}>
                    <div className="input-field">
                      <label htmlFor="jobTitleSearch">
                        <i>
                          Add A Job Title To <b>include</b> in the Query
                        </i>
                      </label>
                      <input
                        type="text"
                        id="jobTitleSearch"
                        onChange={this.handleJobTitleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            var tempJobTitleQueryArray = this.state
                              .jobTitleQueryArray;
                            tempJobTitleQueryArray.push({
                              queryField: "Title",
                              queryValue: e.target.value,
                            });
                            e.target.value = "";

                            this.setState({
                              jobTitleQueryArray: tempJobTitleQueryArray,
                            });
                          }
                        }}
                      />
                    </div>
                    {this.state.jobTitleQueryArray.map((item) => {
                      return (
                        <div key={Math.random()} className="chip">
                          <Row>
                            <div>
                              {item.queryValue}
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  borderRadius: 0,
                                  borderWidth: 0,
                                  padding: 10,
                                }}
                                onClick={() => {
                                  this.removeJobTitleItem(item);
                                }}
                              >
                                X
                              </button>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </div>

                  {/* Col 2 */}
                  {/* Exclude Job Titles */}

                  <div style={{ width: "50%", margin: 10 }}>
                    <div className="input-field">
                      <label htmlFor="excludeJobTitleSearch">
                        <i>
                          Add A Job Title To <b>exclude</b> in the Query
                        </i>
                      </label>
                      <input
                        type="text"
                        id="excludeJobTitleSearch"
                        onChange={this.handleExcludeJobTitleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            var tempExcludeJobTitleQueryArray = this.state
                              .excludeJobTitleQueryArray;
                            tempExcludeJobTitleQueryArray.push({
                              queryField: "Title",
                              queryValue: e.target.value,
                              exclude: true,
                            });
                            e.target.value = "";

                            this.setState({
                              tempExcludeJobTitleQueryArray: tempExcludeJobTitleQueryArray,
                            });
                          }
                        }}
                      />
                    </div>
                    {this.state.excludeJobTitleQueryArray.map((item) => {
                      return (
                        <div key={Math.random()} className="chip">
                          <Row>
                            <div>
                              {item.queryValue}
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  borderRadius: 0,
                                  borderWidth: 0,
                                  padding: 10,
                                }}
                                onClick={() => {
                                  this.removeExcludedJobTitleItem(item);
                                }}
                              >
                                X
                              </button>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* Row 2 */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="input-field"
                    style={{ width: "50%", margin: 10 }}
                  >
                    <Select
                      options={industryList}
                      isMulti
                      value={this.state.industryQueryArray}
                      name="industryQueryArray"
                      placeholder="Include Industry"
                      onChange={this.handleIndustryChange}
                    />
                  </div>
                  <div
                    className="input-field"
                    style={{ width: "50%", margin: 10 }}
                  >
                    <Select
                      options={industryList}
                      isMulti
                      name="excludeIndustryQueryArray"
                      value={this.state.excludeIndustryQueryArray}
                      placeholder="Exclude Industry"
                      onChange={this.handleExcludeIndustryChange}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="input-field"
                    style={{ width: "50%", margin: 10 }}
                  >
                    <Select
                      options={titleList}
                      isMulti
                      value={this.state.titleQueryArray}
                      name="titleQueryArray"
                      placeholder="Select Title Level"
                      onChange={this.handleTitleLevelChange}
                    />
                  </div>
                  <div
                    className="input-field"
                    style={{ width: "50%", margin: 10 }}
                  >
                    <Select
                      options={employeeSizeList}
                      isMulti
                      value={this.state.employeeSizeQueryArray}
                      name="employeeSizeQueryArray"
                      placeholder="Select Company Size"
                      onChange={this.handleEmployeeSizeChange}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="input-field"
                    style={{ width: "50%", margin: 10 }}
                  >
                    <Select
                      options={emailValidation}
                      isMulti
                      value={this.state.emailValidationQueryArray}
                      name="emailValidationQueryArray"
                      placeholder="Email Validation Types"
                      onChange={this.handleEmailValidationChange}
                    />
                  </div>
                  <div
                    className="input-field"
                    style={{ width: "50%", margin: 10 }}
                  ></div>
                </div>
                {/* <div className="input-field">
                  <Select
                    options={emailValidationDates}
                    name="emailValidationDateQueryArray"
                    value={this.state.emailValidationDateQueryArray}
                    placeholder="Email Validation Date"
                    onChange={this.handleEmailValidationDateChange}
                  />
                </div> */}

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "50%", margin: 10 }}>
                    <div className="input-field">
                      <label htmlFor="includeCompany">
                        <i>
                          Add A Company to <b>include</b> in your query (will
                          only include the what is listed below).
                        </i>
                      </label>
                      <input
                        type="text"
                        id="includeCompany"
                        onChange={this.handleIncludeCompanyChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            var tempIncludeCompanyQueryArray = this.state
                              .includeCompanyQueryArray;
                            tempIncludeCompanyQueryArray.push({
                              queryField: "Company_Name",
                              queryValue: e.target.value,
                            });
                            e.target.value = "";
                            this.setState({
                              includeCompanyQueryArray: tempIncludeCompanyQueryArray,
                            });
                          }
                        }}
                      />
                    </div>

                    {this.state.includeCompanyQueryArray.map((item) => {
                      return (
                        <div key={Math.random()} className="chip">
                          <Row>
                            <div>
                              {item.queryValue}
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  borderRadius: 0,
                                  borderWidth: 0,
                                  padding: 10,
                                }}
                                onClick={() => {
                                  this.removeIncludedCompanyItem(item);
                                }}
                              >
                                X
                              </button>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                  {/* Aqui */}
                  <div style={{ width: "50%", margin: 10 }}>
                    <div className="input-field">
                      <label htmlFor="excludeCompany">
                        <i>
                          Add A Company to <b>exclude</b> in your query.
                        </i>
                      </label>
                      <input
                        type="text"
                        id="excludeCompany"
                        onChange={this.handleExcludeCompanyChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            var tempExcludeCompanyQueryArray = this.state
                              .excludeCompanyQueryArray;
                            tempExcludeCompanyQueryArray.push({
                              queryField: "Company_Name",
                              queryValue: e.target.value,
                            });
                            e.target.value = "";
                            this.setState({
                              excludeCompanyQueryArray: tempExcludeCompanyQueryArray,
                            });
                          }
                        }}
                      />
                    </div>

                    {this.state.excludeCompanyQueryArray.map((item) => {
                      return (
                        <div key={Math.random()} className="chip">
                          <Row>
                            <div>
                              {item.queryValue}
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  borderRadius: 0,
                                  borderWidth: 0,
                                  padding: 10,
                                }}
                                onClick={() => {
                                  this.removeExcludedCompanyItem(item);
                                }}
                              >
                                X
                              </button>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* LinkedIn File upload start */}
                <Modal
                  isOpen={this.state.showSelectHeaderDialog_LinkedIn}
                  style={customStyles}
                  contentLabel="Match Column"
                  ariaHideApp={false}
                  onRequestClose={() => {
                    this.setState({
                      showSelectHeaderDialog_LinkedIn: false,
                    });
                    this.setState({
                      resultsFromLinkedIncludeCSV: [],
                    });
                    this.setState({
                      headersToSelect_LinkedIn: [],
                    });
                  }}
                >
                  <div
                    style={{
                      borderBottom: "solid",
                      borderBottomColor: "#666",
                      borderBottomWidth: 1,
                      cursor: "pointer",
                      color: "#666",
                      marginBottom: 40,
                    }}
                  >
                    Pick a column to use:
                  </div>
                  {this.state.headersToSelect_LinkedIn.length > 0 ? (
                    <div>
                      {this.state.headersToSelect_LinkedIn.map((key) => (
                        <div
                          onClick={() => {
                            this.createLinkedInIncludeQuery(key);
                          }}
                          key={Math.random()}
                          style={{
                            borderBottom: "solid",
                            borderBottomColor: "#666",
                            borderBottomWidth: 1,
                            cursor: "pointer",
                          }}
                        >
                          <p>{key}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Your CSV is invalid.</p>
                  )}
                </Modal>
                {/* <label htmlFor="includeLinkedInDomain">
                  <i>
                    Upload a CSV with <b>LinkedIn URLS</b> to <b>include</b> in
                    your query.
                  </i>
                </label> */}
                {/* {this.state.includeLinkedInDomain.length === 0 ? (
                  <FilePicker
                    extensions={['csv']}
                    maxSize={10000000}
                    onChange={(FileObject) => {
                      console.log(FileObject, 'Linked we here?');
                      // Show headers to pick.

                      var arrayOfData = [];

                      var resultsFromLinkedIncludeCSV = this;
                      var showSelectHeaderDialog_LinkedIn = this;
                      var headersToSelect_LinkedIn = this;

                      const config = {
                        quoteChar: '"',
                        escapeChar: '"',
                        header: true,
                        download: true,
                        step: function (step, parser) {
                          arrayOfData.push(step.data);
                        },
                        complete: function (results, file) {
                          // Show a dialog to select which column you'd like to use.
                          resultsFromLinkedIncludeCSV.setState({
                            resultsFromLinkedIncludeCSV: arrayOfData,
                          });
                          showSelectHeaderDialog_LinkedIn.setState({
                            showSelectHeaderDialog_LinkedIn: true,
                          });
                          headersToSelect_LinkedIn.setState({
                            headersToSelect_LinkedIn: Object.keys(
                              arrayOfData[0]
                            ),
                          });
                        },
                        skipEmptyLines: true,
                      };

                      Papa.parse(FileObject, config);
                    }}
                    onError={(errMsg) => {
                      console.log(errMsg, 'Error');
                      //  this.setState({ error: errMsg });
                    }}
                  >
                    <button
                      onClick={() => {
                        //  this.setState({ fileUploaded: '' });
                      }}
                      className="btn green"
                      style={{ marginTop: '15px' }}
                    >
                      Pick CSV
                    </button>
                  </FilePicker>
                ) : (
                  <div>
                    <p style={{ paddingTop: 20, paddingBottom: 20 }}>
                      {this.state.includeLinkedInDomain.substring(0, 100)}
                      ...
                    </p>

                    <button
                      onClick={() => {
                        this.setState({ includeLinkedInDomain: '' });
                      }}
                      className="btn green"
                      style={{ marginTop: '15px', marginBottom: '15px' }}
                    >
                      Clear CSV
                    </button>
                  </div>
                )} */}

                <br />

                {/* Linkedin File Upload end */}

                {/* File input for include domains here */}
                {/* Start of include domains */}

                <Modal
                  isOpen={this.state.showSelectHeaderDialog}
                  style={customStyles}
                  contentLabel="Match Column"
                  ariaHideApp={false}
                  onRequestClose={() => {
                    this.setState({ showSelectHeaderDialog: false });
                    this.setState({
                      resultsFromDomainIncludeCSV: [],
                    });
                    this.setState({
                      headersToSelect: [],
                    });
                  }}
                >
                  <div
                    style={{
                      borderBottom: "solid",
                      borderBottomColor: "#666",
                      borderBottomWidth: 1,
                      cursor: "pointer",
                      color: "#666",
                      marginBottom: 40,
                    }}
                  >
                    Pick a column to use:
                  </div>
                  {this.state.headersToSelect.length > 0 ? (
                    <div>
                      {this.state.headersToSelect.map((key) => (
                        <div
                          onClick={() => {
                            this.createWebsiteIncludeQuery(key);
                          }}
                          key={Math.random()}
                          style={{
                            borderBottom: "solid",
                            borderBottomColor: "#666",
                            borderBottomWidth: 1,
                            cursor: "pointer",
                          }}
                        >
                          <p>X - {key}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Your CSV is invalid.</p>
                  )}
                </Modal>
                {/* <label htmlFor="includeCompanyDomain">
                  <i>
                    Upload a CSV with company <b>domains</b> to <b>include</b>{' '}
                    in your query.
                  </i>
                </label> */}
                {/* {this.state.includeCompanyDomain.length === 0 ? (
                  <FilePicker
                    extensions={['csv']}
                    maxSize={10000000}
                    onChange={(FileObject) => {
                      console.log(FileObject, 'FO');
                      // Show headers to pick.

                      var arrayOfData = [];

                      var resultsFromDomainIncludeCSV = this;
                      var showSelectHeaderDialog = this;
                      var headersToSelect = this;

                      const config = {
                        quoteChar: '"',
                        escapeChar: '"',
                        header: true,
                        download: true,
                        step: function (step, parser) {
                          arrayOfData.push(step.data);
                        },
                        complete: function (results, file) {
                          // Show a dialog to select which column you'd like to use.
                          resultsFromDomainIncludeCSV.setState({
                            resultsFromDomainIncludeCSV: arrayOfData,
                          });
                          showSelectHeaderDialog.setState({
                            showSelectHeaderDialog: true,
                          });
                          headersToSelect.setState({
                            headersToSelect: Object.keys(arrayOfData[0]),
                          });
                        },
                        skipEmptyLines: true,
                      };

                      Papa.parse(FileObject, config);
                    }}
                    onError={(errMsg) => {
                      console.log(errMsg, 'Error');
                      //  this.setState({ error: errMsg });
                    }}
                  >
                    <button
                      onClick={() => {
                        //  this.setState({ fileUploaded: '' });
                      }}
                      className="btn green"
                      style={{ marginTop: '15px' }}
                    >
                      Pick CSV
                    </button>
                  </FilePicker>
                ) : (
                  <div>
                    <p style={{ paddingTop: 20, paddingBottom: 20 }}>
                      {this.state.includeCompanyDomain.substring(0, 100)}
                      ...
                    </p>

                    <button
                      onClick={() => {
                        this.setState({ includeCompanyDomain: '' });
                      }}
                      className="btn green"
                      style={{ marginTop: '15px', marginBottom: '15px' }}
                    >
                      Clear CSV
                    </button>
                  </div>
                )} */}

                {/* End of include domains CSV */}

                {/* Start of include domains via STRING */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "50%", margin: 10 }}>
                    <div className="input-field">
                      <label htmlFor="includeCompanyDomainStringArray">
                        <i>
                          Add A Company <b>domain</b> to <b>include</b> in your
                          query.
                        </i>
                      </label>
                      <input
                        type="text"
                        id="includeCompanyDomainStringArray"
                        onChange={
                          this.handleIncludeCompanyDomainStringArrayChange
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            var tempIncludeCompanyDomainStringArray = this.state
                              .includeCompanyDomainStringArray;
                            tempIncludeCompanyDomainStringArray.push({
                              queryField: "Website",
                              queryValue: e.target.value,
                            });
                            e.target.value = "";
                            this.setState({
                              IncludeCompanyDomainStringArray: tempIncludeCompanyDomainStringArray,
                            });
                          }
                        }}
                      />
                      {this.state.includeCompanyDomainStringArray.map(
                        (item) => {
                          return (
                            <div key={Math.random()} className="chip">
                              <Row>
                                <div>
                                  {item.queryValue}
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      borderRadius: 0,
                                      borderWidth: 0,
                                      padding: 10,
                                    }}
                                    onClick={() => {
                                      this.removeIncludeCompanyDomainStringArray(
                                        item
                                      );
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                              </Row>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div style={{ width: "50%", margin: 10 }}>
                    <div className="input-field">
                      <label htmlFor="excludeCompanyDomainStringArray">
                        <i>
                          Add A Company <b>domain</b> to <b>exclude</b> in your
                          query.
                        </i>
                      </label>
                      <input
                        type="text"
                        id="excludeCompanyDomainStringArray"
                        onChange={
                          this.handleExcludeCompanyDomainStringArrayChange
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            var tempExcludeCompanyDomainStringArray = this.state
                              .excludeCompanyDomainStringArray;
                            tempExcludeCompanyDomainStringArray.push({
                              queryField: "Website",
                              queryValue: e.target.value,
                            });
                            e.target.value = "";
                            this.setState({
                              excludeCompanyDomainStringArray: tempExcludeCompanyDomainStringArray,
                            });
                          }
                        }}
                      />
                      {this.state.excludeCompanyDomainStringArray.map(
                        (item) => {
                          return (
                            <div key={Math.random()} className="chip">
                              <Row>
                                <div>
                                  {item.queryValue}
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      borderRadius: 0,
                                      borderWidth: 0,
                                      padding: 10,
                                    }}
                                    onClick={() => {
                                      this.removeExcludedCompanyDomainStringArray(
                                        item
                                      );
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                              </Row>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {/* End of include domains STRING */}

                {/* File input for exclude domains here */}

                <Modal
                  isOpen={this.state.showSelectHeaderDialog_Exclude}
                  style={customStyles}
                  contentLabel="Match Column"
                  ariaHideApp={false}
                  onRequestClose={() => {
                    this.setState({
                      showSelectHeaderDialog_Exclude: false,
                    });
                    this.setState({
                      resultsFromDomain_ExcludeCSV: [],
                    });
                    this.setState({
                      headersToSelect_Exclude: [],
                    });
                  }}
                >
                  <div
                    style={{
                      borderBottom: "solid",
                      borderBottomColor: "#666",
                      borderBottomWidth: 1,
                      cursor: "pointer",
                      color: "#666",
                      marginBottom: 40,
                    }}
                  >
                    Pick a column to use:
                  </div>
                  {this.state.headersToSelect_Exclude.length > 0 ? (
                    <div>
                      {this.state.headersToSelect_Exclude.map((key) => (
                        <div
                          onClick={() => {
                            this.createWebsiteExcludeQuery(key);
                          }}
                          key={Math.random()}
                          style={{
                            borderBottom: "solid",
                            borderBottomColor: "#666",
                            borderBottomWidth: 1,
                            cursor: "pointer",
                          }}
                        >
                          <p>{key}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Your CSV is invalid.</p>
                  )}
                </Modal>
                {/* <label htmlFor="excludedCompanyDomain">
                  <i>
                    Upload a CSV with company <b>domains</b> to <b>exclude</b>{' '}
                    in your query.
                  </i>
                </label>
                {this.state.excludedCompanyDomain.length === 0 ? (
                  <FilePicker
                    extensions={['csv']}
                    maxSize={10000000}
                    onChange={(FileObject) => {
                      // Show headers to pick.

                      var arrayOfData = [];

                      var resultsFromDomain_ExcludeCSV = this;
                      var showSelectHeaderDialog_Exclude = this;
                      var headersToSelect_Exclude = this;

                      const config = {
                        quoteChar: '"',
                        escapeChar: '"',
                        header: true,
                        download: true,
                        step: function (step, parser) {
                          arrayOfData.push(step.data);
                        },
                        complete: function (results, file) {
                          // Show a dialog to select which column you'd like to use.
                          resultsFromDomain_ExcludeCSV.setState({
                            resultsFromDomain_ExcludeCSV: arrayOfData,
                          });
                          showSelectHeaderDialog_Exclude.setState({
                            showSelectHeaderDialog_Exclude: true,
                          });
                          headersToSelect_Exclude.setState({
                            headersToSelect_Exclude: Object.keys(
                              arrayOfData[0]
                            ),
                          });
                        },
                        skipEmptyLines: true,
                      };

                      Papa.parse(FileObject, config);
                    }}
                    onError={(errMsg) => {
                      console.log(errMsg, 'Error');
                      //  this.setState({ error: errMsg });
                    }}
                  >
                    <button
                      onClick={() => {
                        //  this.setState({ fileUploaded: '' });
                      }}
                      className="btn green"
                      style={{ marginTop: '15px' }}
                    >
                      Pick CSV
                    </button>
                  </FilePicker>
                ) : (
                  <div>
                    <p style={{ paddingTop: 20, paddingBottom: 20 }}>
                      {this.state.excludedCompanyDomain.substring(0, 200)}
                      ...
                    </p>

                    <button
                      onClick={() => {
                        this.setState({ excludedCompanyDomain: '' });
                      }}
                      className="btn green"
                      style={{ marginTop: '15px', marginBottom: '15px' }}
                    >
                      Clear CSV
                    </button>
                  </div>
                )} */}

                {/* Start of exclude domains STRING */}
                <br />

                {/* End of exclude domains STRING */}

                <div style={{ margin: 20 }} className="input-field">
                  <label>
                    <input
                      checked={this.state.onlyIncludeLinkedIn}
                      onChange={this.handleOnlyIncludeLinkedIn}
                      type="checkbox"
                    />
                    <span>Only Include Results with a LinkedIn URL</span>
                  </label>
                </div>
                <br />

                <div style={{ margin: 20 }} className="input-field">
                  <label>
                    <input
                      checked={this.state.onlyIncludeUSA}
                      onChange={this.handleOnlyIncludeUSA}
                      type="checkbox"
                    />
                    <span>Only Include Results From The USA</span>
                  </label>
                </div>
                <br />
                <br />
                {this.state.onlyIncludeUSA ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="input-field"
                      style={{ width: "50%", margin: 10 }}
                    >
                      <Select
                        options={usStateList}
                        isMulti
                        name="usStateQueryArray"
                        value={this.state.usStateQueryArray}
                        placeholder="Select US State"
                        onChange={this.handleUSStateChange}
                      />
                    </div>
                    <div
                      className="input-field"
                      style={{ width: "50%", margin: 10 }}
                    >
                      <Select
                        options={arrayOfCitiesList}
                        isMulti
                        name="citiesInUSAQueryArray"
                        value={this.state.citiesInUSAQueryArray}
                        placeholder="Select City"
                        onChange={this.handleCityChange}
                      />
                    </div>
                  </div>
                ) : null}
                <br />
                <br />
                <br />
                {/* End of query fields */}
              </div>
            ) : (
              <label>This query is not editable</label>
            )}
          </DialogContent>
          <DialogActions>
            <div style={{ flex: 1, paddingLeft: 20 }}>
              {this.state.countOnTheFly > 0 &&
              !this.state.countOnTheFlyLoading ? (
                <div>
                  <b>
                    Estimated Results:{" "}
                    {this.state.countOnTheFly.toLocaleString()}
                  </b>
                </div>
              ) : (
                <div>
                  {!this.state.countOnTheFlyLoading && (
                    <b>No results for this query.</b>
                  )}
                </div>
              )}
              {this.state.countOnTheFlyLoading && (
                <div>
                  <b>Getting count...</b>
                </div>
              )}
            </div>
            <Button
              style={customStyles.buttonStyle}
              onClick={() => {
                // Cancel
                this.setState({ showQueryBuilder: false });
              }}
              color="primary"
            >
              Close
            </Button>
            <Button
              style={customStyles.buttonStyle}
              disabled={this.checkSearchStatus()}
              onClick={() => {
                // this.callFilterFunction();
                // this.setState({ showQueryBuilder: false });
                this.buildingNewArray();
              }}
              color="primary"
            >
              Run Query
            </Button>

            <Button
              style={customStyles.buttonStyle}
              // disabled={}
              onClick={() => {
                if (this.state.updatingQuery) {
                  this.updateQuery();
                  this.setState({ showQueryBuilder: false });
                  this.showSnack("Query Updated");
                } else {
                  this.setState({ savingQuery: true });
                }
              }}
              color="primary"
            >
              {this.state.updatingQuery ? "Update Query" : "Save Query"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.savingQuery}
          onClose={() => {
            // Close
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"md"}
        >
          <DialogTitle id="form-dialog-title">
            What would you like to call this query?
          </DialogTitle>
          <DialogContent>
            <input
              type="text"
              id="queryTitle"
              onChange={this.handleQueryTitleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.addingQueryToDB}
              onClick={() => {
                this.setState({ savingQuery: false });
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.addingQueryToDB}
              onClick={() => {
                this.saveQuery();
              }}
            >
              {this.state.addingQueryToDB ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* <div>
          <br />
          <label htmlFor="queryTitle">
            <i>What would you like to call the query?</i>
          </label>
          <input
            type="text"
            id="queryTitle"
            onChange={this.handleQueryTitleChange}
          />
          <br />
          <button
            onClick={() => {
              this.saveQuery();
              this.setState({
                querySavedMessage: 'Query has been saved.',
              });
            }}
            className="btn green"
            disabled={!this.state.queryTitle}
            style={{
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            Confirm & Save
          </button>
          <button
            onClick={() => {
              this.setState({ queryTitle: '' });
              this.setState({ savingQuery: false });
              this.handleSavedQueryChange('Hello');
              setTimeout(() => {
                this.getSavedQueries();
              }, 800);
            }}
            className="btn green"
            disabled={false}
            style={{
              marginTop: '15px',
              marginBottom: '15px',
              marginLeft: '15px',
            }}
          >
            Cancel
          </button>
          <br />
        </div> */}
      </div>
    );
  }
}

export default BQQueries;

// const columns = [
//   {
//     Header: 'Company',
//     accessor: 'Company_Name',
//     className: 'font-bold ml-6',
//   },
//   {
//     Header: 'First Name',
//     accessor: 'First_Name',
//   },
//   {
//     Header: 'Last Name',
//     accessor: 'Last_Name',
//   },
//   {
//     Header: 'Title',
//     accessor: 'Title',
//   },
//   {
//     Header: 'Country',
//     accessor: 'Country',
//   },
//   {
//     Header: 'State',
//     accessor: 'State',
//   },
//   {
//     Header: 'City',
//     accessor: 'City',
//   },
//   {
//     Header: 'Industry',
//     accessor: 'Industry',
//   },
//   {
//     Header: 'Title Level',
//     accessor: 'Job_Level',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
//   {
//     Header: 'Email Validation',
//     accessor: 'ValidationStatus',
//   },
// ];
