import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ExcludeIndustrySelector = (props) => {
  const [ExcludedIndustries, setExcludedIndustries] = useState(
    require("../resources/industries_exclude.json")
  );

  const [count, setCount] = useState(0);

  const handleIncludedIndustryCheckBoxChange = (event, index) => {
    setCount(count + 1);
    const tempExcludedIndustries = ExcludedIndustries;
    tempExcludedIndustries[index].checked = event.target.checked;
    setExcludedIndustries(tempExcludedIndustries);
    const selectedOptions = tempExcludedIndustries.filter(
      (title) => title.checked
    );
    props.onChange(selectedOptions);
  };

  return (
    <div>
      <div>
        <p>(Leave blank to query all industries.)</p>
        <p style={{ color: "#FFFFFF" }}>{count}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {ExcludedIndustries.map((item, index) => (
          <div
            key={String(Math.random())}
            style={{ width: 250, marginRight: 10 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={ExcludedIndustries[index].checked}
                  onChange={(e) =>
                    handleIncludedIndustryCheckBoxChange(e, index)
                  }
                  value={item.label}
                  name="excludedIndustries"
                  color="primary"
                  id="incl"
                />
              }
              label={item.label}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExcludeIndustrySelector;
