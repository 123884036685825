import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Contact from './Contact';
import Select from 'react-select';

class People extends Component {
  state = {
    slaStatus: '',
    amountOfContactsToViewStart: 0,
    amountOfContactsToViewEnd: 25,
    contactCount: 25
  };

  handleFilter = e => {
    this.setState({
      slaStatus: e.value
    });
  };

  render() {
    const { id, contacts, connectmsges, thanksmsg, slaStatus } = this.props;

    //This is for pagination....

    let invitation = '';

    {
      connectmsges &&
        connectmsges.map(msg => {
          invitation = msg.connectmsg;
        });
    }

    let thankyoumsg = '';
    {
      thanksmsg &&
        thanksmsg.map(msg => {
          thankyoumsg = msg.thanksMsg;
        });
    }

    const contactFilters = [
      { value: 'nofilter', label: 'No Filter' },
      { value: 'invited', label: 'Invite Sent' },
      { value: 'didAcceptConnection', label: 'Invite Accepted' },
      { value: 'thankyousent', label: 'Thanks Message Sent' },
      { value: 'replyreceived', label: 'Reply Received' }
    ];

    // const data = contacts;

    // console.log(data, 'Data');

    // const columns = [
    //   {
    //     Header: 'First Name',
    //     accessor: 'firstName'
    //   },
    //   {
    //     Header: 'Last Name',
    //     accessor: 'lastName'
    //   },
    //   {
    //     Header: 'LinkedIn',
    //     Cell: props => (
    //       <div>
    //         <a href={props.original.liProfile} target="_blank">
    //           <img src={linkedinlogo} width="30" />
    //         </a>
    //       </div>
    //     )
    //   },

    //   {
    //     Header: 'LI Reply',
    //     Cell: props => <div>LI Reply</div>
    //   },
    //   {
    //     Header: 'Invite Message',
    //     //This is the invite message:
    //     Cell: props => {

    //         //Insert all that code here.....

    //     }
    //   accessor: 'firstName' //Check this
    //   },
    //   {
    //     Header: 'Invite Accepted'
    //     //   accessor: 'firstName' //Check this
    //   },
    //   {
    //     Header: 'Thanks For Conn.'
    //     //   accessor: 'firstName' //Check this
    //   },
    //   {
    //     Header: 'Current Follow Up'
    //     //   accessor: 'firstName' //Check this
    //   },
    //   {
    //     Header: 'Reply Received?'
    //     //   accessor: 'firstName' // Check this
    //   }
    // ];

    return (
      <div className="card" style={{ marginBottom: '0' }}>
        <div className="card-content">
          <span className="card-title">People for Campaign: </span>
          {/* Insert React Table here */}
          {/* <ReactTable data={data} columns={columns} defaultPageSize={20} /> */}
          <div className="row">
            <div className="col s12 m4 l4">
              Filter By Status:
              <Select
                name="filterstatus"
                options={contactFilters}
                onChange={this.handleFilter}
              />
            </div>
          </div>
          <table className="responsive-table centered highlight">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>LinkedIn</th>
                <th>LI Reply</th>
                <th>Invite Message</th>
                <th>Invite Accepted</th>
                <th>Thanks For Conn.</th>
                <th>Current Follow Up</th>
                <th>Reply Received?</th>
              </tr>
            </thead>
            <tbody>
              {contacts &&
                contacts
                  .slice(
                    this.state.amountOfContactsToViewStart,
                    this.state.amountOfContactsToViewEnd
                  )
                  .map(contact => {
                    return (
                      <Contact
                        contact={contact}
                        key={contact.id}
                        invitation={invitation}
                        props={this.props}
                        thanksmsg={thankyoumsg}
                        user={this.props.user.email}
                      />
                    );
                  })}
            </tbody>
          </table>
          <div style={{ marginTop: 20 }}>
            <button
              style={{ marginRight: 15 }}
              disabled={this.state.amountOfContactsToViewStart < 1}
              className="btn"
              onClick={() => {
                this.setState({
                  amountOfContactsToViewStart:
                    this.state.amountOfContactsToViewStart - 25
                });
                this.setState({
                  amountOfContactsToViewEnd:
                    this.state.amountOfContactsToViewEnd - 25
                });
              }}>
              Previous Page
            </button>
            <button
              disabled={
                this.state.amountOfContactsToViewEnd > this.state.contactCount
              } //Need to get the list length here.
              className="btn"
              onClick={() => {
                if (this.state.amountOfContactsToViewEnd > contacts.length) {
                  //Do nothing
                } else {
                  this.setState({ contactCount: contacts.length });
                  this.setState({
                    amountOfContactsToViewStart:
                      this.state.amountOfContactsToViewStart + 25
                  });
                  this.setState({
                    amountOfContactsToViewEnd:
                      this.state.amountOfContactsToViewEnd + 25
                  });
                }
              }}>
              Next Page
            </button>
          </div>
        </div>
        <div
          style={{
            position: 'sticky',
            height: '75px',
            bottom: '0',
            left: '0',
            right: '0',
            paddingTop: '10px',
            paddingRight: '30px',
            paddingLeft: '30px',
            paddingBottom: '10px',
            zIndex: '999',
            background: '#eee'
          }}>
          <Link
            className="btn-floating btn-large waves-effect waves-light green right"
            to={`/addpeople/${id}`}>
            <i className="material-icons">group_add</i>
          </Link>
          <span
            className="right"
            style={{
              marginTop: '15px',
              marginBottom: '15px',
              marginRight: '10px'
            }}>
            Add People
          </span>{' '}
          <Link
            className="btn red lighten-1"
            to={'/li-campaign'}
            style={{ marginTop: '10px' }}>
            {'< Back'}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const contacts = state.firestore.ordered.contacts;
  const connectmsges = state.firestore.ordered.connectmsges;
  const thanksmsg = state.firestore.ordered.thanksmsg;
  const slaStatus = state.slaStatus;
  const user = state.firebase.profile;
  return {
    id,
    contacts,
    connectmsges,
    thanksmsg,
    slaStatus,
    user
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'contacts',
      where: [
        ['campaign', '==', props.id]
        // ,['slaStatus','==', props.slaStatus]
      ]
    },
    { collection: 'connectmsges', where: ['campaign', '==', props.id] },
    { collection: 'thanksmsg', where: ['campaign', '==', props.id] }
  ])
)(People);
