import React from 'react';
import logo from '../../img/sla-logo.png';
import { Link } from 'react-router-dom';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux';
import AdminLinks from './AdminLinks';
import AgentLinks from './AgentLinks';
import CustomerLinks from './CustomerLinks';
import AgencyLinks from './AgencyLinks';
import DeveloperLinks from './DeveloperLinks';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#005ebc',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Navbar = (props) => {
  const { profile } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [pageName, setPageName] = React.useState('Dashboard');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageName = (page) => {
    setPageName(page);
  };

  let links = '';
  if (profile.role === 'admin') {
    links = (
      <AdminLinks
        profile={profile}
        close={handleDrawerClose}
        changePageName={changePageName}
      />
    );
  } else if (profile.role === 'agent') {
    links = (
      <AgentLinks
        profile={profile}
        close={handleDrawerClose}
        changePageName={changePageName}
      />
    );
  } else if (profile.role === 'customer') {
    links = (
      <CustomerLinks
        profile={profile}
        close={handleDrawerClose}
        changePageName={changePageName}
      />
    );
  } else if (profile.role === 'agency') {
    links = (
      <AgencyLinks
        profile={profile}
        close={handleDrawerClose}
        changePageName={changePageName}
      />
    );
  } else if (profile.role === 'developer') {
    links = (
      <DeveloperLinks
        profile={profile}
        close={handleDrawerClose}
        changePageName={changePageName}
      />
    );
  } else {
    links = (
      <SignedOutLinks
        close={handleDrawerClose}
        changePageName={changePageName}
      />
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon style={{ color: '#005ebc' }} />
          </IconButton>
          <Typography
            style={{ color: '#666666', fontWeight: 'bold', fontSize: 16 }}
          >
            {pageName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Link to="/" className="brand-logo">
            <img
              src={logo}
              alt="Logo"
              width="150"
              style={{ padding: '15px 10px' }}
            />
          </Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon style={{ color: '#ffffff' }} />
            ) : (
              <ChevronRightIcon style={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{links}</List>
        <Divider />
      </Drawer>
    </div>
  );

  // let links = '';
  // if (profile.role === 'admin') {
  //   links = <AdminLinks profile={profile} />;
  // } else if (profile.role === 'agent') {
  //   links = <AgentLinks profile={profile} />;
  // } else if (profile.role === 'customer') {
  //   links = <CustomerLinks profile={profile} />;
  // } else if (profile.role === 'agency') {
  //   links = <AgencyLinks profile={profile} />;
  // } else if (profile.role === 'developer') {
  //   links = <DeveloperLinks profile={profile} />;
  // } else {
  //   links = <SignedOutLinks />;
  // }

  // return (
  //   <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>
  //     <nav className="blue darken-1">
  //       <div className="nav-wrapper">
  //         <Link to="/" className="brand-logo">
  //           <img
  //             src={logo}
  //             alt="Logo"
  //             width="150"
  //             style={{ padding: '15px 10px' }}
  //           />
  //         </Link>
  //         {links}
  //       </div>
  //     </nav>
  //   </div>
  // );
};

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Navbar);
