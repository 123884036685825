import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Account = (props) => {
  const user = props.userProfile;
  if (!props.auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">
            <i
              className="material-icons"
              style={{ fontSize: '30px', padding: '20px' }}
            >
              person
            </i>{' '}
            {user.firstName} {user.lastName}
          </span>
        </div>
        <div className="card-action">
          <h6>Your Plan:</h6>
          <h6>LinkedIn Starter</h6>
          <button className="btn green">Change Plan</button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const userProfile = state.firebase.profile;

  return {
    auth: state.firebase.auth,
    userProfile
  };
};

export default connect(mapStateToProps)(Account);
