import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

class LinkedInCampaign extends Component {
  render() {
    const campaigns = this.props.campaigns;
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">LinkedIn Campaigns</span>
            {campaigns &&
              campaigns.map(campaign => {
                return (
                  <div className="card" key={campaign.id}>
                    <div className="card-content">
                      <span style={{ fontSize: '15px' }}>
                        {campaign.campaignName}
                      </span>
                    </div>
                    <div className="card-action grey lighten-4">
                      <Link
                        to={`/campaign-steps/${campaign.id}`}
                        className="blue-text">
                        Messaging{' '}
                      </Link>
                      {/* <Link to={`/campaign-steps/${campaign.id}`} className="blue-text">Targeting </Link> */}
                      <Link to={`/people/${campaign.id}`} className="blue-text">
                        <i className="material-icons">group</i> People{' '}
                      </Link>
                      <Link
                        to={`/edit-li-campaign/${campaign.id}`}
                        className="blue-text">
                        <i className="material-icons">edit</i> Edit Campaign{' '}
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="card-action sla-action grey lighten-4">
            <span className="grey-text">
              If you need help setting this up, book a call or chat to us.
            </span>
          </div>
          <div className="card-action sla-action grey lighten-4">
            <Link
              className="btn-floating btn-large waves-effect waves-light green right"
              style={{ position: 'fixed', bottom: '20px', right: '30px' }}
              to="new-li-campaign">
              <i className="material-icons">add</i>
            </Link>
            <div style={{ position: 'fixed', bottom: '10px', left: '30px' }}>
              <Link className="btn red lighten-1" to={'/campaigns'}>
                {'< Back'}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    campaigns: state.firestore.ordered.campaigns,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'campaigns', orderBy: ['createdAt', 'desc'] }
  ])
)(LinkedInCampaign);
