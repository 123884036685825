import React, { Component } from 'react';
import firebase from 'firebase/app';
import ReactTable from 'react-table';
import ActivityIndicator from 'react-activity-indicator';
import { Row, Col } from 'react-materialize';
import Select from 'react-select';
import _ from 'lodash';
import 'react-table/react-table.css';
import { FilePicker } from 'react-file-picker';
import Papa from 'papaparse';
import moment from 'moment';
import Modal from 'react-modal';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const customStyles = {
  content: {
    top: '30%',
    bottom: '10%',
    position: 'fixed',
    left: '30%',
    right: 'auto',
    // marginRight: '-50%',
    width: '30%'
    // transform: 'translate(-50%, -50%)'
  }
};

export class UploadContacts extends Component {
  constructor() {
    super();

    this.state = {
      profileUrlHeader: 'Click to match',
      profileUrlData: [],
      companyHeader: 'Click to match',
      companyData: [],
      firstNameHeader: 'Click to match',
      firstNameData: [],
      lastNameHeader: 'Click to match',
      lastNameData: [],
      mailHeader: 'Click to match',
      mailData: [],
      phoneNumberHeader: 'Click to match',
      phoneNumberData: [],
      jobTitleHeader: 'Click to match',
      jobTitleData: [],
      fileName: '',
      fileUploaded: '',
      fileObject: [],
      jsonOfData: [],
      keysToMatch: [],
      matchColumnModalIsOpen: false,
      campaignList: [],
      selectedCampaignId: '',
      selectedCampaignName: '',
      loading: false,
      confirmationMessage: '',
      dataToUpload: []
    };

    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);

    this.setHeaderVariable = this.setHeaderVariable.bind(this);

    this.parseInitialUpload = this.parseInitialUpload.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.getCustomerCampaignIDs();
    }, 1000);
  }

  getCustomerCampaignIDs() {
    var customers = this.props.profile.customers;

    var campaignsArray = [];
    var campaignList = this;

    customers.forEach(customerEmail => {
      firebase
        .firestore()
        .collection('campaigns')
        .where('client', '==', customerEmail)
        .get()
        .then(customerCampaign => {
          if (!customerCampaign.empty) {
            customerCampaign.docs.forEach(doc => {
              campaignsArray.push({
                value: doc.id,
                label: doc.data().campaignName
              });
            });
          }
          campaignList.setState({ campaignList: campaignsArray });
        });
    });
  }

  openModal(header) {
    this.setState({ modalIsOpen: true });
    this.setState({ currentHeader: header });
  }

  // This is the function that matches the data.

  setHeaderVariable(key) {
    // key is the item from their csv.

    this.setState({ [this.state.currentHeader]: key });

    var preview = this.state.previewOfData; // The preview of the data.
    var tempDataToUpload = this.state.jsonOfData;
    console.log(tempDataToUpload, 'JOD');

    var count = 0;

    preview.forEach(item => {
      count = count + 1;
      item[this.state.currentHeader] = item[key];
    }); // Changing the data in the preview.

    console.log(this.state.currentHeader, 'this.state.currentHeader');
    console.log(preview[0].profileUrl, 'profileUrl');
    console.log(preview[0].profileUrlHeader, 'profileUrlHeader');

    console.log(this.state.jsonOfData[0], 'JSON of Data');

    // For example, this is taking 'profileUrl' and making it 'profileUrlHeader'
    // So you can change 'profileUrlHeader' because 'profileUrl' doesn't change.

    tempDataToUpload.forEach(item => {
      console.log(item, 'ITEM HERE');
      item[this.state.currentHeader] = item[key];
      item[this.state.currentHeader + '_Upload'] = item[key];
    });

    // Why does allData influence preview data after it's changed!?

    this.setState({ previewOfData: preview });
    this.setState({ jsonOfData: tempDataToUpload });

    this.setState({ modalIsOpen: false });
  }

  confirmAndUpload() {
    // Changing CSV headers to match.

    var confirmationMessage = this;

    var loading = this;
    loading.setState({ loading: true });

    if (this.state.selectedCampaignId === '') {
      alert('Please select a campaign.');
    } else if (this.state.jsonOfData.length === 0) {
      alert('Please select a CSV');
    } else {
      // Add campaignID and upload
      this.state.jsonOfData.forEach(item => {
        item.campaign = this.state.selectedCampaignId;
      });

      // console.log(this.state.jsonOfData, 'JOD');

      var dataToUpload = this.state.jsonOfData;

      // var cleanedUpDataArray = []

      var objectArray = [];
      dataToUpload.forEach(item => {
        console.log(Object.keys(item), 'Item keys');
        var keys = Object.keys(item);
        var tempObject = {};
        keys.forEach(key => {
          if (key.substring(key.length - 13, key.length) === 'Header_Upload') {
            // Do nothing
            tempObject[key.substring(0, key.length - 13)] = item[key];
            // console.log(item[key.substring(0, key.length - 13)], 'Key');
          }
        });
        objectArray.push(tempObject);
      });
      console.log(objectArray, 'OA'); // This is the object you need.

      var csv = Papa.unparse(objectArray);

      var csvName =
        'New_LinkedIn_Contacts_for_' +
        this.state.selectedCampaignName +
        '_' +
        [...Array(10)].map(i => (~~(Math.random() * 36)).toString(36)).join('');

      var storageRef = firebase.storage().ref();
      var fileRef = storageRef.child(`/linkedInFiles/${csvName}.csv`);
      var uploadTask = fileRef.putString(csv);

      uploadTask.on(
        'state_changed',
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        function(error) {
          // Handle unsuccessful uploads
        },
        function() {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            loading.setState({ loading: false });
            confirmationMessage.setState({
              confirmationMessage: 'Upload Successful'
            });
          });
        }
      );
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  parseInitialUpload(fileObject) {
    console.log('parseInitialUpload');
    var jsonOfData = this;
    var keysToMatch = this;
    var previewOfData = this;

    var tempJsonOfData = [];

    const config = {
      // download: true,
      header: true,
      // preview: 12,
      skipEmptyLines: true,
      error: function(error) {
        console.log(error, 'Error');
      },
      step: function(row) {
        var currentRow = row.data;
        tempJsonOfData.push(currentRow);
      },
      complete: function(results, file) {
        keysToMatch.setState({
          keysToMatch: Object.keys(tempJsonOfData[0])
        });

        // Creating the preview...

        Object.keys(tempJsonOfData[0]).forEach(key => {
          var preview = tempJsonOfData.slice(0, 10);

          preview.forEach(item => {
            // if (
            //   item[key].substring(item[key].length - 6, item[key].length) ===
            //   'Header'
            // ) {
            //   // Do nothing
            // } else {
            item[key + 'Header'] = item[key]; // This is for the display.
            item[key + 'Header_Upload'] = item[key]; // This is for the upload.

            // }
          });

          previewOfData.setState({ previewOfData: preview });
        });

        var allData = tempJsonOfData;
        jsonOfData.setState({ jsonOfData: allData });
      }
    };

    Papa.parse(fileObject, config);
  }

  handleCampaignChange = (option, e) => {
    this.setState({ selectedCampaignId: option.value });
    this.setState({ selectedCampaignName: option.label });
  };

  render() {
    const data = [
      {
        profileUrl: this.state.profileUrlHeader,
        company: this.state.companyHeader,
        firstName: this.state.firstNameHeader,
        lastName: this.state.lastNameHeader,
        mail: this.state.mailHeader,
        phoneNumber: this.state.phoneNumberHeader,
        jobTitle: this.state.jobTitleHeader
      }
    ];

    const columns = [
      {
        Header: 'Linkedin URL',
        accessor: 'profileUrl',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('profileUrlHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.profileUrlHeader}
            </p>
          </div>
        )
      },
      {
        Header: 'Company Name',
        accessor: 'company',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('companyHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.companyHeader}
            </p>
          </div>
        )
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('firstNameHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.firstNameHeader}
            </p>
          </div>
        )
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('lastNameHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.lastNameHeader}
            </p>
          </div>
        )
      },
      {
        Header: 'Email',
        accessor: 'mail',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('mailHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.mailHeader}
            </p>
          </div>
        )
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('phoneNumberHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.phoneNumberHeader}
            </p>
          </div>
        )
      },
      {
        Header: 'Job Title',
        accessor: 'jobTitle',
        Cell: row => (
          <div
            onClick={() => {
              this.openModal('jobTitleHeader');
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: '#666',
                fontSize: 12,
                cursor: 'pointer'
              }}
            >
              {this.state.jobTitleHeader}
            </p>
          </div>
        )
      }
    ];

    /*
    Ok so when someone matches a column,
    you need to take the first 10 results and put them in here
    and display them below.

    Something different will happen when you confirm the upload,
    but let's just do this for now.
    */

    const dataInserted = this.state.previewOfData;

    const columnsInserted = [
      {
        Header: 'Linkedin URL',
        accessor: 'profileUrlHeader',
        style: { textAlign: 'center' }
      },
      {
        Header: 'Company Name',
        accessor: 'companyHeader',
        style: { textAlign: 'center' }
      },
      {
        Header: 'First Name',
        accessor: 'firstNameHeader',
        style: { textAlign: 'center' }
      },
      {
        Header: 'Last Name',
        accessor: 'lastNameHeader',
        style: { textAlign: 'center' }
      },
      {
        Header: 'Email',
        accessor: 'mailHeader',
        style: { textAlign: 'center' }
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumberHeader',
        style: { textAlign: 'center' }
      },
      {
        Header: 'Job Title',
        accessor: 'jobTitleHeader',
        style: { textAlign: 'center' }
      }
    ];

    const TheadComponent = props => null;

    return (
      <div style={{ marginTop: 20 }}>
        {!this.state.fileObject.name ? (
          <div>
            <FilePicker
              extensions={['csv']}
              maxSize={10000000}
              onChange={fileObject => {
                this.parseInitialUpload(fileObject);
                this.setState({ fileObject: fileObject });
              }}
              onError={errMsg => {
                console.log(errMsg, 'Error');
                this.setState({ error: errMsg });
              }}
            >
              <button
                onClick={() => {
                  this.setState({ fileUploaded: '' });
                }}
                className="btn green"
                style={{ marginTop: '15px' }}
              >
                Pick CSV
              </button>
            </FilePicker>
            <p
              style={{
                paddingTop: 20,
                paddingBottom: 20
              }}
            >
              {this.state.error}
            </p>
          </div>
        ) : (
          <div>
            <div className="input-field">
              <p style={{ paddingBottom: 20 }}>
                Select a campaign associated with your upload.
              </p>
              <Select
                options={this.state.campaignList}
                name="campaign"
                placeholder="Campaign"
                onChange={this.handleCampaignChange}
              />
            </div>
            <p style={{ paddingBottom: 20 }}>
              Match your CSV fields with the fields below.
            </p>
            <div style={{ marginBottom: 20 }}>
              <ReactTable
                data={data}
                columns={columns}
                defaultPageSize={1}
                showPagination={false}
              />
            </div>
            <div>
              {/* This table will be populated with the correct data */}
              <ReactTable
                data={dataInserted}
                columns={columnsInserted}
                defaultPageSize={10}
                showPagination={false}
                TheadComponent={TheadComponent}
              />
            </div>
            <button
              onClick={() => {
                this.setState({ fileUploaded: '' });
                this.confirmAndUpload();
              }}
              className="btn green"
              style={{ marginTop: '15px' }}
            >
              {this.state.loading ? 'Loading...' : 'Upload CSV'}
            </button>
            <p style={{ paddingTop: 12 }}>{this.state.confirmationMessage}</p>
          </div>
        )}

        <div>
          <Modal
            ariaHideApp={false}
            id="Modal"
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            contentLabel="Match Column"
            style={customStyles}
          >
            {this.state.keysToMatch.length > 0 ? (
              <div>
                {this.state.keysToMatch.map(key => (
                  <div
                    onClick={() => {
                      this.setHeaderVariable(key);
                    }}
                    key={Math.random()}
                    style={{
                      borderBottom: 'solid',
                      borderBottomColor: '#666',
                      borderBottomWidth: 1,
                      cursor: 'pointer'
                    }}
                  >
                    <p>{key}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p>Please select a CSV first</p>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(UploadContacts);

// profileUrl;<--- Must be dynamic
// campaign; <--- Must be dynamic
// fullName;  <--- Must be dynamic
/*


Ok so you'll have the option there, then you click a modal and you get the options to show.
Then you select one and you set it's state properly.

LinkedIn;
allSkills;
baseUrl;
birthday;
civilityFromDropContact;
company2;
companyUrl;
companyUrl2;
companyWebsite;
connectedOn;
connectionDegree;
connectionsUrl;
description;
error;
facebookUrl;
imgUrl;
jobDateRange;
jobDateRange2;
jobDescription;
jobDescription2;
jobTitle2;
linkedinProfile;
linkedinSalesNavigatorUrl;
location2;
mailFromDropcontact;
mailFromHunter;
mailQualificationFromDropContact;
message;
mutualConnectionsUrl;
naf5CodeFromDropContact;
naf5DesFromDropContact;
nbEmployeesFromDropContact;
phoneNumberFromHunter;
positionFromHunter;
profileId;
school;
school2;
schoolDateRange;
schoolDateRange2;
schoolDegree;
schoolDegree2;
schoolDegreeSpec;
schoolDegreeSpec2;
schoolDescription;
schoolDescription2;
schoolUrl;
schoolUrl2;
scoreFromHunter;
sirenFromDropContact;
siretAddressFromDropContact;
siretFromDropContact;
siretZipFromDropContact;
skill1;
skill2;
skill3;
timestamp;
twitter;
url;
vatFromDropContact;
vmid;
websiteFromDropContact;

*/

// Some things you can link to others, which we'll do.
