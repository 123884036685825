import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createLead } from '../../store/actions/leadActions'
import { Link, Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import { Autocomplete, DatePicker, Dropdown } from 'react-materialize'

const CreateLead = props => {

  const [user, setUser] = useState({
    email: props.location.state.userEmail,
    firstName: props.location.state.userFirstName,
    lastName: props.location.state.userLastName,
  })

  const pathname = props.location.state.cameFrom === '/'
    ? '/' : `${props.location.state.cameFrom}/${props.match.params.id}`

  const [state, setState] = useState({
    jobTitle: '',
    jobTitle2: '',
    content: '',
    firstName: '',
    lastName: '',
    company: '',
    emailAddress: '',
    otherEmail: '',
    phone: '',
    leadStatus: '',
    linkedInProfile: '',
    linkedInReply: '',
    connectionDate: new Date(),
    client: '',
    campaign: ''
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      campaign: props.users && props.users[props.match.params.id].campaignId ? props.users[props.match.params.id].campaignId : ''
    })
    props.createLead(state)
    props.history.push({
      pathname,
      state: {
        userEmail: user.email,
        userFirstName: user.firstName,
        userLastName: user.lastName
      }});
  }

  const updateDate = (e) => {
    setState({
      ...state,
      connectionDate: e
    })
  }

  const updateDropdown = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    })
  }

  if (!props.auth.uid) return <Redirect to='/signin' />

  return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <form className="white" onSubmit={handleSubmit}>
              <h5 className="grey-text text-darken-3">Create Lead</h5>

              <div className="input-field">
                <label htmlFor="firstName">First Name</label>
                <input type="text" id="firstName" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="jobTitle">Title</label>
                <input type="text" id="jobTitle" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="jobTitle2">Second Title</label>
                <input type="text" id="jobTitle2" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="company">Company</label>
                <input type="text" id="company" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="emailAddress">Work Email</label>
                <input type="email" id="emailAddress" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="otherEmail">Other Email</label>
                <input type="email" id="otherEmail" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" id="phone" onChange={e => handleChange(e)}/>
              </div>
              {/* <div className="input-field">
                <label htmlFor="leadStatus">Lead Status</label>

                <Dropdown trigger={<input type="text" id="leadStatus" onChange={e => handleChange(e)}/>} onChange={e => updateDropdown(e)}>
                    <a>Cold</a>
                    <a>Warm</a>
                    <a>Hot</a>
                    <a>Booked</a>
                </Dropdown>

              </div> */}
              <div className="input-field">
                <label htmlFor="linkedInProfile">Linked In Profile URL</label>
                <input type="url" id="linkedInProfile" onChange={e => handleChange(e)}/>
              </div>
              <div className="input-field">
                <label htmlFor="linkedInReply">Linked In Reply Link</label>
                <input type="url" id="linkedInReply" onChange={e => handleChange(e)}/>
              </div>
              {/* <div className="input-field">
                <DatePicker
                  onChange={e => {
                    const target = {
                      value: e,
                      id: 'connectionDate'
                    }
                    e.target = target;
                    handleChange(e)
                  }}
                  id="connectionDate" placeholder="Connection Date"/>
              </div> */}
              <div className="input-field">
                <label className="active" htmlFor="linkedInReply">Linked In Reply Link</label>
                <input type="email" defaultValue={user.email} onChange={e => handleChange(e)} id="client"/>
              </div>
              <div className="input-field">
                  <button className="btn green lighten-1">Create Lead</button>
              </div>
          </form>
          </div>
          <div className="card-action sla-action grey lighten-4">
            <div style={{ position: 'fixed', bottom: '10px', left: '30px' }}>
              <Link className="btn red lighten-1" to={{
                pathname,
                state: {
                  userEmail: user.email,
                  userFirstName: user.firstName,
                  userLastName: user.lastName
                }}}>
                {'< Back'}
              </Link>
            </div>
          </div>
        </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  const users = state.firestore.data.users
  return {
    auth: state.firebase.auth,
    users
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createLead: (lead) => dispatch(createLead(lead))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'users' }
  ])
)(CreateLead)
