import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const TitleLevelSelector = (props) => {
  const [IncludedTitleLevels, setIncludedTitleLevels] = useState(
    require("../resources/title_levels.json")
  );

  const handleTitleLevelCheckBoxChange = (event, index) => {
    const tempIncludedTitleLevels = IncludedTitleLevels;
    tempIncludedTitleLevels[index].checked = event.target.checked;
    setIncludedTitleLevels(tempIncludedTitleLevels);
    const selectedOptions = tempIncludedTitleLevels.filter(
      (title) => title.checked
    );
    props.onChange(selectedOptions);
  };

  return (
    <div>
      <h6 style={{ fontWeight: "bold", marginBottom: 12 }}>
        Select Title Levels:
      </h6>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {IncludedTitleLevels.map((item, index) => (
          <div
            key={String(Math.random())}
            style={{ width: 110, marginRight: 10 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={IncludedTitleLevels[index].checked}
                  onChange={(e) => handleTitleLevelCheckBoxChange(e, index)}
                  value={item.label}
                  name="includedTitleLevel"
                  color="primary"
                  id="inclTitle"
                />
              }
              label={
                <Typography style={{ fontSize: 12 }}>{item.label}</Typography>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TitleLevelSelector;

// import React from "react";

// const TitleLevelSelector = () => {
//   return <div>Hi</div>;
// };

// export default TitleLevelSelector;
