import React, { useEffect, useState } from 'react';

import { CSVLink } from 'react-csv';
import { CircularProgress } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import Grid from '@material-ui/core/Grid';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Paper from '@material-ui/core/Paper';
import ReactTable from 'react-table';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import TwitterIcon from '@material-ui/icons/Twitter';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    width: '90%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [shownContacts, setShownContacts] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState({
    value: 'All Contacts',
    label: 'All Contacts',
  });
  const version = require('../../../package.json');

  const classes = useStyles();
  var columns = [
    {
      Header: 'Title',
      accessor: 'Title',
      className: 'font-bold ml-6',
    },
    { Header: 'First Name', accessor: 'First_Name' },
    { Header: 'Last Name', accessor: 'Last_Name' },
    {
      Header: 'Email',
      accessor: 'Email',
      Cell: (props) => (
        <Tooltip title={props.value}>
          <a href={`mailto:${props.value}`}>
            <EmailIcon
              style={{
                color: '#005ebc',
                fontSize: 16,
              }}
            />
          </a>
        </Tooltip>
      ),
    },
    { Header: 'Industry', accessor: 'Industry' },
    {
      Header: 'Website',
      accessor: 'Website',
      Cell: (props) => (
        <div>
          {props.value !== null && (
            <Tooltip title={props.value}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  props.value.includes('http')
                    ? `${props.value}`
                    : `https://${props.value}`
                }
              >
                <LanguageIcon
                  style={{
                    color: '#005ebc',
                    fontSize: 16,
                  }}
                />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    { Header: 'Company Name', accessor: 'Company_Name' },
    { Header: 'City', accessor: 'City' },
    { Header: 'State', accessor: 'State' },
    { Header: 'Country', accessor: 'Country' },
    { Header: 'Technologies', accessor: 'Technologies' },
    { Header: 'Job Level', accessor: 'Job_Level' },
    { Header: 'Email Status', accessor: 'Email_Status' },
    { Header: 'First Phone', accessor: 'First_Phone' },
    { Header: 'Corporate Phone', accessor: 'Corporate_Phone' },
    { Header: '# Employees', accessor: 'No_Employees' },
    { Header: 'Annual Revenue', accessor: 'Annual_Revenue' },
    {
      Header: 'Personal LinkedIn',
      accessor: 'Person_Linkedin_Url',
      Cell: (props) => (
        <div>
          {props.value !== null && (
            <Tooltip title={props.value}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  props.value.includes('http')
                    ? `${props.value}`
                    : `https://${props.value}`
                }
              >
                <LinkedInIcon
                  style={{
                    color: '#005ebc',
                    fontSize: 16,
                  }}
                />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      Header: 'Company LinkedIn',
      accessor: 'Company_Linkedin_Url',
      Cell: (props) => (
        <div>
          {props.value !== null && (
            <Tooltip title={props.value}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  props.value.includes('http')
                    ? `${props.value}`
                    : `https://${props.value}`
                }
              >
                <LinkedInIcon
                  style={{
                    color: '#005ebc',
                    fontSize: 16,
                  }}
                />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      Header: 'Facebook',
      accessor: 'Facebook_Url',
      Cell: (props) => (
        <div>
          {props.value !== null && (
            <Tooltip title={props.value}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  props.value.includes('http')
                    ? `${props.value}`
                    : `https://${props.value}`
                }
              >
                <FacebookIcon
                  style={{
                    color: '#005ebc',
                    fontSize: 16,
                  }}
                />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      Header: 'Twitter',
      accessor: 'Twitter_Url',
      Cell: (props) => (
        <div>
          {props.value !== null && (
            <Tooltip title={props.value}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  props.value.includes('http')
                    ? `${props.value}`
                    : `https://${props.value}`
                }
              >
                <TwitterIcon
                  style={{
                    color: '#005ebc',
                    fontSize: 16,
                  }}
                />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    { Header: 'Keywords', accessor: 'Keywords' },
    { Header: 'Contact Address 1', accessor: 'Contact_Address_1' },
    { Header: 'Contact Address 2', accessor: 'Contact_Address_2' },
    { Header: 'Contact Address 3', accessor: 'Contact_Address_3' },
    { Header: 'Zip Code', accessor: 'Zip_Code' },
    { Header: 'Company Address', accessor: 'Company_Address' },
    { Header: 'Company Address 2', accessor: 'Company_Address_2' },
    { Header: 'Company City', accessor: 'Company_City' },
    { Header: 'Company State', accessor: 'Company_State' },
    { Header: 'Company Zip', accessor: 'Company_Zip_Code' },
    { Header: 'Company Country', accessor: 'Company_Country' },
    { Header: 'Company Phone', accessor: 'Company_Phone' },
    { Header: 'SIC Code', accessor: 'SIC_Code' },
    { Header: 'Naics Code', accessor: 'Naics_Code' },
    { Header: 'Validation Status ID', accessor: 'ValidationStatusId' },
    { Header: 'Validation Status', accessor: 'ValidationStatus' },
    { Header: 'EmailDomain Group IS', accessor: 'EmailDomainGroupId' },
    { Header: 'Email Domain Group', accessor: 'EmailDomainGroup' },
    { Header: 'SEO Description', accessor: 'SEO_Description' },
    { Header: 'EOF', accessor: 'EOF' },
    {
      Header: 'Number_of_Retail_Locations',
      accessor: 'Number_of_Retail_Locations',
    },
  ];

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = () => {
    var contactArray = [];
    firebase
      .firestore()
      .collection('validatedContacts')
      .where('ownerUID', '==', firebase.auth().currentUser.uid)
      .get()
      .then((docs) => {
        if (docs.empty) {
          setContacts([]);
          setLoading(false);
        } else {
          docs.docs.forEach((item) => {
            contactArray.push(item.data());
          });
          setLoading(false);
          // Filter the array here.
          const uniqueListNames = _.uniqBy(contactArray, function (e) {
            return e.listName;
          });

          var listsArray = [];

          listsArray.push({ value: 'All Contacts', label: 'All Contacts' });

          console.log('ULN', uniqueListNames);

          uniqueListNames.forEach((name) => {
            listsArray.push({ value: name.listName, label: name.listName });
          });

          setLists(listsArray);
          setContacts(contactArray);
          setShownContacts(contactArray);
        }
      });
  };

  const handleListChange = (e) => {
    setLoading(true);
    setSelectedList(e);
    if (e.value === 'All Contacts') {
      setShownContacts(contacts);
      setLoading(false);
    } else {
      var filteredContacts = contacts.filter(function (contact) {
        return contact.listName === e.value;
      });

      setShownContacts(filteredContacts);
      setLoading(false);
    }
  };

  const { auth, userProfile } = props;

  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div>
      {loading ? (
        <div class="loading-controller">
          <CircularProgress style={{ color: '#2196F3' }} />
        </div>
      ) : (
        <div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Top Card */}
            <div className={classes.root}>
              <Grid container spacing={3}>
                {/*    <Grid item md={6} xs={12}>
                  <Paper className={classes.paper}>
                    <div className="h2">LinkedIn Metrics</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <PersonAddIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Connections</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <DraftsIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Sent Invites</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <CheckCircleOutlineIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Accepted Invites</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <ReplyIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Replies</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <ReplyIcon style={{ color: '#ffffff' }} />
                      <div
                        style={{ textAlign: 'right' }}
                        className="sub-header"
                      >
                        <a
                          target="_blank"
                          href="https://app.conversify.ai/login"
                          style={{ hover: 'pointer' }}
                        >
                          <Button>See More</Button>
                        </a>
                      </div>
                    </div>
                  </Paper>
                </Grid> */}
                {/* <Grid item md={6} xs={12}>
                  <Paper className={classes.paper}>
                    <div className="h2">Email Metrics</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <MailOutlineIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Sent</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <BlockIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Bounced</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <ImportContactsIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Opened</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <MoveToInboxIcon
                        style={{
                          color: '#d2d2d2',
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="sub-header">Replies</div>
                      <div className="metric-number">12</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <div
                        style={{ textAlign: 'right' }}
                        className="sub-header"
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://emailogin.salesleadautomation.com/login"
                          style={{ hover: 'pointer' }}
                        >
                          <Button>See More</Button>
                        </a>
                      </div>
                    </div>
                  </Paper>
                </Grid> */}
                <Grid item xs={6}>
                  <div className="h2">Select A List</div>
                  <div style={{ marginTop: 10 }}>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={lists}
                      value={selectedList}
                      name="listSelector"
                      placeholder="Select List"
                      onChange={handleListChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div className="h2">Your Contacts</div>
                    <ReactTable
                      className="-striped -highlight border-0"
                      defaultPageSize={10}
                      showPagination={true}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      noDataText="No contacts to display"
                      data={shownContacts}
                      columns={columns}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        alignItems: 'center',
                        borderBottomColor: '#fafafa',
                      }}
                    >
                      <div
                        style={{ textAlign: 'right', color: '#039be5' }}
                        className="sub-header"
                      >
                        <div style={{ marginBottom: 30 }}>
                          <CSVLink
                            filename={`${selectedList.value}-${moment().format(
                              'X'
                            )}.csv`}
                            data={shownContacts}
                          >
                            Download Current List
                          </CSVLink>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* Change Version Number */}
          <p style={{ paddingLeft: 60, color: '#666' }}>V {version.version}</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    userProfile: state.firebase.profile,
  };
};

export default compose(connect(mapStateToProps))(Dashboard);
