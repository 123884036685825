import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const Helpers = (props) => {
  const classes = useStyles();
  const [loadingModal, setLoadingModal] = useState(false);

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  useEffect(() => {
    // Hi
  }, []);

  return (
    <div>
      <p style={{ paddingLeft: 60, color: '#666' }}>Helper Page</p>
      <Chip label="Deletable" onDelete={handleDelete} />
    </div>
  );
};

export default Helpers;
