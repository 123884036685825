import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import ActivityIndicator from 'react-activity-indicator';
import NewLeadsTable from '../leads/NewLeadsTable';
import { updateLeadStatus } from '../../store/actions/leadActions';
// import {
//   useAllUserContacts,
//   useLeadUpdate,
// } from '../customHooks/dashboardHooks';
import Button from 'react-materialize/lib/Button';
import Papa from 'papaparse';
import firebase from 'firebase/app';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const UserDashboard = (props) => {
  const [state, setState] = useState({
    userUID: '',
    userEmail: '',
    firstName: '',
    lastName: '',
  });

  const [CRM, setCRM] = useState({
    hubspot: false,
  });

  useEffect(() => {
    setCRM({
      hubspot: props.userProfile.hubspotRefreshToken ? true : false,
    });
  }, [props.userProfile]);

  useEffect(() => {
    setState({
      userUID: props.match.params.id,
      userEmail: props.location.state.userEmail,
      firstName: props.location.state.userFirstname,
      lastName: props.location.state.userLastname,
    });
  }, []);

  const [loadingModal, setLoadingModal] = useState(false);
  const [updated, setUpdated] = useState(1);
  // const contacts = useAllUserContacts(
  //   state.userEmail,
  //   updated,
  //   setLoadingModal
  // );
  // const [leadUpdateEvent, setLeadUpdateEvent] = useState({
  //   target: undefined,
  //   contact: '',
  // });

  // useLeadUpdate(leadUpdateEvent, setLoadingModal, updated, setUpdated);

  // const updateLead = (target, contact) => {
  //   setLoadingModal(true);
  //   setLeadUpdateEvent({ target, contact });
  // };

  const updateState = () => {
    // setLoadingModal(!loadingModal);
  };

  const downloadCsv = (contacts) => {
    const campaignId = contacts[0].campaign;

    firebase
      .firestore()
      .collection('campaigns')
      .doc(campaignId)
      .get()
      .then((doc) => {
        const campaignName = doc.data().campaignName || '';
        console.log(campaignName, 'CN');

        var csvName =
          'Contacts_for_' +
          campaignName +
          '_' +
          [...Array(10)]
            .map((i) => (~~(Math.random() * 36)).toString(36))
            .join('');

        var modifiedContactsArray = [];

        contacts.forEach((contact) => {
          if (contact.didAcceptConnection === 'Yes') {
            if (contact.replyReceived === 'Yes') {
              console.log('Received Reply');
            } else {
              delete contact.campaign;
              delete contact.id;
              contact.campaignName = campaignName;

              if (!contact.leadStatus) {
                contact.leadStatus = 'No status';
              }

              if (!contact.contactType) {
                contact.contactType = 'Prospect';
              }

              if (!contact.replyReceived) {
                contact.replyReceived = 'No';
              }

              modifiedContactsArray.push(contact);
            }
          } else {
            // Nothing
          }
        });

        var csv = Papa.unparse(modifiedContactsArray);

        var storageRef = firebase.storage().ref();
        var fileRef = storageRef.child(`/tempFiles/${csvName}.csv`);
        var uploadTask = fileRef.putString(csv);
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          function (error) {
            // Handle unsuccessful uploads
          },
          function () {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log('File available at', downloadURL);
                window.open(downloadURL);
                setLoadingModal(false);
              });
          }
        );
      });
  };

  if (!props.auth.uid) return <Redirect to="/signin" />;

  return (
    <div>
      <p
        style={{
          paddingLeft: 20,
          color: '#ff0000',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Currently Viewing {state.firstName} {state.lastName}'s dashboard.
      </p>
      <Modal
        isOpen={loadingModal}
        style={customStyles}
        contentLabel="Loading..."
        ariaHideApp={false}
      >
        <ActivityIndicator
          number={3}
          diameter={20}
          borderWidth={1}
          duration={200}
          activeColor="#66D9EF"
          borderColor="white"
          borderRadius="50%"
        />
      </Modal>
      <NewLeadsTable
        // contacts={contacts}
        userProfile={props.userProfile}
        updateState={updateState}
        // updateLead={updateLead}
        CRM={CRM}
      />
      <Link
        className="btn-floating btn-large waves-effect waves-light green right"
        style={{ position: 'fixed', bottom: '20px', right: '30px' }}
        to={{
          pathname: `/create-lead/${props.match.params.id}`,
          state: {
            userEmail: state.userEmail,
            userFirstName: state.firstName,
            userLastName: state.lastName,
            cameFrom: '/user-dashboard',
          },
        }}
      >
        <i className="material-icons">add</i>
      </Link>
      {/* {contacts && (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Button
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
            onClick={() => {
              setLoadingModal(true);
              downloadCsv(contacts);
              // Filter the contacts here.
            }}
          >
            Download Contacts Without Replies
          </Button>
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    userProfile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLeadStatus: (state, contact) =>
      dispatch(updateLeadStatus(state, contact)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
