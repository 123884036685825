import React from 'react';
import logo from '../../img/sla-logo.png';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const LinkItem = (props) => {
  const link = props.link;
  const name = props.name;

  return (
    <NavLink
      onClick={() => {
        if (name === 'Sign Out') {
          props.signOut();
          props.close();
          props.changePageName('');
        } else {
          props.close();
          props.changePageName(name);
        }
      }}
      style={{ width: '100%' }}
      to={link}
    >
      <ListItem button key={name}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText style={{ color: '#ffffff' }} primary={name} />
      </ListItem>
    </NavLink>
  );
};

const AgentLinks = (props) => {
  return (
    <ul>
      <li>
        <LinkItem
          name={'Campaigns'}
          link={'/campaigns'}
          close={props.close}
          changePageName={props.changePageName}
          icon={<DescriptionIcon style={{ color: '#ffffff' }} />}
        />
      </li>
      <li>
        <LinkItem
          name={'Profile'}
          link={'/account'}
          close={props.close}
          changePageName={props.changePageName}
          icon={<AccountCircleIcon style={{ color: '#ffffff' }} />}
        />
      </li>
      <li>
        <LinkItem
          name={'Sign Out'}
          link={'/'}
          close={props.close}
          signOut={props.signOut}
          changePageName={props.changePageName}
          icon={<ExitToAppIcon style={{ color: '#ffffff' }} />}
        />
      </li>
    </ul>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(AgentLinks);
