import React from "react";
import logo from "../../img/sla-logo.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import GroupIcon from "@material-ui/icons/Group";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PublishIcon from "@material-ui/icons/Publish";
import RestoreIcon from "@material-ui/icons/Restore";
import DescriptionIcon from "@material-ui/icons/Description";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const LinkItem = (props) => {
  const link = props.link;
  const name = props.name;

  return (
    <NavLink
      onClick={() => {
        if (name === "Sign Out") {
          props.signOut();
          props.close();
          props.changePageName("");
        } else {
          props.close();
          props.changePageName(name);
        }
      }}
      style={{ width: "100%" }}
      to={link}
    >
      <ListItem button key={name}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText style={{ color: "#ffffff" }} primary={name} />
      </ListItem>
    </NavLink>
  );
};

const AdminLinks = (props) => {
  return (
    <ul>
      <li>
        <LinkItem
          name={"Dashboard"}
          link={"/"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<InboxIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Users"}
          link={"/users"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<GroupIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Queries"}
          link={"/query-builder"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<FindInPageIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Uploads"}
          link={"/uploads"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<PublishIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Query History"}
          link={"/query-history"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<RestoreIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Campaigns"}
          link={"/campaigns"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<DescriptionIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Reports"}
          link={"/reports"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<AssessmentIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Settings"}
          link={"/settings"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<SettingsIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Profile"}
          link={"/account"}
          close={props.close}
          changePageName={props.changePageName}
          icon={<AccountCircleIcon style={{ color: "#ffffff" }} />}
        />
      </li>
      <li>
        <LinkItem
          name={"Sign Out"}
          link={"/"}
          close={props.close}
          signOut={props.signOut}
          changePageName={props.changePageName}
          icon={<ExitToAppIcon style={{ color: "#ffffff" }} />}
        />
      </li>
    </ul>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(AdminLinks);
