import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

const CommaSeparatedPaste = (props) => {
  const [value, setValue] = useState("");

  return (
    <div>
      <TextField
        style={{
          width: props.width,
          fontSize: 12,
          marginRight: 12,
          marginLeft: 12,
          marginBottom: 12,
        }}
        inputProps={{ style: { height: 60, fontSize: 12 } }}
        InputLabelProps={{
          style: { fontSize: 12 },
        }}
        id="outlined-multiline-static"
        variant="outlined"
        label={props.label}
        multiline
        rowsMax={6}
        placeholder={props.placeholder}
        onBlur={(e) => {
          if (e.target.value.length > 1) {
            setValue(e.target.value);
            props.onChange(e.target.value);
          } else {
            setValue("");
            props.onChange("");
          }
        }}
      />
    </div>
  );
};

export default CommaSeparatedPaste;
