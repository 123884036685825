import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { editFollowUp } from '../../store/actions/campaignActions';
import { Link } from 'react-router-dom';

class EditFollowUp extends Component {
  state = {
    msg: ''
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log('Form Submitted');
    this.props.editFollowUp(this.state.msg, this.props.id);
    this.props.history.push('/campaign-steps/' + this.props.campaign);
  };
  render() {
    const { stepMsg } = this.props;
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Edit Follow Up Message</span>
            <form onSubmit={this.handleSubmit}>
              {stepMsg &&
                stepMsg.map(msg => {
                  return (
                    <p
                      style={{
                        whiteSpace: 'pre-wrap'
                      }}>
                      {msg.msg}
                    </p>
                  );
                })}
              <div className="form-group">
                <label htmlFor="msg">New Message</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="msg"
                  onChange={this.handleChange}
                />
                {/* <input
                      type="text"
                      id="msg"
                      onChange={this.handleChange}
                    /> */}
              </div>
              <span style={{ fontSize: '12px' }}>
                Merge tags:
                <br />
                #firstName# - First Name
                <br />
                #firstName# - Last Name
                <br />
                #company# - Company Name
                <br />
              </span>
              <br />
              <button className="btn right">Save</button>
              <Link
                to={`/campaign-steps/${this.props.match.params.campaign}`}
                className="btn red lighten-1">
                Cancel
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const stepMsg = state.firestore.ordered.steps;
  const campaign = ownProps.match.params.campaign;
  return {
    id,
    stepMsg,
    campaign
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editFollowUp: (msg, id) => dispatch(editFollowUp(msg, id))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
    { collection: 'steps', where: ['campaign', '==', props.campaign] }
  ])
)(EditFollowUp);
