import firebase from '../../config/fbConfig';
export const createLead = lead => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to db
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    firestore
      .collection('contacts')
      .add({
        ...lead,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId,
        createdAt: new Date(),
        step: 4,
        connectSent: 'Yes',
        didAcceptConnection: 'Yes',
        replyReceived: 'Yes'
      })
      .then(() => {
        dispatch({ type: 'CREATE_LEAD', lead });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_LEAD_ERROR', err });
      });
  };
};

export const updateLeadStatus = (leadUpdate, dispatch) => {
  return new Promise((resolve, reject) => {
    const updates = {}

    if (leadUpdate.leadStatus) updates.leadStatus = leadUpdate.leadStatus
    if (leadUpdate.contactType) updates.contactType = leadUpdate.contactType
    if (leadUpdate.addedToHubspot) updates.addedToHubspot = leadUpdate.addedToHubspot

    firebase
      .firestore()
      .collection('contacts')
      .doc(leadUpdate.contact)
      .update(updates)
      .then(() => {
        console.log('Lead updated');
        resolve();
      })
      .catch((err) => {
        console.log(`An error occured while updating the lead: ${err}`)
        reject(`An error occured while updating the lead: ${err}`)
      });
    //   dispatch({ type: 'UPDATE_LEAD_STATUS', contact });
    // })
    // .catch(err => {
    //   dispatch({ type: 'UPDATE_LEAD_STATUS_ERROR', err });
    // });
    //   return (dispatch, getState, { getFirebase, getFirestore }) => {
    //     const firestore = getFirestore();
    //     console.log('We here 2?');
    //     firestore
    //       .collection('contacts')
    //       .doc(contact)
    //       .update({
    //         leadStatus: status
    //       })
    //       .then(() => {
    //         dispatch({ type: 'UPDATE_LEAD_STATUS', contact });
    //       })
    //       .catch(err => {
    //         dispatch({ type: 'UPDATE_LEAD_STATUS_ERROR', err });
    //       });
    //   };
  })
};
