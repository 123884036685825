import React from 'react';
import firebase from 'firebase';
import { Link } from 'react-router-dom';

const ThanksMsges = ({ thanksMsg, campaign }) => {
  function handleDelete(id) {
    // TODO: MAKE THIS A REDU ACTION
    firebase
      .firestore()
      .collection('thanksmsg')
      .doc(id)
      .delete();
    console.log('Data Deleted');
  }

  return (
    <div>
      {thanksMsg &&
        thanksMsg.map(msg => {
          return (
            <div className="card" key={msg.id}>
              <div className="card-content">
                <b>Message:</b>
                <p
                  style={{
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {msg.thanksMsg}
                </p>
              </div>
              <div className="card-action">
                <Link
                  to={`/edit-thanks-msg/${msg.id}/${campaign}`}
                  className="blue-text"
                >
                  Edit
                </Link>
                <a
                  className="blue-text"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(msg.id)}
                >
                  Delete
                </a>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ThanksMsges;
