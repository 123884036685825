import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { resetPassword } from '../../store/actions/authActions';

const PasswordReset = (props) => {
  const [state, setState] = useState({
    email: '',
  })

  const handleChange = e => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.resetPassword(state.email)
    props.history.push('/signin/')
  };

  const { auth } = props;
  if (auth.uid) return <Redirect to="/" />;

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <form className="white" onSubmit={handleSubmit}>
            <h5 className="grey-text text-darken-3">Reset Password</h5>
            <div className="input-field">
              <p className="grey-text"> Enter your email address below and click submit, a password reset link will be emailed to you. </p>
            </div>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" onChange={handleChange} />
            </div>
            <div style={{display: 'flex'}} className="input-field">
              <button style={{ marginRight: '10px'}} className="btn green lighten-1">Submit</button>
              <Link to="/signin" className="btn red lighten-1">Cancel</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: email => dispatch(resetPassword(email))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
