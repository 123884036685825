import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

class LiSLRCampaigns extends Component {
  render() {
    const { campaigns } = this.props;

    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">LinkedIn SLR Campaigns</span>
            {campaigns &&
              campaigns.map(item => {
                return (
                  <div className="card" key={item.id}>
                    <div className="card-content">
                      <span className="card-title">{item.campaignName}</span>
                      <p>Status: Active</p>
                    </div>
                    <div className="card-action">
                      <Link to={`/slr-messaging/${item.id}`}>Messaging</Link>
                      <a>People</a>
                      <a>Edit</a>
                      <a>Delete</a>
                      <a>Pause</a>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="card-action sla-action">
            <Link
              className="btn-floating btn-large waves-effect waves-light green right"
              to="/new-li-slr-campaign"
            >
              <i className="material-icons">add</i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    campaigns: state.firestore.ordered.slrcampaigns
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    { collection: 'slrcampaigns', where: ['authorId', '==', props.userId] }
  ])
)(LiSLRCampaigns);
