import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import firebase from "firebase";
// Table Imports
import ReactTable from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
// Component Imports
import TitleLevelSelector from "./components/TitleLevelSelector";
import CompanySizeSelector from "./components/CompanySizeSelector";
import IndustrySelector from "./components/IndustrySelector";
import ExcludeIndustrySelector from "./components/ExcludeIndustrySelector";
import CommaSeparatedPaste from "./components/CommaSeparatedPaste";
import ValidationTypeSelector from "./components/ValidationTypeSelector";
import StateSelector from "./components/StateSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import RecordsPerCompany from "./components/RecordsPerCompany";

const QueryBuilder = () => {
  // UI States
  const [showIndustrySelection, setShowIndustrySelection] = useState(false);
  const [
    showExcludedIndustrySelection,
    setShowExcludedIndustrySelection,
  ] = useState(false);
  const [showStateSelection, setShowStateSelection] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  // Form States
  const [IncludedTitles, setIncludedTitles] = useState("");
  const [ExcludedTitles, setExcludedTitles] = useState("");
  const [IncludedCompanies, setIncludedCompanies] = useState("");
  const [ExcludedCompanies, setExcludedCompanies] = useState("");
  const [IncludedCompanyDomain, setIncludedCompanyDomain] = useState("");
  const [ExcludedCompanyDomain, setExcludedCompanyDomain] = useState("");
  const [countOnTheFly, setCountOnTheFly] = useState(0);
  const [countOnTheFlyLoading, setCountOnTheFlyLoading] = useState(false);
  const [IncludedIndustries, setIncludedIndustries] = useState([]);
  const [ExcludedIndustries, setExcludedIndustries] = useState([]);
  const [IncludedTitleLevels, setIncludedTitleLevels] = useState([]);
  const [CompanySize, setCompanySize] = useState([]);
  const [validationTypes, setValidationTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [USOnly, setUSOnly] = useState(false);
  const [LinkedInOnly, setLinkedInOnly] = useState(false);
  const [recordsPerCompany, setRecordsPerCompany] = useState(0);
  const [SICCode, setSICCode] = useState("");
  const [NAICSCode, setNAICSCode] = useState("");

  // Response States
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [userObject, setUserObject] = useState([]);
  const [apiKey, setApiKey] = useState("");

  if (userObject.role === "admin") {
    var columns = [
      {
        Header: "Title",
        accessor: "Title",
        className: "font-bold ml-6",
      },
      { Header: "First Name", accessor: "First_Name" },
      { Header: "Last Name", accessor: "Last_Name" },
      {
        Header: "Email",
        accessor: "Email",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            <Tooltip title={props.value}>
              <a href={`mailto:${props.value}`}>
                <EmailIcon
                  style={{
                    color: "#005ebc",
                    fontSize: 16,
                  }}
                />
              </a>
            </Tooltip>
          </div>
        ),
      },
      { Header: "Industry", accessor: "Industry" },
      {
        Header: "Website",
        accessor: "Website",
        Cell: (props) => (
          <div>
            {props.value !== null && (
              <div style={{ textAlign: "center" }}>
                <Tooltip title={props.value}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      props.value.includes("http")
                        ? `${props.value}`
                        : `https://${props.value}`
                    }
                  >
                    <LanguageIcon
                      style={{
                        color: "#005ebc",
                        fontSize: 16,
                      }}
                    />
                  </a>
                </Tooltip>
              </div>
            )}
          </div>
        ),
      },
      { Header: "Company Name", accessor: "Company_Name" },
      { Header: "City", accessor: "City" },
      { Header: "State", accessor: "State" },
      { Header: "Country", accessor: "Country" },
      { Header: "Technologies", accessor: "Technologies" },
      { Header: "Job Level", accessor: "Job_Level" },
      { Header: "Email Status", accessor: "Email_Status" },
      { Header: "First Phone", accessor: "First_Phone" },
      { Header: "Corporate Phone", accessor: "Corporate_Phone" },
      { Header: "# Employees", accessor: "No_Employees" },
      { Header: "Annual Revenue", accessor: "Annual_Revenue" },
      {
        Header: "Personal LinkedIn",
        accessor: "Person_Linkedin_Url",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value !== null && (
              <Tooltip title={props.value}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    props.value.includes("http")
                      ? `${props.value}`
                      : `https://${props.value}`
                  }
                >
                  <LinkedInIcon
                    style={{
                      color: "#005ebc",
                      fontSize: 16,
                    }}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Company LinkedIn",
        accessor: "Company_Linkedin_Url",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value !== null && (
              <Tooltip title={props.value}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    props.value.includes("http")
                      ? `${props.value}`
                      : `https://${props.value}`
                  }
                >
                  <LinkedInIcon
                    style={{
                      color: "#005ebc",
                      fontSize: 16,
                    }}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Facebook",
        accessor: "Facebook_Url",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value !== null && (
              <Tooltip title={props.value}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    props.value.includes("http")
                      ? `${props.value}`
                      : `https://${props.value}`
                  }
                >
                  <FacebookIcon
                    style={{
                      color: "#005ebc",
                      fontSize: 16,
                    }}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: "Twitter",
        accessor: "Twitter_Url",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value !== null && (
              <Tooltip title={props.value}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    props.value.includes("http")
                      ? `${props.value}`
                      : `https://${props.value}`
                  }
                >
                  <TwitterIcon
                    style={{
                      color: "#005ebc",
                      fontSize: 16,
                    }}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
      { Header: "Keywords", accessor: "Keywords" },
      { Header: "Contact Address 1", accessor: "Contact_Address_1" },
      { Header: "Contact Address 2", accessor: "Contact_Address_2" },
      { Header: "Contact Address 3", accessor: "Contact_Address_3" },
      { Header: "Zip Code", accessor: "Zip_Code" },
      { Header: "Company Address", accessor: "Company_Address" },
      { Header: "Company Address 2", accessor: "Company_Address_2" },
      { Header: "Company City", accessor: "Company_City" },
      { Header: "Company State", accessor: "Company_State" },
      { Header: "Company Zip", accessor: "Company_Zip_Code" },
      { Header: "Company Country", accessor: "Company_Country" },
      { Header: "Company Phone", accessor: "Company_Phone" },
      { Header: "SIC Code", accessor: "SIC_Code" },
      { Header: "Naics Code", accessor: "Naics_Code" },
      { Header: "Validation Status ID", accessor: "ValidationStatusId" },
      { Header: "Validation Status", accessor: "ValidationStatus" },
      { Header: "EmailDomain Group IS", accessor: "EmailDomainGroupId" },
      { Header: "Email Domain Group", accessor: "EmailDomainGroup" },
      { Header: "SEO Description", accessor: "SEO_Description" },
      { Header: "EOF", accessor: "EOF" },
      {
        Header: "Number_of_Retail_Locations",
        accessor: "Number_of_Retail_Locations",
      },
    ];
  } else {
    columns = [
      {
        Header: "Title",
        accessor: "Title",
        className: "font-bold ml-6",
      },
      { Header: "First Name", accessor: "First_Name" },
      { Header: "Industry", accessor: "Industry" },
      {
        Header: "Website",
        accessor: "Website",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value !== null && (
              <Tooltip title={props.value}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    props.value.includes("http")
                      ? `${props.value}`
                      : `https://${props.value}`
                  }
                >
                  <LanguageIcon
                    style={{
                      color: "#d2d2d2",
                      fontSize: 16,
                    }}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        ),
      },
      { Header: "Company Name", accessor: "Company_Name" },
      { Header: "City", accessor: "City" },
      { Header: "State", accessor: "State" },
      { Header: "Country", accessor: "Country" },
    ];
  }

  const customStyles = {
    buttonStyle: {
      height: 50,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: "#005ebc",
      color: "#ffffff",
      fontWeight: "bold",
      margin: 10,
      fontSize: 10,
    },
    dialogButtonStyle: {
      height: 60,
      width: 200,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: "#005ebc",
      color: "#ffffff",
      fontWeight: "bold",
      margin: 12,
      fontSize: 10,
    },
    sidebarButtonStyle: {
      height: 50,
      paddingLeft: 30,
      paddingRight: 30,
      fontSize: 10,
      backgroundColor: "#005ebc",
      color: "#ffffff",
      fontWeight: "bold",
      width: 250,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 10,
      marginTop: 10,
    },
    sideBar: {
      width: 300,
      height: "100vh",
      backgroundColor: "#ffffff",
      overflow: "scroll",
      padding: 20,
      borderRight: "solid 1px #d2d2d2",
    },
    sideBarInput: {
      border: "solid 1px #ffffff",
      fontSize: 12,
      color: "#666",
      padding: 5,
      marginTop: 5,
      width: "90%",
    },
  };

  const downloadCSVFunction = () => {
    setDownloadLoading(true);

    const url = generateURL("download-csv");

    axios
      .get(url + `&userID=${firebase.auth().currentUser.uid}`)
      .then(function (response) {
        setDownloadLoading(false);
        console.log("Response", response.data);
        alert(
          "Success. Your download link will be sent to your inbox shortly."
        );
      })
      .catch(function (error) {
        setDownloadLoading(false);
        alert("There has been an error. Please try again.");
      });
  };

  const generateURL = (type) => {
    // INCL INDUSTRY
    var includedIndustryString = "";

    IncludedIndustries.forEach((element) => {
      if (element.checked) {
        includedIndustryString = includedIndustryString + `${element.value}, `;
      }
    });

    includedIndustryString = includedIndustryString.slice(0, -2);

    // EXCL INDUSTRY

    var excludedIndustryString = "";

    ExcludedIndustries.forEach((element) => {
      if (element.checked) {
        excludedIndustryString = excludedIndustryString + `${element.value}, `;
      }
    });

    excludedIndustryString = excludedIndustryString.slice(0, -2);

    //  INCL Title Level

    var includedTitleLevelString = "";

    IncludedTitleLevels.forEach((element) => {
      console.log("Element", element);
      if (element.checked) {
        includedTitleLevelString =
          includedTitleLevelString + `${element.value}, `;
      }
    });

    includedTitleLevelString = includedTitleLevelString.slice(0, -2);

    //  INCL Company Size

    var companySizeString = "";

    CompanySize.forEach((element) => {
      if (element.checked) {
        companySizeString = companySizeString + `${element.value}, `;
      }
    });

    companySizeString = companySizeString.slice(0, -2);

    //  Validation Types

    var validationTypesString = "";

    validationTypes.forEach((element) => {
      if (element.checked) {
        validationTypesString = validationTypesString + `${element.value}, `;
      }
    });

    validationTypesString = validationTypesString.slice(0, -2);

    //  States

    var statesString = "";

    states.forEach((element) => {
      if (element.checked) {
        statesString = statesString + `${element.value}, `;
      }
    });

    statesString = statesString.slice(0, -2);

    // const basePath = "http://localhost:4000/";
    const basePath = "https://sla-server.herokuapp.com/";

    const url = `${basePath}${type}?includedJobTitles=${IncludedTitles}&excludedJobTitles=${ExcludedTitles}&includedIndustry=${includedIndustryString}&excludedIndustry=${excludedIndustryString}&includedCompany=${IncludedCompanies}&excludedCompany=${ExcludedCompanies}&includedCompanyDomain=${IncludedCompanyDomain}&excludedCompanyDomain=${ExcludedCompanyDomain}&titleLevel=${includedTitleLevelString}&companySize=${companySizeString}&unitedStatesOnly=${String(
      USOnly
    )}&includedStates=${statesString}&linkedInOnly=${String(
      LinkedInOnly
    )}&validationTypes=${validationTypesString}&recordsPerCompany=${recordsPerCompany}&SICCodes=${SICCode}&NAICSCodes=${NAICSCode}&apiKey=${apiKey}`;

    return url;
  };

  const getCount = () => {
    console.log("Getting the count..");

    setCountOnTheFlyLoading(true);
    const url = generateURL("get-count");

    if (apiKey !== "") {
      axios
        .get(url)
        .then(function (response) {
          console.log(response);
          if (response.data.status !== 200 && response.data.status !== 500) {
            alert("There has been an error, please contact support.");
          } else if (response.data.status === 500) {
            setCountOnTheFlyLoading(false);
            setCountOnTheFly(response.data.data || 0);
          } else {
            setCountOnTheFlyLoading(false);
            setCountOnTheFly(response.data.data || 0);
          }
        })
        .catch(function (error) {
          setCountOnTheFlyLoading(false);
        });
    }
  };

  const buildQuery = () => {
    setLoading(true);

    const url = generateURL("build-query");

    axios
      .get(url)
      .then(function (response) {
        setLoading(false);
        setShowMoreOptions(false);
        console.log(response);
        setReturnedData(response.data.data[0]);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("", error);
      });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        setUserObject(doc.data());
        if (doc.data().apiKey) {
          setApiKey(doc.data().apiKey);
        } else {
          alert("Please create an API key in settings before proceeding.");
        }
      });
  }, []);

  useEffect(() => {
    getCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IncludedTitles,
    IncludedTitleLevels,
    ExcludedTitles,
    IncludedCompanies,
    ExcludedCompanies,
    IncludedCompanyDomain,
    ExcludedCompanyDomain,
    IncludedIndustries,
    ExcludedIndustries,
    IncludedTitleLevels,
    CompanySize,
    validationTypes,
    states,
    USOnly,
    LinkedInOnly,
    recordsPerCompany,
    SICCode,
    NAICSCode,
  ]);

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={customStyles.sideBar}>
          <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 16 }}>
            Build A New Query
          </div>
          <div style={{ marginLeft: -12 }}>
            <CommaSeparatedPaste
              placeholder="Insert comma separated list. (eg: Developer, Pilot...)"
              label={"Included Job Titles"}
              onChange={(e) => {
                setIncludedTitles(e);
              }}
              width={250}
              value={IncludedTitles}
            />
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TitleLevelSelector
              onChange={(value) => {
                setIncludedTitleLevels(value);
              }}
            />
            <CompanySizeSelector onChange={setCompanySize} />
            <RecordsPerCompany
              onChange={setRecordsPerCompany}
              value={recordsPerCompany}
            />
            {/* Sidebar Buttons */}
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginTop: 5 }}>
                  <Button
                    onClick={() => [setShowIndustrySelection(true)]}
                    style={customStyles.sidebarButtonStyle}
                  >
                    Select Industries To Include
                  </Button>
                </div>
                <div style={{ marginTop: 5 }}>
                  <Button
                    onClick={() => setShowMoreOptions(true)}
                    style={customStyles.sidebarButtonStyle}
                  >
                    See More Options
                  </Button>
                </div>
                <div style={{ marginTop: 5 }}>
                  <Button
                    onClick={() => buildQuery()}
                    style={customStyles.sidebarButtonStyle}
                  >
                    {loading ? "Loading..." : "Run Query"}
                  </Button>
                </div>
                <div style={{ height: 200 }} />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* See More Options... */}
          <Dialog
            open={showMoreOptions}
            onClose={() => {
              setShowMoreOptions(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle>
              Create Query{" "}
              <div>
                {countOnTheFly > 0 && !countOnTheFlyLoading
                  ? `Estimated Count: ${countOnTheFly}`
                  : "Estimated Count: ..."}
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  margin: 24,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: Developer, Pilot...)"
                  label={"Included Job Titles"}
                  onChange={setIncludedTitles}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: Accountant, Realtor...)"
                  label={"Excluded Job Titles"}
                  onChange={setExcludedTitles}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: Apple, Walmart...)"
                  label={"Included Companies"}
                  onChange={setIncludedCompanies}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: Best Buy, Amazon...)"
                  label={"Excluded Companies"}
                  onChange={setExcludedCompanies}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: amazon.com, walmart.com...)"
                  label={"Included Company Domain"}
                  onChange={setIncludedCompanyDomain}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: apple.com, samsung.com...)"
                  label={"Excluded Company Domain"}
                  onChange={setExcludedCompanyDomain}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: 350, 450...)"
                  label={"Included SIC Codes"}
                  onChange={setSICCode}
                  width={376}
                />
                <CommaSeparatedPaste
                  placeholder="Insert comma separated list. (eg: 621210, 236115...)"
                  label={"Incldued Naics Codes"}
                  onChange={setNAICSCode}
                  width={376}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 24,
                  marginRight: 24,
                }}
              >
                <Button
                  onClick={() => [setShowIndustrySelection(true)]}
                  style={customStyles.dialogButtonStyle}
                >
                  Select Industries To Include
                </Button>
                <Button
                  onClick={() => [setShowExcludedIndustrySelection(true)]}
                  style={customStyles.dialogButtonStyle}
                >
                  Select Industries To Exclude
                </Button>
                <Button
                  onClick={() => [setShowStateSelection(true)]}
                  style={customStyles.dialogButtonStyle}
                >
                  Select US States To Limit Query To
                </Button>
              </div>
              <div
                style={{
                  marginTop: 24,
                  marginRight: 48,
                  marginBottom: 24,
                  marginLeft: 48,
                }}
              >
                <ValidationTypeSelector onChange={setValidationTypes} />
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                  <h6 style={{ fontWeight: "bold", marginBottom: 12 }}>
                    Extra Options:
                  </h6>
                  {/* US Only ? */}
                  <div style={{ marginRight: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={USOnly}
                          onChange={(e) => setUSOnly(e.target.checked)}
                          value={USOnly}
                          name="usOnly"
                          color="primary"
                          id="usOnly"
                        />
                      }
                      label="Only Include Results from the US"
                    />
                  </div>
                  {/* LinkedIn Only ? */}
                  <div style={{ marginRight: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={LinkedInOnly}
                          onChange={(e) => setLinkedInOnly(e.target.checked)}
                          value={LinkedInOnly}
                          name="usOnly"
                          color="primary"
                          id="usOnly"
                        />
                      }
                      label="Only Include Results with a LinkedIn URL"
                    />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <RecordsPerCompany
                      onChange={setRecordsPerCompany}
                      value={recordsPerCompany}
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                onClick={() => setShowMoreOptions(false)}
              >
                Close
              </Button>
              <Button onClick={() => buildQuery()}>
                {loading ? "Loading..." : "Run Query"}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Select Included Industries */}
          <Dialog
            open={showIndustrySelection}
            onClose={() => {
              setShowIndustrySelection(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle id="form-dialog-title">
              Select Industries To Include
            </DialogTitle>
            <DialogContent>
              <IndustrySelector onChange={setIncludedIndustries} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowIndustrySelection(false);
                }}
                color="primary"
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
          {/* Select Excluded Industries */}
          <Dialog
            open={showExcludedIndustrySelection}
            onClose={() => {
              setShowExcludedIndustrySelection(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle id="form-dialog-title">
              Select Industries To Exclude
            </DialogTitle>
            <DialogContent>
              <ExcludeIndustrySelector onChange={setExcludedIndustries} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowExcludedIndustrySelection(false);
                }}
                color="primary"
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
          {/* Select Included States */}
          <Dialog
            open={showStateSelection}
            onClose={() => {
              setShowStateSelection(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle id="form-dialog-title">
              Select States To Limit Query To
            </DialogTitle>
            <DialogContent>
              <StateSelector onChange={setStates} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowStateSelection(false);
                }}
                color="primary"
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
          {/* Table */}
        </div>
        {/* Table of results */}
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              borderBottom: "solid 1px #d2d2d2",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 80,
              paddingLeft: 30,
            }}
          >
            {countOnTheFly > 0 && !countOnTheFlyLoading ? (
              <div>
                <b>Estimated Results: {countOnTheFly.toLocaleString()}</b>
              </div>
            ) : (
              <div>
                {!countOnTheFlyLoading && <b>No results for this query.</b>}
              </div>
            )}
            {countOnTheFlyLoading && (
              <div>
                <b>Getting count...</b>
              </div>
            )}
            {countOnTheFly > 0 && (
              <Button
                onClick={() => {
                  downloadCSVFunction();
                }}
                style={customStyles.buttonStyle}
                disabled={downloadLoading}
              >
                {downloadLoading ? "Loading..." : "Download CSV Of Result"}
              </Button>
            )}
            {loading && (
              <div style={{ marginLeft: 30 }}>
                <CircularProgress />
              </div>
            )}
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              overflow: "scroll",
            }}
          >
            <ReactTable
              style={{ width: "auto", maxWidth: "75vw", margin: 30 }}
              className="-striped -highlight border-0"
              defaultPageSize={15}
              showPagination={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              noDataText="No query results"
              data={returnedData}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryBuilder;
