import React from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase';

const SingleStep = ({ steps, campaign }) => {
  function handleDelete(id) {
    // TODO: MAKE THIS A REDUX ACTION
    firebase
      .firestore()
      .collection('steps')
      .doc(id)
      .delete();
    console.log('Data Deleted');
  }
  return (
    <div>
      {steps &&
        steps.map(step => {
          return (
            <div className="card" key={step.id}>
              <div className="card-content">
                <p>
                  After <b>{step.days}</b> days
                </p>
                <b>Message:</b>
                <p style={{ whiteSpace: 'pre-wrap' }}>{step.msg}</p>
              </div>
              <div className="card-action">
                <Link
                  className="blue-text"
                  to={`/edit-followup-msg/${step.id}/${campaign}`}
                >
                  Edit
                </Link>
                <a
                  className="blue-text"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(step.id)}
                >
                  Delete
                </a>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SingleStep;
