import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase';

const ConnectMsg = ({ connectmsges, campaign }, props) => {
  function handleDelete(id) {
    // TODO: MAKE THIS A REDU ACTION
    firebase
      .firestore()
      .collection('connectmsges')
      .doc(id)
      .delete();
  }

  return (
    <div>
      {connectmsges &&
        connectmsges.map(msg => {
          return (
            <div className="card" key={msg.id}>
              <div className="card-content">
                <b>Message:</b>
                <p
                  style={{
                    whiteSpace: 'pre-wrap'
                  }}>
                  {msg.connectmsg}
                </p>
              </div>
              <div className="card-action">
                <Link
                  to={`/edit-connect-msg/${msg.id}/${campaign}`}
                  className="blue-text">
                  Edit
                </Link>{' '}
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(msg.id)}
                  className="blue-text">
                  Delete
                </a>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    // TODO : ACTION FOR REDUC STOERE deleteConnectMsg: (id) => dispatch(deleteConnectMsg(id))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ConnectMsg);
