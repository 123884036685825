const initState = {
}
const campaignReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_CAMPAIGN':
            console.log('created campaign', action.campaign)
            return state
        case 'CREATE_CAMPAIGN_ERROR':
            console.log('create campaign error', action.err)
            return state
        case 'EDIT_CAMPAIGN':
            console.log('edited campaign', action.campaign)
            return state
        case 'EDIT_CAMPAIGN_ERROR':
            console.log('edit campaign error', action.err)
            return state
        case 'ADDED_STEP':
            console.log('added step', action.campaign)
            return state
        case 'ADD_STEP_ERROR':
            console.log('adding step error', action.err)
            return state
        case 'STEP_MSG_EDIT':
            console.log('edited step', action.msg)
            return state
        case 'STEP_MSG_EDIT_ERROR':
            console.log('edit step error', action.err)
            return state
        case 'ADDED_CONNECT_MSG':
            console.log('added a connect message', action.msg)
            return state
        case 'CONNECT_MSG_ERROR':
            console.log('connect message adding error', action.err)
            return state
        case 'CONNECT_MSG_EDIT':
            console.log('Connect MEssage Edited!', action.msg)
            return state
        case 'CONNECT_MSG_EDIT_ERROR':
            console.log('Connect Message Edit Error', action.err)
            return state
        case 'CONNECT_COMPLETE':
            console.log('connect mark completed', action.contact)
            return state
        case 'CONNECT_MARK_ERROR':
            console.log('connect mar Error', action.err)
            return state
        case 'ADD_REPLY_MSG':
            console.log('added reply msg', action.replyMsg)
            return state
        case 'ADD_REPLY_ERROR':
            console.log('add reply msg error', action.err)
            return state
        case 'CONNECTION_ACCEPTED':
            console.log('connection accept update successful', action.contact)
            return state
        case 'CONNECTION_ACCEPTED_ERROR':
            console.log('connection accept error', action.err)
            return state
        case 'THANKS_MSG_ADDED':
            console.log('thanks msg added success', action.msg)
            return state
        case 'THANKS_MSG_ADDED_ERROR':
            console.log('thanks msg add error', action.err)
            return state
        case 'THANKS_MSG_EDIT':
            console.log('thanks msg edited', action.msg)
            return state
        case 'THANKS_MSG_EDIT_ERROR':
            console.log('Thanks msg edit error', action.err)
            return state
        case 'NAV_SEARCH_ADDED':
            console.log('nav search added to db', action.searchURL)
            return state
        case 'NAV_SEARCH_ADDED_ERROR':
            console.log('nav search add error', action.err)
            return state
        case 'CREATE_SLR_CAMPAIGN':
            console.log('created slr campaign', action.campaign)
            return state
        case 'CREATE_SLR_CAMPAIGN_ERROR':
            console.log('Error creating SLR Campaign', action.err)
            return state
        case 'ADD_SLR_CONNECT_MSG':
            console.log('Added SLR Connect Message', action.msg)
            return state
        case 'ADD_SLR_CONNECT_MSG_ERROR':
            console.log('Error adding SLR Connect Message', action.err)
            return state
        default:
            return state
    }
}

export default campaignReducer