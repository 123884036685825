import React from 'react';
import logo from '../../img/sla-logo.png';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const DeveloperLinks = props => {
  return (
    <ul className="right">
      <li>
        <NavLink to="/">Dashboard</NavLink>
      </li>
      <li>
        <NavLink to="/users">Users</NavLink>
      </li>
      <li>
        <NavLink to="/queries">Queries</NavLink>
      </li>
      <li>
        <NavLink to="/uploads">Uploads</NavLink>
      </li>
      <li>
        <NavLink to="/query-history">Query History</NavLink>
      </li>
      <li>
        <NavLink to="/campaigns">Campaigns</NavLink>
      </li>
      <li>
        <NavLink to="/reports">Reports</NavLink>
      </li>
      <li>
        <NavLink to="/settings">Settings</NavLink>
      </li>
      <li>
        <NavLink to="/helpers">Helpers</NavLink>
      </li>
      <li>
        <a onClick={props.signOut}>Log Out</a>
      </li>
      <li>
        <NavLink to="/account" className="btn btn-floating green lighten-1">
          {props.profile.initials}
        </NavLink>
      </li>
    </ul>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DeveloperLinks);
