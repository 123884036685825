import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import firebase from "./config/fbConfig";
import Navbar from "./components/nav/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import BQQueries from "./components/queries/BQQueries";
import LeadDetails from "./components/leads/LeadDetails";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import CreateLead from "./components/leads/CreateLead";
import Account from "./components/account/Account";
import Settings from "./components/settings/Settings";
import CreateTask from "./components/tasks/CreateTask";
import Campaigns from "./components/campaigns/Campaigns";
import LinkedInCampaign from "./components/campaigns/LinkedInCampaign";
import NewLinkedInCampaign from "./components/campaigns/NewLinkedInCampaign";
import EditLinkedInCampaign from "./components/campaigns/EditLinkedInCampaign";
import LiCampaignSteps from "./components/campaigns/LiCampaignSteps";
import FurtherLiStep from "./components/campaigns/FurtherLiStep";
import NewLiStep from "./components/campaigns/NewLiStep";
import People from "./components/campaigns/People";
import AddPeople from "./components/campaigns/AddPeople";
import FollowUp from "./components/campaigns/FollowUp";
import Users from "./components/users/Users";
import UserDetail from "./components/users/UserDetail";
import AddReply from "./components/campaigns/AddReply";
import InviteUser from "./components/users/InviteUser";
import ThanksMsg from "./components/campaigns/ThanksMsg";
import EditConnectMsg from "./components/campaigns/EditConnectMsg";
import EditThanksMsg from "./components/campaigns/EditThanksMsg";
import EditFollowUp from "./components/campaigns/EditFollowUp";
import Reports from "./components/reports/Reports";
import ViewQueries from "./components/queries/viewQueries";
import LiSLRCampaigns from "./components/campaigns/LiSLRCampaigns";
import NewSlrLiCampaign from "./components/campaigns/NewSlrLiCampaign";
import SlrMessaging from "./components/campaigns/SlrMessaging";
import AddSlrConnectMsg from "./components/campaigns/AddSlrConnectMsg";
import UserDashboard from "./components/users/userDashboard";
import Uploads from "./components/uploads/uploads";
import UserUploads from "./components/uploads/userUploads";
import Helpers from "./components/helpers/Helpers";
import PasswordReset from "./components/auth/PasswordReset";
import Documentation from "./components/settings/Documentation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import QueryBuilder from "./components/queries/QueryBuilder";

const stripePromise = loadStripe("pk_test_BQCSYLFL6cQi1oE9Jolib27j");

class App extends Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <div className="App">
            <div style={{ marginTop: 60 }}>
              <Navbar />
            </div>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/queries" component={QueryBuilder} />
              <Route path="/query-builder" component={QueryBuilder} />
              <Route path="/uploads-old" component={Uploads} />
              <Route path="/uploads" component={UserUploads} />
              <Route path="/query-history" component={ViewQueries} />
              <Route path="/lead/:id" component={LeadDetails} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/password-reset" component={PasswordReset} />
              <Route path="/create-lead/:id" component={CreateLead} />
              <Route path="/account" component={Account} />
              <Route path="/settings" component={Settings} />
              <Route path="/helpers" component={Helpers} />
              <Route path="/create-task" component={CreateTask} />
              <Route path="/campaigns" component={Campaigns} />
              <Route path="/li-campaign" component={LinkedInCampaign} />
              <Route path="/new-li-campaign" component={NewLinkedInCampaign} />
              <Route
                path="/edit-li-campaign/:id"
                component={EditLinkedInCampaign}
              />
              <Route path="/campaign-steps/:id" component={LiCampaignSteps} />
              <Route path="/add-step/:id" component={FurtherLiStep} />
              <Route path="/add-connect-msg/:id" component={NewLiStep} />
              <Route path="/people/:id" component={People} />
              <Route path="/user-dashboard/:id" component={UserDashboard} />
              <Route path="/addpeople/:id" component={AddPeople} />
              <Route path="/followup/:id?/:campaign?" component={FollowUp} />
              <Route path="/users" component={Users} />
              <Route path="/userdetail/:id" component={UserDetail} />
              <Route path="/add-reply/:id" component={AddReply} />
              <Route path="/invite-user" component={InviteUser} />
              <Route path="/callback/:id?" component={Settings} />
              <Route path="/add-thanks-msg/:id" component={ThanksMsg} />
              <Route
                path="/edit-connect-msg/:id?/:campaign?"
                component={EditConnectMsg}
              />
              <Route
                path="/edit-thanks-msg/:id?/:campaign?"
                component={EditThanksMsg}
              />
              <Route
                path="/edit-followup-msg/:id?/:campaign?"
                component={EditFollowUp}
              />
              <Route path="/reports" component={Reports} />
              <Route path="/li-slr-campaigns" component={LiSLRCampaigns} />
              <Route path="/new-li-slr-campaign" component={NewSlrLiCampaign} />
              <Route path="/slr-messaging/:id" component={SlrMessaging} />
              <Route
                path="/add-slr-connect-msg/:id"
                component={AddSlrConnectMsg}
              />
              <Route path="/documentation" component={Documentation} />
            </Switch>
          </div>
        </BrowserRouter>
      </Elements>
    );
  }
}

export default App;
