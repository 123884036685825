import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addStep } from '../../store/actions/campaignActions';
import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

export class FurtherLiStep extends Component {
  state = {
    days: '',
    content: '',
    campaign: ''
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.addStep(this.state);
    this.props.history.push('/campaign-steps/' + this.props.match.params.id);
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  componentDidMount() {
    this.setState({
      ...this.state,
      campaign: this.props.match.params.id
    });
  }
  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Add Step to Campaign</span>
            <form onSubmit={this.handleSubmit}>
              <div className="input-field">
                <label htmlFor="days">After How Many Days</label>
                <input type="text" id="days" onChange={this.handleChange} />
              </div>
              <div className="input-field">
                <label htmlFor="msg">Follow Up Message:</label>
                <input type="text" id="msg" onChange={this.handleChange} />
              </div>
              <span style={{ fontSize: '12px' }}>
                Merge tags:
                <br />
                #firstName# - First Name
                <br />
                #lastName# - Last Name
                <br />
                #company# - Company Name
                <br />
              </span>
              <br />
              <button className="btn green right">Save</button>
              <Link
                to={`/campaign-steps/${this.props.match.params.id}`}
                className="btn red lighten-1">
                Cancel
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const users = state.firestore.data.users;
  return {
    auth: state.firebase.auth,
    users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addStep: step => dispatch(addStep(step))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: 'campaigns' }])
)(FurtherLiStep);
