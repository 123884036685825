import React, { Component } from 'react';
import { Autocomplete, Chip, Dropdown } from 'react-materialize';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { createLiCampaign } from '../../store/actions/campaignActions';
import { connect } from 'react-redux';

export class NewLinkedInCampaign extends Component {
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createLiCampaign(this.state);
    this.props.history.push('/li-campaign');
  };

  handleMultiChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option
    });
  };

  handleClientChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option[0].value
    });
  };

  state = {
    campaignName: '',
    sessionCookie: '',
    profileUrl: '',
    industry: [],
    countries: '',
    states: '',
    cities: '',
    companySize: '',
    keywords: [],
    seniority: '',
    client: ''
  };
  render() {
    const { users } = this.props;
    let userList = [];
    users &&
      users.map(user => {
        const userEmail = user.email;
        const userFirstName = user.firstName;
        const userLastName = user.lastName;

        const userLine = {
          value: userEmail,
          label: userEmail + ' - ' + userFirstName + ' ' + userLastName
        };
        userList.push(userLine);
      });
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">New LinkedIn Campaign</span>
            <form onSubmit={this.handleSubmit}>
              <div className="input-field">
                <label htmlFor="campaignName">Campaign Name</label>
                <input
                  type="text"
                  id="campaignName"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-field">
                <label htmlFor="sessionCookie">Session Cookie</label>
                <input
                  type="text"
                  id="sessionCookie"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-field">
                <label htmlFor="profileUrl">LinkedIn Profile URL</label>
                <input
                  type="text"
                  id="profileUrl"
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-field">
                <Select
                  options={userList}
                  isMulti
                  name="client"
                  placeholder="Client"
                  onChange={this.handleClientChange}
                />
              </div>
              <h5>Targeting:</h5>
              <div className="input-field">
                <Select
                  options={industries}
                  isMulti
                  name="industry"
                  placeholder="Industry"
                  onChange={this.handleMultiChange}
                />
              </div>
              <div className="input-field">
                <Select
                  options={seniority}
                  isMulti
                  name="seniority"
                  placeholder="Seniority / Position"
                  onChange={this.handleMultiChange}
                />
              </div>
              <div className="input-field">
                <Select
                  options={companySize}
                  isMulti
                  name="companySize"
                  placeholder="Company Size"
                  onChange={this.handleMultiChange}
                />
              </div>
              <div className="input-field">
                <Select
                  options={countries}
                  isMulti
                  name="countries"
                  placeholder="Country"
                  onChange={this.handleMultiChange}
                />
              </div>
              <div className="input-field">
                <Select
                  options={states}
                  isMulti
                  name="states"
                  placeholder="State"
                  onChange={this.handleMultiChange}
                />
              </div>
              <div className="input-field">
                <Select
                  options={cities}
                  isMulti
                  name="cities"
                  placeholder="City"
                  onChange={this.handleMultiChange}
                />
              </div>
              <div className="input-field">
                <button className="btn right">Submit</button>
                <Link to="li-campaign" className="btn red">
                  Cancel
                </Link>
              </div>
            </form>
          </div>
          <div className="card-action sla-action">
            {/* <Link className="btn green right" to="/campaign-steps/test">Next </Link> */}
          </div>
        </div>
      </div>
    );
  }
}

const industries = [
  { value: 'Web Development', label: 'Web Development' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Relocation', label: 'Relocation' }
];

const seniority = [
  { value: 'csuite', label: 'C-Suite / Executive' },
  { value: 'management', label: 'Management' },
  { value: 'employee', label: 'Employee' }
];

const companySize = [
  { value: '10', label: '1-10 Employees' },
  { value: '20', label: '11-20 Employees' },
  { value: '50', label: '21-50 Employees' },
  { value: '100', label: '51-100 Employees' },
  { value: '500', label: '101-500 Employees' },
  { value: '1000', label: '501-1000 Employees' },
  { value: '1000+', label: '1000+ Employees' }
];

const countries = [
  { value: 'usa', label: 'United States of America' },
  { value: 'ger', label: 'Germany' },
  { value: 'fra', label: 'France' },
  { value: 'uk', label: 'United Kingdom' }
];

const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'Dist. of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Marianas' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

const cities = [
  { value: 'New York', label: 'New York' },
  { value: 'Miami', label: 'Miami' },
  { value: 'Los Angeles', label: 'Los Angeles' },
  { value: 'London', label: 'London' },
  { value: 'Sydney', label: 'Sydney' },
  { value: 'Chicago', label: 'Chicago' }
];

const keywords = [
  { value: 'seo', label: 'SEO' },
  { value: 'marketing', label: 'Marketing' }
];

const mapStateToProps = state => {
  const users = state.firestore.ordered.users;
  const campaigns = state.firestore.ordered.campaigns;
  return {
    auth: state.firebase.auth,
    users,
    campaigns
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createLiCampaign: campaign => dispatch(createLiCampaign(campaign))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: 'users' }, { collection: 'campaigns' }])
)(NewLinkedInCampaign);
