import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { editConnectMsg } from '../../store/actions/campaignActions';
import { Link } from 'react-router-dom';

export class EditConnectMsg extends Component {
  state = {
    msg: ''
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log('Form Submitted');
    this.props.editConnectMsg(this.state.msg, this.props.id);
    this.props.history.push('/campaign-steps/' + this.props.campaign);
  };
  render() {
    const { conMsg } = this.props;
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Edit Invitation Message</span>

            {conMsg &&
              conMsg.map(invmsg => {
                return (
                  <p
                    style={{
                      whiteSpace: 'pre-wrap'
                    }}
                    key={invmsg.id}>
                    {invmsg.connectmsg}
                  </p>
                );
              })}
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="msg">Message</label>
                {/* <input
                      type="text"
                      id="msg"
                      onChange={this.handleChange}
                    /> */}
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="msg"
                  style={{ height: 'auto' }}
                  onChange={this.handleChange}
                />
              </div>

              <span style={{ fontSize: '12px' }}>
                Merge tags:
                <br />
                #firstName# - First Name
                <br />
                #lastName# - Last Name
                <br />
                #company# - Company Name
                <br />
              </span>
              <br />
              <button className="btn right">Save</button>
              <Link
                className="btn red lighten-1"
                to={`/campaign-steps/${this.props.match.params.campaign}`}>
                Back
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const conMsg = state.firestore.ordered.connectmsges;
  const campaign = ownProps.match.params.campaign;
  return {
    id,
    conMsg,
    campaign
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editConnectMsg: (msg, id) => dispatch(editConnectMsg(msg, id))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
    { collection: 'connectmsges', where: ['campaign', '==', props.campaign] }
  ])
)(EditConnectMsg);
