import React from 'react'

const AgentReportLine = ({contacts, users}) => {
    console.log(users)
    return (
        <tbody>

        {users && users.map((user) => {

            let invitesSent = ''
            let invitesAccepted = ''
            let thanksSent = ''
            let followUpsSent = ''
            let repliesReceived = ''
            contacts && contacts.map((contact) => {
                if (contact.connectSentBy === user.email) {
                    invitesSent ++
                }
                if (contact.didAcceptConnectionBy === user.email) {
                    invitesAccepted ++
                }
                if (contact.thankYouSentBy === user.email) {
                    thanksSent ++
                }
                if (contact.followUpSentBy === user.email) {
                    followUpsSent ++
                }
                // TODO: Handle replies count from replies table
                return
            })

            return (
                <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{invitesSent}</td>
                    <td>{invitesAccepted}</td>
                    <td>TBA</td>
                    <td>{thanksSent}</td>
                    <td>{followUpsSent}</td>
                    <td>{repliesReceived}</td>
                    <td>TBA</td>
                </tr>
            )
        })}
        </tbody>
    )



}

export default AgentReportLine