import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    width: '90%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
}));

const Documentation = () => {
  const [parameters, setParameters] = useState([
    { label: 'Email', required: 'Yes' },
    { label: 'Title', required: 'No' },
    { label: 'ValidationStatusId', required: 'No' },
    { label: 'ValidationStatus', required: 'No' },
    { label: 'EmailDomainGroupId', required: 'No' },
    { label: 'EmailDomainGroup', required: 'No' },
    { label: 'EOF', required: 'No' },
    { label: 'Industry', required: 'No' },
    { label: 'Keywords', required: 'No' },
    { label: 'Website', required: 'No' },
    { label: 'City', required: 'No' },
    { label: 'State', required: 'No' },
    { label: 'Country', required: 'No' },
    { label: 'Technologies', required: 'No' },
    { label: 'First_Name', required: 'No' },
    { label: 'Last_Name', required: 'No' },
    { label: 'Job_Level', required: 'No' },
    { label: 'Company_Name', required: 'No' },
    { label: 'Email_Status', required: 'No' },
    { label: 'First_Phone', required: 'No' },
    { label: 'Corporate_Phone', required: 'No' },
    { label: 'No_Employees', required: 'No' },
    { label: 'Person_Linkedin_Url', required: 'No' },
    { label: 'Company_Linkedin_Url', required: 'No' },
    { label: 'Facebook_Url', required: 'No' },
    { label: 'Twitter_Url', required: 'No' },
    { label: 'Contact_Address_1', required: 'No' },
    { label: 'Contact_Address_2', required: 'No' },
    { label: 'Contact_Address_3', required: 'No' },
    { label: 'Zip_Code', required: 'No' },
    { label: 'Company_Address', required: 'No' },
    { label: 'Company_Address_2', required: 'No' },
    { label: 'Company_City', required: 'No' },
    { label: 'Company_State', required: 'No' },
    { label: 'Company_Zip_Code', required: 'No' },
    { label: 'Company_Country', required: 'No' },
    { label: 'Company_Phone', required: 'No' },
    { label: 'SIC_Code', required: 'No' },
    { label: 'Naics_Code', required: 'No' },
    { label: 'SEO_Description', required: 'No' },
    { label: 'Annual_Revenue', required: 'No' },
    { label: 'Number_of_Retail_Locations', required: 'No' },
  ]);
  const classes = useStyles();

  return (
    <div className="container">
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div className="h2">API Documentation:</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderBottom: 'solid',
                  borderBottomWidth: 1,
                  alignItems: 'center',
                  borderBottomColor: '#fafafa',
                }}
              >
                <NotificationImportantIcon
                  style={{
                    color: '#d2d2d2',
                    paddingRight: 10,
                    fontSize: 32,
                  }}
                />
                <p>
                  The Sales Lead Automation API relies on you having a valid API
                  key which can be obtained from the{' '}
                  <a href="/settings" target="_blank" rel="noopener noreferrer">
                    settings
                  </a>{' '}
                  page.
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderBottom: 'solid',
                  borderBottomWidth: 1,
                  alignItems: 'center',
                  borderBottomColor: '#fafafa',
                }}
              >
                <NotificationImportantIcon
                  style={{
                    color: '#d2d2d2',
                    paddingRight: 10,
                    fontSize: 32,
                  }}
                />
                <p>
                  Please generate a new API key if you believe your key was
                  compromised.
                </p>
              </div>
              {/* <div className="h2" style={{ marginTop: 20 }}>
                Updating A Record:
              </div>
              <div style={{ textAlign: 'left' }} className="metric-number">
                Endpoint (POST):
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                {`https://sla-server.herokuapp.com/update-row/?apiKey={your-api-key}&Email={email-of-record-to-update}`}
              </div>
              <div
                style={{ textAlign: 'left', marginBottom: 20 }}
                className="metric-number"
              >
                Available Parameters:
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #666666',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>
                  Parameter
                </div>
                <div style={{ width: '30%', fontWeight: 'bold' }}>Type</div>
                <div style={{ width: '30%', fontWeight: 'bold' }}>Required</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #fafafa',
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>apiKey</div>
                <div style={{ width: '30%' }}>String</div>
                <div style={{ width: '30%' }}>Yes</div>
              </div>

              {parameters.map((item) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #fafafa',
                    height: 50,
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '30%', fontWeight: 'bold' }}>
                    {item.label}
                  </div>
                  <div style={{ width: '30%' }}>String</div>
                  <div style={{ width: '30%' }}>{item.required}</div>
                </div>
              ))}
              <div
                style={{ textAlign: 'left', marginTop: 20 }}
                className="metric-number"
              >
                Example:
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                {`https://sla-server.herokuapp.com/update-row?apiKey=3c45e041-0393-432d-ba01-a626ada6b161&Email=test@test.com&ValidationStatus=Verified&Industry=Aviation`}
              </div>
              <div
                style={{ textAlign: 'left', marginTop: 20 }}
                className="metric-number"
              >
                Sample Responses:
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                Success: <br />
                <br />
                {`{
                  "status": 200,
                  "message": "Row Updated"
                }`}
                <br />
                <br />
                Generic Error: <br />
                <br />
                {`{
                  status: 400,
                  message: 'Error message will describe issue.',
                }`}
                <br />
                <br />
                Invalid API Key: <br />
                <br />
                {`{
                  status: 400,
                  message: 'API Key is invalid.',
                }`}
              </div>*/}
              <div className="h2" style={{ marginTop: 40 }}>
                Retrieving A Record Via Email:
              </div>
              <div style={{ textAlign: 'left' }} className="metric-number">
                Endpoint (GET):
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                {`https://sla-server.herokuapp.com/get-contact-email/?apiKey={your-api-key}&Email={email-of-record-to-retrieve}`}
              </div>
              <div
                style={{ textAlign: 'left', marginBottom: 20 }}
                className="metric-number"
              >
                Available Parameters:
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #666666',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>
                  Parameter
                </div>
                <div style={{ width: '30%', fontWeight: 'bold' }}>Type</div>
                <div style={{ width: '30%', fontWeight: 'bold' }}>Required</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #fafafa',
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>apiKey</div>
                <div style={{ width: '30%' }}>String</div>
                <div style={{ width: '30%' }}>Yes</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #fafafa',
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>Email</div>
                <div style={{ width: '30%' }}>String</div>
                <div style={{ width: '30%' }}>Yes</div>
              </div>
              <div
                style={{ textAlign: 'left', marginTop: 20 }}
                className="metric-number"
              >
                Example:
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                {`https://sla-server.herokuapp.com/get-contact-email?apiKey=3c45e041-0393-432d-ba01-a626ada6b161&Email=test@test.com`}
              </div>
              <div
                style={{ textAlign: 'left', marginTop: 20 }}
                className="metric-number"
              >
                Sample Responses:
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                Success: <br />
                <br />
                {`{
                  "status": 200,
                  "message": "Success",
                  "data": {
                      "firstName": "Joe",
                      "Title": "Lead Fragrance Developer",
                      "Company": "Soaps Inc.",
                      "City": "Los Angeles",
                      "State": "CA",
                      "Website": "example.com",
                      "Industry": "Soap",
                      "No_Employees": "10,001+ employees"
                  }
                }`}
                <br />
                <br />
                Generic Error: <br />
                <br />
                {`{
                  status: 400,
                  message: 'Error message will describe issue.',
                }`}
                <br />
                <br />
                Invalid API Key: <br />
                <br />
                {`{
                  status: 400,
                  message: 'API Key is invalid.',
                }`}
              </div> 
              <div className="h2" style={{ marginTop: 40 }}>
                Retrieving A Record Via Linkedin URL:
              </div>
              <div style={{ textAlign: 'left' }} className="metric-number">
                Endpoint (GET):
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                {`https://sla-server.herokuapp.com/get-contact-linkedin?apiKey={your-api-key}&Person_Linkedin_Url={linkedin-url-record-to-retrieve}`}
              </div>
              <div
                style={{ textAlign: 'left', marginBottom: 20 }}
                className="metric-number"
              >
                Available Parameters:
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #666666',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>
                  Parameter
                </div>
                <div style={{ width: '30%', fontWeight: 'bold' }}>Type</div>
                <div style={{ width: '30%', fontWeight: 'bold' }}>Required</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #fafafa',
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>apiKey</div>
                <div style={{ width: '30%' }}>String</div>
                <div style={{ width: '30%' }}>Yes</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #fafafa',
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '30%', fontWeight: 'bold' }}>
                  Person_Linkedin_Url
                </div>
                <div style={{ width: '30%' }}>String</div>
                <div style={{ width: '30%' }}>Yes</div>
              </div>
              <div
                style={{ textAlign: 'left', marginTop: 20 }}
                className="metric-number"
              >
                Example:
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                {`https://sla-server.herokuapp.com/get-contact-linkedin?apiKey=3c45e041-0393-432d-ba01-a626ada6b161&Person_Linkedin_Url=https://www.linkedin.com/in/joe-soap-59133211/`}
              </div>
              <div
                style={{ textAlign: 'left', marginTop: 20 }}
                className="metric-number"
              >
                Sample Responses:
              </div>
              <div
                style={{
                  margin: 20,
                  padding: 20,
                  backgroundColor: '#d2d2d2',
                  textAlign: 'left',
                }}
              >
                Success: <br />
                <br />
                {`{
                  "status": 200,
                  "message": "Success",
                  "data": {
                      "firstName": "Joe",
                      "Title": "Lead Fragrance Developer",
                      "Company": "Soaps Inc.",
                      "City": "Los Angeles",
                      "State": "CA",
                      "Website": "example.com",
                      "Industry": "Soap",
                      "No_Employees": "10,001+ employees"
                  }
                }`}
                <br />
                <br />
                Generic Error: <br />
                <br />
                {`{
                  status: 400,
                  message: 'Error message will describe issue.',
                }`}
                <br />
                <br />
                Invalid API Key: <br />
                <br />
                {`{
                  status: 400,
                  message: 'API Key is invalid.',
                }`}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Documentation;
