import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import linkedinlogo from '../../img/linkedinlogo.png';
import { Modal, Select, SideNav, SideNavItem, Button } from 'react-materialize';
import { connect } from 'react-redux';
import { updateLeadStatus } from '../../store/actions/leadActions';
import firebase from '../../config/fbConfig';
import ActivityIndicator from 'react-activity-indicator';

// export class Lead extends Component {

const Lead = ({ contact, updateLeadStatus, refreshToken, updateState }) => {
  let replyLogo = '';
  if (contact.replyMsg) {
    replyLogo = (
      <a href={contact.replyMsg} rel="noopener noreferrer" target="_blank">
        <img alt="" src={linkedinlogo} width="30" />
      </a>
    );
  } else {
    replyLogo = 'No Reply';
  }

  let conDate = '';
  if (contact.connectionDate) {
    conDate = <td>{moment(contact.connectionDate.toDate()).calendar()}</td>;
  } else {
    conDate = <td>No Conn Date</td>;
  }

  function handleSelect(e, contact) {
    const newStatus = e.target.value;
    updateLeadStatus(newStatus, contact);
  }
  const leadDetails = (
    <div>
      <p>Company: {contact.company}</p>
      <p>Title: {contact.title}</p>
      <p>Phone: {contact.firstPhone}</p>
      <p>Work Email: {contact.email}</p>
      <p>Other Email: {contact.otherEmail}</p>
      <p>Status: {contact.leadStatus} </p>
      <div className="input-field">
        <Select id="leadStatus" onChange={e => handleSelect(e, contact.id)}>
          <option value="" default>
            Change Status
          </option>
          <option value="Hot">Hot</option>
          <option value="Warm">Warm</option>
          <option value="Cold">Cold</option>
          <option value="Meeting Booked">Meeting Booked</option>
          <option value="Replied">Replied</option>
          <option value="Nurture">Nurture</option>
          <option value="Not Interested">Not Interested</option>
          <option value="Do not contact">Don't Contact</option>
        </Select>
      </div>
      <p>
        <a href={contact.liProfile} target="_blank">
          LinkedIn Profile
        </a>
      </p>
      <p>Industry: {contact.industry}</p>
      <p>Company City: {contact.companyCity}</p>
      <p>Company Country: {contact.companyCountry}</p>
      <p>Company Employees: {contact.numEmployees}</p>
      {/* TODO: Remember to check first if these are empty before rendering them  */}
      <p>
        <a href={contact.facebookUrl} target="_blank">
          Company Facebook Page
        </a>
      </p>
      <p>
        <a href={contact.twitterUrl} target="_blank">
          Company Twitter Page
        </a>
      </p>
    </div>
  );

  // <Modal header={lead.firstName + ' ' + lead.lastName} trigger={<a>{lead.firstName}</a>}>{leadDetails}</Modal>

  return (
    <tr>
      <td>
        <SideNav
          options={{ edge: 'right' }}
          trigger={<a className="lead-link">{contact.firstName}</a>}>
          <SideNavItem style={{ textAlign: 'left' }} icon="person_pin">
            {contact.firstName + ' ' + contact.lastName}
          </SideNavItem>
          <div style={{ textAlign: 'left', padding: '20px' }}>
            {leadDetails}
          </div>
        </SideNav>
      </td>
      <td>
        <SideNav
          options={{ edge: 'right' }}
          trigger={<a className="lead-link">{contact.lastName}</a>}>
          <SideNavItem style={{ textAlign: 'left' }} icon="person_pin">
            {contact.firstName + ' ' + contact.lastName}
          </SideNavItem>
          <div style={{ textAlign: 'left', padding: '20px' }}>
            {leadDetails}
          </div>
        </SideNav>
      </td>
      <td>
        <SideNav
          options={{ edge: 'right' }}
          trigger={<a className="lead-link">{contact.company}</a>}>
          <SideNavItem style={{ textAlign: 'left' }} icon="person_pin">
            {contact.firstName + ' ' + contact.lastName}
          </SideNavItem>
          <div style={{ textAlign: 'left', padding: '20px' }}>
            {leadDetails}
          </div>
        </SideNav>
      </td>
      <td>
        <a href={'mailto:' + contact.email}>{contact.email}</a>
      </td>
      {/* <td><a href={'mailto:'+contact.otherEmail}>{contact.otherEmail}</a></td> */}
      <td>
        <SideNav
          options={{ edge: 'right' }}
          trigger={<a className="lead-link">{contact.firstPhone}</a>}>
          <SideNavItem style={{ textAlign: 'left' }} icon="person_pin">
            {contact.firstName + ' ' + contact.lastName}
          </SideNavItem>
          <div style={{ textAlign: 'left', padding: '20px' }}>
            {leadDetails}
          </div>
        </SideNav>
      </td>
      <td>
        <SideNav
          options={{ edge: 'right' }}
          trigger={<a className="lead-link">{contact.leadStatus}</a>}>
          <SideNavItem style={{ textAlign: 'left' }} icon="person_pin">
            {contact.firstName + ' ' + contact.lastName}
          </SideNavItem>
          <div style={{ textAlign: 'left', padding: '20px' }}>
            {leadDetails}
          </div>
        </SideNav>
      </td>
      <td>
        <a href={contact.liProfile} target="_blank">
          <img src={linkedinlogo} width="30" />
        </a>
      </td>
      <td>{replyLogo}</td>
      {/* {conDate} */}
      <td>
        <Button
          disabled={contact.addedToCRM}
          onClick={() => {
            var infoForSalesForce = {
              FirstName: contact.firstName,
              LastName: contact.lastName,
              Email: contact.email,
              Phone: contact.firstPhone,
              City: contact.city,
              Street: contact.companyAddress,
              Title: contact.title,
              Description: contact.technologies,
              Company: contact.company,
              Country: contact.country
            }; //Ok let's deal with this later.

            const salesForceRefreshToken = refreshToken;

            if (salesForceRefreshToken) {
              updateState();

              var addSalesForceLead = firebase
                .functions()
                .httpsCallable('addSalesForceLead');

              addSalesForceLead({
                refresh_token: refreshToken,
                contact: infoForSalesForce
              })
                .then(function(result) {
                  firebase
                    .firestore()
                    .collection('contacts')
                    .doc(contact.id)
                    .update({ addedToCRM: true })
                    .then(() => {
                      updateState();
                      console.log('Contact updated');
                      alert('Lead added to CRM');
                    });
                })
                .catch(error => {
                  updateState();
                  alert(error.message);
                });
            } else {
              alert('No CRM Linked');
            }
          }}
          className="btn-small blue darken-2"
          id="crm">
          {!contact.addedToCRM && (
            <div>
              <i className="material-icons left">add_circle</i>CRM
            </div>
          )}
          {contact.addedToCRM && <div>Added</div>}
        </Button>
      </td>
      {/* <td><a className="waves-effect waves-light btn-small"><i className="material-icons left">mail</i>Nurture</a></td> */}
    </tr>
  );
};

const mapDispatchToProps = dispatch => {
  // const userProfile = state.firebase.profile;
  return {
    updateLeadStatus: (status, contact) =>
      dispatch(updateLeadStatus(status, contact))
    // userProfile
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Lead);
