import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ValidationTypeSelector = (props) => {
  const [validationTypes, setValidationTypes] = useState(
    require("../resources/validationTypes.json")
  );

  const handleValidationTypeCheckBoxChange = (event, index) => {
    const tempIncludedValidationTypes = validationTypes;
    tempIncludedValidationTypes[index].checked = event.target.checked;
    setValidationTypes(tempIncludedValidationTypes);
    const selectedOptions = tempIncludedValidationTypes.filter(
      (title) => title.checked
    );
    props.onChange(selectedOptions);
  };

  return (
    <div>
      <h6 style={{ fontWeight: "bold", marginBottom: 12 }}>
        Select Validation Types:
      </h6>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {validationTypes.map((item, index) => (
          <div
            key={String(Math.random())}
            style={{ width: 250, marginRight: 10 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={validationTypes[index].checked}
                  onChange={(e) => handleValidationTypeCheckBoxChange(e, index)}
                  value={item.label}
                  name="validationTypes"
                  color="primary"
                  id="validationTypes"
                />
              }
              label={item.label}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ValidationTypeSelector;
