import React, { Component } from 'react';
import Select from 'react-select';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createLiSlrCampaign } from '../../store/actions/campaignActions';

class NewSlrLiCampaign extends Component {
  state = {
    time: '00:00',
    batchSize: 0,
    batchRuns: 0,
    mondayChecked: false,
    tuesdayChecked: false,
    wednesdayChecked: false,
    thursdayChecked: false,
    fridayChecked: false,
    saturdayChecked: false,
    sundayChecked: false,
    slots: [],
    timeslots: [],
    campaignName: ''
  };
  timeChange = time => this.setState({ time });

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const submission = {
      batchSize: this.state.batchSize,
      batchRuns: this.state.batchRuns,
      mondayChecked: this.state.mondayChecked,
      tuesdayChecked: this.state.tuesdayChecked,
      wednesdayChecked: this.state.wednesdayChecked,
      thursdayChecked: this.state.thursdayChecked,
      fridayChecked: this.state.fridayChecked,
      saturdayChecked: this.state.saturdayChecked,
      sundayChecked: this.state.sundayChecked,
      timeslots: this.state.timeslots,
      campaignName: this.state.campaignName
    };
    this.props.createLiSlrCampaign(submission);
    this.props.history.push('/li-slr-campaigns');
  };
  handleClientChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option[0].value
    });
  };
  changeCheckbox = e => {
    const daySelected = e.target.id;
    this.setState({
      [daySelected]: !this.state[daySelected]
    });
  };

  addTime = e => {
    this.state.timeslots.push(e.target.value);
  };

  addTimeSlot = e => {
    const numDays = e.target.value;
    let slots = [];
    for (let i = 0; i < numDays; ++i) {
      slots.push(<input type="time" key={i} onChange={this.addTime} />);
    }
    this.setState({ slots });
  };
  render() {
    const { users } = this.props;
    let userList = [];
    users &&
      users.map(user => {
        const userEmail = user.email;
        const userFirstName = user.firstName;
        const userLastName = user.lastName;

        const userLine = {
          value: userEmail,
          label: userEmail + ' - ' + userFirstName + ' ' + userLastName
        };
        userList.push(userLine);
      });
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">New Linked In SLR Campaign</span>
            <form onSubmit={this.handleSubmit}>
              <div className="input-field">
                <label htmlFor="campaignName">Campaign Name</label>
                <input
                  type="text"
                  id="campaignName"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-field">
                <Select
                  options={userList}
                  isMulti
                  name="client"
                  placeholder="Client"
                  onChange={this.handleClientChange}
                />
              </div>
              <div className="input-field">
                <label htmlFor="batchSize">Batch Size: (Recommend: 10)</label>
                <input
                  type="number"
                  id="batchSize"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-field">
                <label htmlFor="batchRuns">
                  How many batch runs per day? (Recommend: 7)
                </label>
                <input
                  type="number"
                  id="batchRuns"
                  onChange={this.addTimeSlot}
                />
              </div>
              <h6>Run on these days:</h6>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="mondayChecked"
                />
                <span>Monday</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="tuesdayChecked"
                />
                <span>Tuesday</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="wednesdayChecked"
                />
                <span>Wednesday</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="thursdayChecked"
                />
                <span>Thursday</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="fridayChecked"
                />
                <span>Friday</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="saturdayChecked"
                />
                <span>Saturday</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onChange={this.changeCheckbox}
                  id="sundayChecked"
                />
                <span>Sunday</span>
              </label>
              <h6>Run on these hours:</h6>
              <p>
                Note: Actual times between each invite may be between 10-20
                seconds
              </p>
              <div className="input-field">
                {this.state.slots &&
                  this.state.slots.map(timeslot => {
                    return timeslot;
                  })}
              </div>
              <button className="btn">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const users = state.firestore.ordered.users;
  return {
    users
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createLiSlrCampaign: campaign => dispatch(createLiSlrCampaign(campaign))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: 'users' }, { collection: 'campaigns' }])
)(NewSlrLiCampaign);
