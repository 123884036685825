import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase/app';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connectionDidAccept } from '../../store/actions/campaignActions';

class FollowUp extends Component {
  state = {
    contacts: '',
    campaign: ''
  };

  markComplete = (contactStep, daysToNext) => {
    let addDays = parseInt(daysToNext, 10);
    let date = new Date();
    let newDate = date.setDate(date.getDate() + addDays);

    // TODO: Take the below firebase call and add it as an acion in the redux store
    const dbRef = firebase
      .firestore()
      .collection('contacts')
      .doc(this.props.id);
    dbRef
      .update({
        followUpStep: contactStep,
        nextStepDate: newDate,
        followUpSentBy: this.props.user.email
        // TODO: Use this date to then trigger reminders to the agent: https://stackoverflow.com/questions/54323163/how-to-trigger-function-when-date-stored-in-firestore-database-is-todays-date
      })
      .then(() => {
        console.log('Updated Contact Step');
        this.props.history.push('/people/' + this.props.campaign);
      })
      .catch(err => {
        console.log('Error: ', err);
      });
  };

  handleCopy = () => {
    console.log('it was copied!');
  };

  connectionAccepted = () => {
    this.props.connectionDidAccept(this.props.id);
    this.props.history.push('/people/' + this.props.campaign);
  };

  render() {
    let date = new Date();
    let numDatesToAdd = 2;
    let newDate = date.setDate(date.getDate() + numDatesToAdd);

    let formatDate = moment(newDate)
      .endOf('hour')
      .fromNow();

    const { steps, contacts } = this.props;
    let stepNumber = 0;
    let contactStep = 0;
    let nextStepDate = '';
    let mapObj = '';
    let liProfile = '';
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Follow Up</span>
            Current Follow Up Step:
            {contacts &&
              contacts.map(contact => {
                contactStep = contact.followUpStep;
                nextStepDate = contact.nextStepDate;

                liProfile = contact.liProfile;

                // String Replacement for Merge Tags
                mapObj = {
                  '#firstName#': contact.firstName,
                  '#lastName#': contact.lastName,
                  '#company#': contact.company,
                  '#industry#': contact.industry
                };
              })}
            {steps &&
              steps.map(step => {
                stepNumber++;
                // String Replacement for Merge Tags
                let newMsg = step.msg.replace(
                  /#firstName#|#lastName#|#company#|#industry#/gi,
                  function(matched) {
                    return mapObj[matched];
                  }
                ); // This is the modified invitation message with the persons actual name
                if (stepNumber == contactStep) {
                  return (
                    <div className="card" key={stepNumber}>
                      <div className="card-content">
                        Step {stepNumber}
                        <br />
                        After <b>{step.days}</b> days:
                        <p>{newMsg}</p>
                        <br />
                        <p>
                          This message must be sent:{' '}
                          {moment(nextStepDate).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </p>
                      </div>
                      <div className="card-action">
                        <CopyToClipboard text={newMsg} onCopy={this.handleCopy}>
                          <a
                            className="btn waves-effect waves-light"
                            id="copybtn">
                            Copy
                          </a>
                        </CopyToClipboard>{' '}
                        <a className="btn" href={liProfile} target="_blank">
                          Go To Profile
                        </a>{' '}
                        <button
                          className="btn"
                          onClick={() =>
                            this.markComplete(contactStep + 1, step.days)
                          }>
                          Mark as Complete
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return <p />;
                }
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const contactId = ownProps.match.params.id;
  const steps = state.firestore.ordered.steps;
  const connectmsges = state.firestore.ordered.connectmsges;
  const campaign = ownProps.match.params.campaign;
  const contacts = state.firestore.ordered.contacts;
  const user = state.firebase.profile;
  return {
    steps,
    auth: state.firebase.auth,
    id: contactId,
    connectmsges,
    campaign,
    contacts,
    user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    connectionDidAccept: contact => dispatch(connectionDidAccept(contact))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
    {
      collection: 'steps',
      where: ['campaign', '==', props.campaign],
      orderBy: ['createdAt', 'asc']
    },
    { collection: 'contacts', doc: props.id }
  ])
)(FollowUp);
