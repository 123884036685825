import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { createTask } from '../../store/actions/taskActions'

export class CreateTask extends Component {
  state = {
      task:''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
      e.preventDefault()
      this.props.createTask(this.state)
  }
render() {
    return (
      <div>
        <h5>Add Task:</h5>
        <form onSubmit={this.handleSubmit}>
            <div className="input-field">
                <label htmlFor="task">Task Title:</label>
                <input type="text" id="task" onChange={this.handleChange}/>
            </div>
            <div className="input-field">
                <button className="btn green lighten-1">Create Task</button>
            </div>
        </form>
      </div>
    )
  } 
}

const mapStateToProps = (state) => {
return {
    auth: state.firebase.auth,
    task: state.task
    }
}

const mapDispatchToProps = dispatch => {
return {
    createTask: (task) => dispatch(createTask(task))
    }
}

export default compose(
connect(mapStateToProps, mapDispatchToProps),
firestoreConnect([
    { collection: 'tasks' }
])
)(CreateTask)
