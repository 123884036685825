import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'
// import google from '../../img/google-dark.png'

const SignUp = (props) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.signUp(state)
  }

  const { auth, authError } = props
  if (auth.uid) return <Redirect to='/' />

  return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <form className="white" onSubmit={handleSubmit}>
              <h5 className="grey-text text-darken-3">Sign Up</h5>
              <div className="input-field">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" onChange={handleChange}/>
              </div>
              <div className="input-field">
                  <label htmlFor="password">Password</label>
                  <input type="password" id="password" onChange={handleChange}/>
              </div>
              <div className="input-field">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" id="firstName" onChange={handleChange}/>
              </div>
              <div className="input-field">
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text" id="lastName" onChange={handleChange}/>
              </div>
              <div className="input-field">
                  <button className="btn green lighten-1">Sign Up</button>
                  <div className="red-text center">
                    { authError ? <p>{ authError }</p> : null }
                  </div>
              </div>
              {/* <div className="input-field">
                  <p className="grey-text"> - OR - </p><br/>
                  <img src={google} alt="" width="200" style={{ cursor: 'pointer'}} onClick={googleSignUp}/>
              </div> */}
            </form>
          </div>
        </div>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
