import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { editThanksMsg } from '../../store/actions/campaignActions';
import { Link } from 'react-router-dom';

class EditThanksMsg extends Component {
  state = {
    msg: ''
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log('Form Submitted');
    this.props.editThanksMsg(this.state.msg, this.props.id);
    this.props.history.push('/campaign-steps/' + this.props.campaign);
  };

  render() {
    const { txMsg } = this.props;
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title" />
            <form onSubmit={this.handleSubmit}>
              {txMsg &&
                txMsg.map(msg => {
                  return (
                    <p style={{ whiteSpace: 'pre-wrap' }}>{msg.thanksMsg}</p>
                  );
                })}
              <div className="form-group">
                <label htmlFor="msg">New Message</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="msg"
                  style={{ height: 'auto' }}
                  onChange={this.handleChange}
                />
              </div>

              <span style={{ fontSize: '12px' }}>
                Merge tags:
                <br />
                #firstName# - First Name
                <br />
                #lastName# - Last Name
                <br />
                #company# - Company Name
                <br />
              </span>
              <br />
              <button className="btn right">Save</button>
              <Link
                className="btn red lighten-1"
                to={`/campaign-steps/${this.props.match.params.campaign}`}>
                Back
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const txMsg = state.firestore.ordered.thanksmsg;
  const campaign = ownProps.match.params.campaign;
  return {
    id,
    txMsg,
    campaign
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editThanksMsg: (msg, id) => dispatch(editThanksMsg(msg, id))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
    { collection: 'thanksmsg', where: ['campaign', '==', props.campaign] }
  ])
)(EditThanksMsg);
