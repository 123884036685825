import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Select } from 'react-materialize';
import MultiSelect from 'react-select';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Button } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const rolesOptions = [
  { key: 1, value: 'admin', label: 'Admin' },
  { key: 2, value: 'agent', label: 'Agent' },
  { key: 3, value: 'customer', label: 'Customer' },
  { key: 4, value: 'agency', label: 'Agency' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    width: '90%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const UserDetail = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [userObject, setUserObject] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [showUserSnack, setShowUserSnack] = useState(false);

  const [myRole, setMyRole] = useState('');
  const [credits, setCredits] = useState('...');
  const [newCredits, setNewCredits] = useState(0);
  const [addCreditModal, setAddCreditModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [creditSnack, setCreditSnack] = useState(false);

  const handleSelectRole = (e) => {
    setUserRole(e.target.value);
    firebase
      .firestore()
      .collection('users')
      .doc(props.match.params.id)
      .update({ role: e.target.value })
      .then(() => {
        console.log('Role Updated');
        setShowUserSnack(true);
      });
  };

  useEffect(() => {
    // Get user Data.
    firebase
      .firestore()
      .collection('users')
      .doc(props.match.params.id)
      .get()
      .then((doc) => {
        console.log(doc);
        if (doc.exists) {
          setUserObject(doc.data());
          setUserRole(doc.data().role);
          setLoading(false);
        } else {
          alert('There is no user with the corresponding ID.');
        }
      });
    // Get my role:

    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        setMyRole(doc.data().role);
      });

    // Get Credits

    firebase
      .firestore()
      .collection('credits')
      .doc(props.match.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCredits(doc.data().credits);
        } else {
          setCredits('User has no credits/Is not subscribed.');
        }
      });
  }, []);

  const closeCreditModal = () => {
    setAddCreditModal(false);
  };

  const openCreditModal = () => {
    setAddCreditModal(true);
    if (typeof credits === 'number') {
      setNewCredits(credits);
    } else {
      setNewCredits(0);
    }
  };

  const saveCredits = () => {
    setSaving(true);

    firebase
      .firestore()
      .collection('credits')
      .doc(props.match.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          firebase
            .firestore()
            .collection('credits')
            .doc(props.match.params.id)
            .update({
              credits: parseInt(newCredits),
            })
            .then(() => {
              setCredits(newCredits);
              setCreditSnack(true);
              setAddCreditModal(false);
            });
        } else {
          firebase
            .firestore()
            .collection('credits')
            .doc(props.match.params.id)
            .set({
              credits: parseInt(newCredits),
            })
            .then(() => {
              setCredits(newCredits);
              setCreditSnack(true);
              setAddCreditModal(false);
            });
        }
      });
  };

  if (!props.auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="container">
      <Paper style={{ marginTop: 30 }} className={classes.paper}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div>
            {/* You're here making this more beautiful */}
            <div className="h2">User Detail:</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderBottom: 'solid',
                borderBottomWidth: 1,
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderBottomColor: '#fafafa',
                flexWrap: 'wrap',
              }}
            >
              <AccountCircleIcon
                style={{
                  color: '#d2d2d2',
                  paddingRight: 10,
                  fontSize: 32,
                }}
              />
              <div className="metric-number">
                Email:{' '}
                <span style={{ fontWeight: 'normal' }}>{userObject.email}</span>
              </div>
            </div>

            {(myRole === 'admin' || myRole === 'developer') && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: 'solid',
                    borderBottomWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    borderBottomColor: '#fafafa',
                    flexWrap: 'wrap',
                  }}
                >
                  <AccountCircleIcon
                    style={{
                      color: '#d2d2d2',
                      paddingRight: 10,
                      fontSize: 32,
                    }}
                  />
                  <div
                    style={{ textTransform: 'capitalize' }}
                    className="metric-number"
                  >
                    Role:
                  </div>
                  {rolesOptions.length > 0 && (
                    <div className="input-field">
                      <Select
                        id="role"
                        onChange={handleSelectRole}
                        value={userRole}
                      >
                        <option value="" default disabled>
                          Change Role
                        </option>
                        {rolesOptions.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: 'solid',
                    borderBottomWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    borderBottomColor: '#fafafa',
                    flexWrap: 'wrap',
                  }}
                >
                  <AccountBoxIcon
                    style={{
                      color: '#d2d2d2',
                      paddingRight: 10,
                      fontSize: 32,
                    }}
                  />
                  <div className="metric-number">
                    Credits:{' '}
                    <span style={{ fontWeight: 'normal' }}>{credits}</span>
                  </div>
                  {/* Add Credits */}
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                    onClick={() => {
                      openCreditModal();
                    }}
                  >
                    <Button onClick={() => {}}>Add Credits</Button>
                  </div>
                </div>
              </div>
            )}
            <Snackbar
              style={{ marginTop: 50 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={showUserSnack}
              autoHideDuration={4000}
              onClose={() => {
                setShowUserSnack(false);
              }}
              message="User Updated"
            />
            <Snackbar
              style={{ marginTop: 50 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={creditSnack}
              autoHideDuration={4000}
              onClose={() => {
                setCreditSnack(false);
              }}
              message="Credit added to user."
            />
            <Dialog
              open={addCreditModal}
              onClose={() => {
                closeCreditModal();
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Insert the new credit balance for the user.
                </DialogContentText>
                <TextField
                  value={newCredits}
                  autoFocus
                  margin="dense"
                  id="newCredits"
                  label="Credits to add"
                  type="number"
                  fullWidth
                  onChange={(e) => {
                    setNewCredits(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={saving}
                  onClick={() => {
                    setAddCreditModal(false);
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={saving}
                  onClick={() => {
                    saveCredits();
                  }}
                  color="primary"
                >
                  {saving ? 'Saving...' : 'Add Credits'}
                </Button>
              </DialogActions>
            </Dialog>

            {/* {props.user.customers &&
            props.user.customers.length > 0 &&
            user.current.role === 'agency' && (
              <div>
              <p>Customers:</p>
              <ol>
              {props.user.customers.map((customer, index) => (
                <li
                key={index}
                style={{
                  padding: '10px',
                }}
                >
                {customer}{' '}
                <span
                style={{ color: 'red', cursor: 'pointer' }}
                id={customer}
                onClick={(e) => updateCustomers(e, 'remove')}
                >
                Remove
                </span>
                </li>
                ))}
                </ol>
                </div>
              )} */}
            {/* {(props.location.state.role === 'admin' ||
            props.location.state.role === 'developer') && (
              <div>
              {user.current.role === 'agency' && (
                <div className="input-field">
                <Select
                id="customers"
                onChange={(e) => updateCustomers(e, 'add')}
                >
                <option value="" default>
                Add Customers
                </option>
                {users.map((user) => (
                  <option key={user.key} value={user.value}>
                  {user.label}
                  </option>
                  ))}
                  </Select>
                  </div>
                  )}
                  </div>
                )} */}
            <div style={{ position: 'fixed', bottom: '10px', left: '30px' }}>
              <Link className="btn red lighten-1" to="/users/">
                {'< Back'}
              </Link>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const users = state.firestore.data.users;
  const user = users ? users[id] : null;
  return {
    users: state.firestore.ordered.users,
    user,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'users' }])
)(UserDetail);
