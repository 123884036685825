import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";

const RecordsPerCompany = (props) => {
  const [value, setValue] = useState(props.value);

  return (
    <div style={{ marginTop: 10 }}>
      <h6 style={{ fontWeight: "bold", marginBottom: 12 }}>
        Records Per Company:
      </h6>
      <Typography style={{ fontSize: 12 }}>
        This does not impact the count shown above.
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            if (value > 0) {
              const newValue = value - 1;
              setValue(newValue);
              props.onChange(newValue);
            } else {
              // Nothing
            }
          }}
          style={{
            margin: "10px 10px 10px 0",
            height: 40,
            width: 40,
            fontWeight: "bold",
            fontSize: 18,
            backgroundColor: "#d2d2d2",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 3,
          }}
        >
          -
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            width: 50,
          }}
        >
          {value === 0 ? "All" : value}
        </div>
        <div
          onClick={() => {
            const newValue = value + 1;
            setValue(newValue);
            props.onChange(newValue);
          }}
          style={{
            margin: 10,
            height: 40,
            width: 40,
            backgroundColor: "#d2d2d2",
            fontWeight: "bold",
            fontSize: 18,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 3,
          }}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default RecordsPerCompany;
