const initState = {
    authError: null
}
const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log('Login Error')
            return {
                ...state,
                authError: 'Login Failed'
            }
        case 'LOGIN_SUCCESS':
            console.log('Login Success')
            return {
                ...state,
                authError: null
            }
        case 'PASSWORD_RESET_ERROR':
            console.log('Password reset Error')
            return {
                ...state,
                authError: 'Password reset Failed'
            }
        case 'PASSWORD_RESET_SUCCESS':
            console.log('Password reset Success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Logged Out')
            return state
        case 'SIGNUP_SUCCESS':
            console.log('signup success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('signup error')
            return {
                ...state,
                authError: action.err.message
            }
        case 'INVITE_SUCCESS':
            console.log('invite success')
            return state
        case 'INVITE_ERROR':
            console.log('invite error', action.err)
            return state
        default:
            return state
    }
}

export default authReducer