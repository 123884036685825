import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Initialize Firebase
var config = {
  apiKey: 'AIzaSyDr8iqMEo4TK5IPYadN39l74n7ugR_Af-0',
  authDomain: 'sales-lead-automation.firebaseapp.com',
  databaseURL: 'https://sales-lead-automation.firebaseio.com',
  projectId: 'sales-lead-automation',
  storageBucket: 'sales-lead-automation.appspot.com',
  messagingSenderId: '213248264142'
};


  firebase.initializeApp(config);
  // firebase.firestore().settings({ timestampsInSnapshots: true })

  export default firebase;