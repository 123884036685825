import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const CompanySizeSelector = (props) => {
  const [CompanySize, setCompanySize] = useState(
    require("../resources/employeeSize.json")
  );

  const handleCompanySizeCheckBoxChange = (event, index) => {
    const tempCompanySize = CompanySize;
    tempCompanySize[index].checked = event.target.checked;
    setCompanySize(tempCompanySize);
    const selectedOptions = tempCompanySize.filter((title) => title.checked);
    props.onChange(selectedOptions);
  };

  return (
    <div>
      <h6 style={{ fontWeight: "bold", marginBottom: 12 }}>
        Select Company Size:
      </h6>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {CompanySize.map((item, index) => (
          <div
            key={String(Math.random())}
            style={{ width: 110, marginRight: 10 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={CompanySize[index].checked}
                  onChange={(e) => handleCompanySizeCheckBoxChange(e, index)}
                  value={item.label}
                  name="CompanySize"
                  color="primary"
                  id="CompanySize"
                />
              }
              label={
                <Typography style={{ fontSize: 12 }}>{item.label}</Typography>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanySizeSelector;
