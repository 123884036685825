import authReducer from './authReducer'
import leadReducer from './leadReducer'
import taskReducer from './taskReducer'
import campaignReducer from './campaignReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    lead: leadReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    task: taskReducer,
    campaign: campaignReducer
})

export default rootReducer