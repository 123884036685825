import React, { Component } from 'react';
import firebase from 'firebase/app';
import ActivityIndicator from 'react-activity-indicator';

export class ViewQueries extends Component {
  state = {
    fullLogsArray: [],
    fullDocsState: [],
    showLoading: true
    // State
  };

  componentDidMount() {
    //Any init functions we want here.

    this.getLogs();
  }

  getLogs() {
    var fullLogsArray = this;
    var fullDocsState = this;
    var showLoading = this;

    firebase
      .firestore()
      .collection('queryLogs')
      .orderBy('sortingTime')
      .limit(25)
      .get()
      .then(function(querySnapshot) {
        var logsArray = [];
        var fullDocs = [];
        querySnapshot.forEach(function(doc) {
          fullDocs.push(doc);
          doc.data().key = doc.id;
          var document = doc.data();
          document.key = doc.id;
          logsArray.push(document);
        });
        showLoading.setState({ showLoading: false });
        console.log(logsArray, 'SA');
        fullDocsState.setState({ fullDocsState: fullDocs });
        fullLogsArray.setState({ fullLogsArray: logsArray });
      });
  }

  getMoreLogs() {
    var currentList = [];
    var shownLogs = this.state.fullLogsArray;
    currentList = this.state.fullDocsState;
    const startingPoint = currentList.length - 1;
    const startingKey = currentList[startingPoint];
    var fullLogsArray = this;
    var showLoading = this;
    showLoading.setState({ showLoading: true });

    firebase
      .firestore()
      .collection('queryLogs')
      .orderBy('sortingTime')
      .limit(25)
      .startAfter(startingKey)
      .get()
      .then(function(querySnapshot) {
        var logsArray = shownLogs;
        querySnapshot.forEach(function(doc) {
          doc.data().key = doc.id;
          var document = doc.data();
          document.key = doc.id;
          logsArray.push(document);
        });
        showLoading.setState({ showLoading: false });
        fullLogsArray.setState({ fullLogsArray: logsArray });
      });
  }

  render() {
    const logs = this.state.fullLogsArray;

    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">View Query Download History</span>
            {logs &&
              logs.map(log => {
                return (
                  <div key={Math.random()}>
                    <p>
                      <b>User: </b>
                      {log.displayName}
                    </p>
                    <p>
                      <b>Time: </b>
                      {log.time}
                    </p>
                    <p>
                      <b>Count: </b>
                      {log.count}
                    </p>
                    <p>
                      <b>Query: </b>
                      {log.query}
                    </p>
                    <br />
                  </div>
                );
              })}
          </div>
          <div className="card-content">
            {this.state.showLoading ? (
              <ActivityIndicator
                number={3}
                diameter={20}
                borderWidth={1}
                duration={200}
                activeColor="#66D9EF"
                borderColor="white"
                borderRadius="50%"
              />
            ) : null}
            <button
              style={{
                backgroundColor: 'transparent',
                borderRadius: 0,
                borderWidth: 0,
                padding: 10
              }}
              onClick={() => {
                this.getMoreLogs();
              }}
            >
              Show More
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewQueries;