import React, { useState } from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { editLiCampaign } from '../../store/actions/campaignActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const NewLinkedInCampaign = props => {
  const { campaigns, campaignId } = props;
  const existingCampaign =
    campaigns &&
    campaigns.filter(campaign => {
      return campaign.id === campaignId;
    })[0];

  const [editedCampaign, setEditedCampaign] = useState({
    campaignId: existingCampaign ? existingCampaign.id : '',
    campaignName: existingCampaign ? existingCampaign.campaignName : '',
    sessionCookie: existingCampaign ? existingCampaign.sessionCookie : '',
    profileUrl: existingCampaign ? existingCampaign.profileUrl : ''
  });

  const handleChange = e => {
    setEditedCampaign({
      ...editedCampaign,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.editLiCampaign(editedCampaign);
    props.history.push('/li-campaign');
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Edit LinkedIn Campaign</span>
          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <label className="active" htmlFor="campaignName">
                Campaign Name
              </label>
              <input
                type="text"
                id="campaignName"
                onChange={handleChange}
                defaultValue={existingCampaign && existingCampaign.campaignName}
              />
            </div>
            <div className="input-field">
              <label className="active" htmlFor="sessionCookie">
                Session Cookie
              </label>
              <input
                type="text"
                id="sessionCookie"
                onChange={handleChange}
                defaultValue={
                  existingCampaign && existingCampaign.sessionCookie
                }
              />
            </div>
            <div className="input-field">
              <label className="active" htmlFor="profileUrl">
                LinkedIn Profile URL
              </label>
              <input
                type="text"
                id="profileUrl"
                onChange={handleChange}
                defaultValue={existingCampaign && existingCampaign.profileUrl}
              />
            </div>
            <div className="input-field">
              <button className="btn right">Submit</button>
              <Link to={'/li-campaign'} className="btn red lighten-1">
                Cancel
              </Link>
            </div>
          </form>
        </div>
        {/* <div className="card-action sla-action">
          <Link className="btn green right" to="/campaign-steps/test">Next </Link>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = ownProps.match.params.id;
  const campaigns = state.firestore.ordered.campaigns;
  return {
    campaignId,
    auth: state.firebase.auth,
    campaigns
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editLiCampaign: campaign => dispatch(editLiCampaign(campaign))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    {
      collection: 'campaigns'
    }
  ])
)(NewLinkedInCampaign);
