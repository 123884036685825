import React, { Component } from 'react';
import firebase from 'firebase/app';
import ReactTable from 'react-table';
import ActivityIndicator from 'react-activity-indicator';
import { Row, Col } from 'react-materialize';
import Select from 'react-select';
import _ from 'lodash';
import 'react-table/react-table.css';
import { FilePicker } from 'react-file-picker';

export class Uploads extends Component {
  state = {
    campaignId: '',
    showLoading: false
  };

  componentDidMount() {
    //Any init functions we want here.
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  confirmUpload() {
    var typeOfFile = this.state.typeOfFile;

    if (this.state.campaignId !== '' || typeOfFile !== 'contacts') {
      var storageRef = firebase.storage().ref(`contactUploads/${typeOfFile}`);

      var uploadProgress = this;
      var fileUploaded = this;
      var fileName = this;
      var fileObject = this;
      var showLoading = this;
      var campaignId = this;

      uploadProgress.setState({
        uploadProgress: 'Current Progress ' + 0 + ' %'
      });
      showLoading.setState({ showLoading: true });

      fileUploaded.setState({ fileUploaded: '' });

      var metadata = {
        customMetadata: {
          campaignId: this.state.campaignId,
          typeOfUpload: typeOfFile
        }
      };

      var uploadTask = storageRef
        .child(this.state.fileName)
        .put(this.state.fileObject, metadata);

      uploadTask.on(
        'state_changed',
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadProgress.setState({
            uploadProgress: 'Current Progress ' + Math.round(progress) + ' %'
          });
        },
        function(error) {
          console.log(error, 'Error');
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            uploadProgress.setState({ uploadProgress: '' });

            //Do the final stuff here..
            fileUploaded.setState({
              fileUploaded:
                'Your file has been uploaded. Please allow a few minutes for your records to show.'
            });
            showLoading.setState({ showLoading: false });
          });
        }
      );
    } else {
      alert('Please add a Campaign ID');
    }
  }

  handleCSVTypeChange = (option, e) => {
    this.setState({
      ...this.state,
      [e.name]: option.value
    });
    setTimeout(() => {
      console.log(this.state.typeOfFile);
    }, 500);

    //So what you need to do is figure out the array of states that are there,
    //and then you need to filter the JSON and only show the cities in states that have been selected.
  };

  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Upload Contacts CSV</span>
            {/* Type of upload */}
            <div style={{ marginBottom: 30, marginTop: 30 }}>
              <Select
                options={[
                  {
                    label: 'New Contacts',
                    value: 'contacts'
                  },
                  {
                    label: 'Contacts that have accepted connection',
                    value: 'connectionAccepted'
                  },
                  {
                    label: 'Contacts that have replied',
                    value: 'replyReceived'
                  }
                ]}
                name="typeOfFile"
                placeholder="Select Type Of CSV Upload"
                onChange={this.handleCSVTypeChange}
              />
            </div>

            {/* Campaign ID */}
            {this.state.typeOfFile && (
              <div>
                <p>Enter the campaign ID:</p>
                <input
                  required
                  type="text"
                  id="campaignId"
                  value={this.state.campaignId}
                  onChange={this.handleChange}
                />
              </div>
            )}
            {/* File upload */}
            {this.state.typeOfFile !== '' && (
              <div>
                {!this.state.fileName && (
                  <div>
                    <FilePicker
                      extensions={['csv']}
                      maxSize={10000000}
                      onChange={FileObject => {
                        this.setState({ fileUploaded: '' });
                        this.setState({
                          fileName: FileObject.name
                        });
                        this.setState({ fileObject: FileObject });
                      }}
                      onError={errMsg => {
                        console.log(errMsg, 'Error');
                        this.setState({ error: errMsg });
                      }}
                    >
                      <button
                        onClick={() => {
                          this.setState({ fileUploaded: '' });
                        }}
                        className="btn green"
                        style={{ marginTop: '15px' }}
                      >
                        Pick CSV
                      </button>
                    </FilePicker>
                    <p
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20
                      }}
                    >
                      {this.state.error}
                    </p>
                  </div>
                )}
                {this.state.fileName && !this.state.showLoading && (
                  <div>
                    <p
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20
                      }}
                    >
                      {this.state.fileName}
                    </p>

                    <button
                      className="btn green"
                      style={{ marginTop: '15px' }}
                      onClick={() => {
                        //Upload the object here.
                        this.confirmUpload();
                      }}
                    >
                      Confirm and Upload
                    </button>
                    <br />
                    <button
                      className="btn red"
                      style={{ marginTop: '15px' }}
                      onClick={() => {
                        this.setState({ fileName: '' });
                        this.setState({ fileObject: [] });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {this.state.showLoading && (
                  <div>
                    <p>{this.state.uploadProgress}</p>
                    <ActivityIndicator
                      number={3}
                      diameter={20}
                      borderWidth={1}
                      duration={200}
                      activeColor="#66D9EF"
                      borderColor="white"
                      borderRadius="50%"
                    />
                  </div>
                )}
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                  {this.state.fileUploaded}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Uploads;
