import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const StateSelector = (props) => {
  const [states, setStates] = useState(require("../resources/us-states.json"));
  const [count, setCount] = useState(0);

  const handleStateCheckBoxChange = (event, index) => {
    setCount(count + 1);
    const tempStates = states;
    tempStates[index].checked = event.target.checked;
    setStates(tempStates);
    const selectedOptions = tempStates.filter((title) => title.checked);
    props.onChange(selectedOptions);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <div>
        <p style={{ color: "#FFFFFF" }}>{count}</p>
      </div>
      {states.map((item, index) => (
        <div
          key={String(Math.random())}
          style={{ width: 250, marginRight: 10 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={states[index].checked}
                onChange={(e) => handleStateCheckBoxChange(e, index)}
                value={item.label}
                name="states"
                color="primary"
                id="states"
              />
            }
            label={item.label}
          />
        </div>
      ))}
    </div>
  );
};

export default StateSelector;
