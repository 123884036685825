import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import AgentReportLine from './AgentReportLine'

export class Reports extends Component {
    render() {
        const {contacts, users} = this.props
        return (

                <div className="card">
                    <div className="card-content">
                        <span className="card-title">
                            Reports
                        </span>
                        <table>
                            <thead>
                                <tr>
                                    <th>Agent</th>
                                    <th>Invites Sent</th>
                                    <th>Invites Accepted</th>
                                    <th>Connection Rate</th>
                                    <th>Thanks Sent</th>
                                    <th>Follow Ups Sent</th>
                                    <th>Replies Received</th>
                                    <th>Reply Rate</th>
                                </tr>
                            </thead>

                            <AgentReportLine contacts={contacts} users={users}/>

                        </table>
                    </div>
                </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const contacts = state.firestore.ordered.contacts
    const users = state.firestore.ordered.users
    return {
        contacts,
        users
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection: 'contacts' },
        { collection: 'users'}

    ])
)(Reports) 

