import "react-table/react-table.css";

import { Col, Row } from "react-materialize";
import React, { useEffect, useState } from "react";

import ActivityIndicator from "react-activity-indicator";
import Button from "@material-ui/core/Button";
import { FilePicker } from "react-file-picker";
import { FlatfileButton } from "@flatfile/react";
import Grid from "@material-ui/core/Grid";
import InfluencerUploads from "./InfluencerUploads";
import Papa from "papaparse";
import Paper from "@material-ui/core/Paper";
import ReactTable from "react-table";
import Select from "react-select";
import UploadContacts from "./uploadContacts";
import _ from "lodash";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    width: "90%",
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
  },
}));

const UserUploads = (props) => {
  const classes = useStyles();

  const schema = [
    { label: "Title", key: "Title" },
    { label: "Email", key: "Email" },
    { label: "ValidationStatusId", key: "ValidationStatusId" },
    { label: "ValidationStatus", key: "ValidationStatus" },
    { label: "EmailDomainGroupId", key: "EmailDomainGroupId" },
    { label: "EmailDomainGroup", key: "EmailDomainGroup" },
    { label: "EOF", key: "EOF" },
    { label: "Industry", key: "Industry" },
    { label: "Keywords", key: "Keywords" },
    { label: "Website", key: "Website" },
    { label: "City", key: "City" },
    { label: "State", key: "State" },
    { label: "Country", key: "Country" },
    { label: "Technologies", key: "Technologies" },
    { label: "First_Name", key: "First_Name" },
    { label: "Last_Name", key: "Last_Name" },
    { label: "Job_Level", key: "Job_Level" },
    { label: "Company_Name", key: "Company_Name" },
    { label: "Email_Status", key: "Email_Status" },
    { label: "First_Phone", key: "First_Phone" },
    { label: "Corporate_Phone", key: "Corporate_Phone" },
    { label: "No_Employees", key: "No_Employees" },
    { label: "Person_Linkedin_Url", key: "Person_Linkedin_Url" },
    { label: "Company_Linkedin_Url", key: "Company_Linkedin_Url" },
    { label: "Facebook_Url", key: "Facebook_Url" },
    { label: "Twitter_Url", key: "Twitter_Url" },
    { label: "Contact_Address_1", key: "Contact_Address_1" },
    { label: "Contact_Address_2", key: "Contact_Address_2" },
    { label: "Contact_Address_3", key: "Contact_Address_3" },
    { label: "Zip_Code", key: "Zip_Code" },
    { label: "Company_Address", key: "Company_Address" },
    { label: "Company_Address_2", key: "Company_Address_2" },
    { label: "Company_City", key: "Company_City" },
    { label: "Company_State", key: "Company_State" },
    { label: "Company_Zip_Code", key: "Company_Zip_Code" },
    { label: "Company_Country", key: "Company_Country" },
    { label: "Company_Phone", key: "Company_Phone" },
    { label: "SIC_Code", key: "SIC_Code" },
    { label: "Naics_Code", key: "Naics_Code" },
    { label: "SEO_Description", key: "SEO_Description" },
    { label: "Annual_Revenue", key: "Annual_Revenue" },
    { label: "Number_of_Retail_Locations", key: "Number_of_Retail_Locations" },
    { label: "row_number", key: "row_number" },
  ];

  const selectedValues = [
    {
      Title: {
        originalValue: "Title",
        matchedValue: "",
      },
      Email: {
        originalValue: "Email",
        matchedValue: "",
      },
      ValidationStatusId: {
        originalValue: "ValidationStatusId",
        matchedValue: "",
      },
      ValidationStatus: {
        originalValue: "ValidationStatus",
        matchedValue: "",
      },
      EmailDomainGroupId: {
        originalValue: "EmailDomainGroupId",
        matchedValue: "",
      },
      EmailDomainGroup: {
        originalValue: "EmailDomainGroup",
        matchedValue: "",
      },
      EOF: {
        originalValue: "EOF",
        matchedValue: "",
      },
      Industry: {
        originalValue: "Industry",
        matchedValue: "",
      },
      Keywords: {
        originalValue: "Keywords",
        matchedValue: "",
      },
      Website: {
        originalValue: "Website",
        matchedValue: "",
      },
      City: {
        originalValue: "City",
        matchedValue: "",
      },
      State: {
        originalValue: "State",
        matchedValue: "",
      },
      Country: {
        originalValue: "Country",
        matchedValue: "",
      },
      Technologies: {
        originalValue: "Technologies",
        matchedValue: "",
      },
      First_Name: {
        originalValue: "First_Name",
        matchedValue: "",
      },
      Last_Name: {
        originalValue: "Last_Name",
        matchedValue: "",
      },
      Job_Level: {
        originalValue: "Job_Level",
        matchedValue: "",
      },
      Company_Name: {
        originalValue: "Company_Name",
        matchedValue: "",
      },
      Email_Status: {
        originalValue: "Email_Status",
        matchedValue: "",
      },
      First_Phone: {
        originalValue: "First_Phone",
        matchedValue: "",
      },
      Corporate_Phone: {
        originalValue: "Corporate_Phone",
        matchedValue: "",
      },
      No_Employees: {
        originalValue: "No_Employees",
        matchedValue: "",
      },
      Person_Linkedin_Url: {
        originalValue: "Person_Linkedin_Url",
        matchedValue: "",
      },
      Company_Linkedin_Url: {
        originalValue: "Company_Linkedin_Url",
        matchedValue: "",
      },
      Facebook_Url: {
        originalValue: "Facebook_Url",
        matchedValue: "",
      },
      Twitter_Url: {
        originalValue: "Twitter_Url",
        matchedValue: "",
      },
      Contact_Address_1: {
        originalValue: "Contact_Address_1",
        matchedValue: "",
      },
      Contact_Address_2: {
        originalValue: "Contact_Address_2",
        matchedValue: "",
      },
      Contact_Address_3: {
        originalValue: "Contact_Address_3",
        matchedValue: "",
      },
      Zip_Code: {
        originalValue: "Zip_Code",
        matchedValue: "",
      },
      Company_Address: {
        originalValue: "Company_Address",
        matchedValue: "",
      },
      Company_Address_2: {
        originalValue: "Company_Address_2",
        matchedValue: "",
      },
      Company_City: {
        originalValue: "Company_City",
        matchedValue: "",
      },
      Company_State: {
        originalValue: "Company_State",
        matchedValue: "",
      },
      Company_Zip_Code: {
        originalValue: "Company_Zip_Code",
        matchedValue: "",
      },
      Company_Country: {
        originalValue: "Company_Country",
        matchedValue: "",
      },
      Company_Phone: {
        originalValue: "Company_Phone",
        matchedValue: "",
      },
      SIC_Code: {
        originalValue: "SIC_Code",
        matchedValue: "",
      },
      Naics_Code: {
        originalValue: "Naics_Code",
        matchedValue: "",
      },
      SEO_Description: {
        originalValue: "SEO_Description",
        matchedValue: "",
      },
      Annual_Revenue: {
        originalValue: "Annual_Revenue",
        matchedValue: "",
      },
      Number_of_Retail_Locations: {
        originalValue: "Number_of_Retail_Locations",
        matchedValue: "",
      },
      row_number: {
        originalValue: "row_number",
        matchedValue: "",
      },
    },
  ];

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      height: 20,
      width: 500,
      padding: 0,
      margin: 0,
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: 3,
      margin: 0,
      height: 45,
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      height: 20,
    }),
    control: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      height: 20,
      width: 500,
    }),
  };

  const [userHeaders, setUserHeaders] = useState([]);
  const [userData, setUserData] = useState([]);
  const [csvLoaded, setCSVLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialColumnState, setInitialColumnState] = useState(
    selectedValues[0]
  );

  useEffect(() => {
    setTimeout(() => {
      console.log(props.profile);
    }, 1000);
    // Init Values Here.
  }, []);

  const confirmAndUpload = () => {
    const keys = Object.keys(initialColumnState);
    // Do you make a new array???

    var arrayToUpload = {};

    keys.forEach((item) => {
      const matchedValue = initialColumnState[item].matchedValue;
      const neededValue = initialColumnState[item].originalValue;

      var count = 0;

      if (matchedValue) {
        userData.forEach((item) => {
          arrayToUpload[count] = {
            ...arrayToUpload[count],
            [neededValue]: item[matchedValue],
          };
          count = count + 1;
        });
      } else {
        userData.forEach((item) => {
          arrayToUpload[count] = {
            ...arrayToUpload[count],
            [neededValue]: "",
          };
          count = count + 1;
        });
        count = count + 1;
      }
    });

    confirmUpload(arrayToUpload);

    // We need to take the original data and map it to the new one...
  };

  const confirmUpload = (arrayToUpload) => {
    setLoading(true);

    // var csv = Papa.unparse(arrayToUpload);

    var csvName =
      "Upload_" +
      [...Array(10)].map((i) => (~~(Math.random() * 36)).toString(36)).join("");

    var storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(
      `/uploadsToTransferToBQTable/${csvName}.json`
    );

    var uploadTask = fileRef.putString(JSON.stringify(arrayToUpload));

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      function (error) {
        console.log(error, "Error");
        setLoading(false);
        alert("There has been an error, please try again.");
        // Handle unsuccessful uploads
      },
      function (res) {
        // Handle successful uploads on complete
        setLoading(false);
        var fromCloudStorageToBQTable = firebase
          .functions()
          .httpsCallable("fromCloudStorageToBQTable");

        fromCloudStorageToBQTable({
          fileName: uploadTask.snapshot.metadata.name,
        })
          .then(function (result) {
            console.log("Called Function", result);
            setLoading(false);

            var mergeAndUpdateTable = firebase
              .functions()
              .httpsCallable("mergeAndUpdateTable");

            mergeAndUpdateTable({})
              .then(function (result) {
                console.log(result);
                setLoading(false);

                alert(
                  "Your file has been uploaded. Please allow a few minutes for your changes to reflect."
                );
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);

                alert("There has been an error.");
              });
          })
          .catch((error) => {
            alert("There has been an error.");
            setLoading(false);
          });
      }
    );
  };

  const upload = () => {
    document.getElementById("inputFile").click();
  };

  const processCSV = (file) => {
    setLoading(true);

    var CSVArray = [];

    const config = {
      download: true,
      header: true,

      error: function (error) {
        console.log(error, "Error");
        alert("There has been an error, please contact support.");
      },
      step: function (row) {
        console.log("Row", row);
        CSVArray.push(row.data);
      },
      complete: function (results, file) {
        var headerArray = [];
        setUserData(CSVArray);
        Object.keys(CSVArray[0]).forEach((item) => {
          headerArray.push({ label: item, value: item });
        });
        setUserHeaders(headerArray);
        setLoading(false);
        setCSVLoaded(true);
      },
    };

    Papa.parse(file, config);
  };

  const matchColumn = (target, input) => {
    setInitialColumnState({
      ...initialColumnState,
      [target]: {
        originalValue: target,
        matchedValue: input,
      },
    });
    console.log(initialColumnState[target]);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Top Card */}
        <div className={classes.root}>
          <Grid container spacing={12}>
            <Paper className={classes.paper}>
              <div className="h2">Upload New Contacts to Database.</div>

              <FlatfileButton
                disableManualInput={true}
                style={{
                  height: 50,
                  paddingLeft: 30,
                  paddingRight: 30,
                  backgroundColor: "#005ebc",
                  color: "#ffffff",
                  fontWeight: "bold",
                  width: 200,
                  marginBottom: 10,
                  marginTop: 10,
                }}
                licenseKey="7dddc4d1-e724-4a7b-bfb5-d40a4c9bfa48"
                customer={{ userId: firebase.auth().currentUser.uid }}
                settings={{
                  type: "Contact",
                  fields: schema,
                  disableManualInput: true,
                }}
                onData={async (results) => {
                  // do something with the results
                  var jsonName =
                    "Upload_" +
                    [...Array(10)]
                      .map((i) => (~~(Math.random() * 36)).toString(36))
                      .join("");

                  var storageRef = firebase.storage().ref();

                  var fileRef = storageRef.child(
                    `/jsonToTransferToBQTable/${jsonName}.json`
                  );

                  var file = results.allData;

                  const ndJson = file.map(JSON.stringify).join("\n");

                  var uploadTask = fileRef.putString(ndJson);

                  uploadTask.on(
                    "state_changed",
                    function (snapshot) {
                      var progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log("Upload is " + progress + "% done");
                    },
                    function (error) {
                      console.log(error, "Error");
                      return "There has been an error, please try again.";
                      // Handle unsuccessful uploads
                    },
                    function (res) {
                      // Handle successful uploads on complete
                      // Handle unsuccessful uploads
                      return "Upload Complete!";
                    }
                  );
                }}
              >
                Import Contacts
              </FlatfileButton>
            </Paper>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(UserUploads);
