export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' })
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err})
        })
    }
}

export const signInWithGoogle = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().useDeviceLanguage();
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithPopup(provider).then((result) => {
            dispatch({ type: 'LOGIN_SUCCESS' })
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err})
        });
    }
}

export const resetPassword = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        const auth = firebase.auth();

        auth.sendPasswordResetEmail(email).then(function() {
            dispatch({ type: 'PASSWORD_RESET_SUCCESS' })
        }).catch(function(err) {
            dispatch({ type: 'PASSWORD_RESET_ERROR', err })
        });
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS' })
        })
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        let role = ''
        const inviteRef = firestore.collection('invites').where('email', '==', newUser.email)
        return inviteRef.get().then((snap) => {
            snap.forEach((doc) => {
                if (doc){
                    let invite = doc.data()
                    console.log(invite)
                    role = invite.role

                } else {
                    role = 'customer'
                    console.log('no dice')
                }
            })
        }).then(() => {
            firebase.auth().createUserWithEmailAndPassword(
                newUser.email,
                newUser.password
            ).then((resp) => {
                return firestore.collection('users').doc(resp.user.uid).set({
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    initials: newUser.firstName[0] + newUser.lastName[0],
                    email:newUser.email.toLowerCase(),
                    role
                })
            }).then(() => {
                dispatch({ type: 'SIGNUP_SUCCESS' })
            }).catch((err) => {
                dispatch({ type: 'SIGNUP_ERROR', err})
            })
        })
    }
}

export const inviteUser = (invite) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('invites').add({
            name:invite.name,
            email:invite.email,
            role:invite.role
        }).then(() => {
            dispatch({ type: 'INVITE_SUCCESS'})
        }).catch((err) => {
            dispatch({ type: 'INVITE_ERROR', err})
        })
    }
}