import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase/app';
import 'firebase/storage';
import { navigatorSearch } from '../../store/actions/campaignActions';

const AddPeople = props => {
  const { campaignId, campaign } = props;
  console.log('props:', props);

  const [navSearchParameters, setNavSearchParameters] = useState({
    campaign: campaignId,
    file: '',
    searchURL: '',
    linkedInSessionCookie: campaign ? campaign.sessionCookie : '',
    phantomUrl: '',
    numberOfProfiles: '',
    searchTitle: ''
  });
  console.log('navSearchParameters:', navSearchParameters);

  const handleUpload = e => {
    const storageRef = firebase
      .storage()
      .ref('campaign-people')
      .child(props.id);

    // Get the file
    var file = e.target.files[0];

    // Upload the file
    var task = storageRef.put(file);

    task.on(
      'state_changed',
      // Update the progress bar
      function progress(snapshot) {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        document.getElementById('progressbar').style.width = percentage + '%';
      },
      function error(err) {
        alert('Upload failed, please try again.');
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then(url => {
          setTimeout(3000);
          props.history.push('/people/' + props.id);
        }); // TODO: Relocate after
      }
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.navigatorSearch(navSearchParameters);
    document.getElementById(
      'alertarea'
    ).innerHTML = `<p style="padding"15px;"><i class="material-icons">info</i><b style="color:green;"> - Import Running, check contacts in a few minutes. You may exit this tab.</b></p>`;
    //props.history.push('/people/'+props.id);
  };

  const handleChange = e => {
    setNavSearchParameters({
      ...navSearchParameters,
      [e.target.id]: e.target.value
    });
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Add People to Campaign:</span>
          <form onSubmit={handleSubmit}>
            {/* Search Title */}
            <label>Give your search a title:</label>
            <div className="input-field">
              <label htmlFor="searchTitle">Search Title</label>
              <input
                type="text"
                id="searchTitle"
                onChange={handleChange}
                required
              />
            </div>
            {/* LinkedIn */}
            <label>Paste In Your LinkedIn Session Cookie:</label>
            <div className="input-field">
              <label className="active" htmlFor="linkedInSessionCookie">
                Session Cookie
              </label>
              <input
                type="text"
                id="linkedInSessionCookie"
                onChange={handleChange}
                defaultValue={navSearchParameters.linkedInSessionCookie}
                required
              />
            </div>
            {/* Phantom Buster */}
            <label>Add your Phantom Buster API URL:</label>
            <div className="input-field">
              <label htmlFor="phantomUrl">Phantom Buster API URL</label>
              <input
                type="url"
                id="phantomUrl"
                onChange={handleChange}
                required
              />
            </div>
            {/* Sales Navigator */}
            <label>Paste a Sales Navigator Search URL:</label>
            <div className="input-field">
              <label htmlFor="searchURL">Navigator URL</label>
              <input
                type="url"
                id="searchURL"
                onChange={handleChange}
                required
              />
            </div>
            {/* Number Of Profiles */}
            <label>How many profiles would you like to retrieve?:</label>
            <div className="input-field">
              <label htmlFor="numberOfProfiles">Enter number</label>
              <input
                type="number"
                id="numberOfProfiles"
                onChange={handleChange}
                required
              />
            </div>
            <div id="alertarea" />
            <button className="btn right" type="submit">
              Save Nav URL
            </button>
            <Link
              className="btn red lighten-1"
              to={`/people/${props.match.params.id}`}>
              Back
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = ownProps.match.params.id;
  const campaigns = state.firestore.ordered.campaigns;
  const campaign =
    campaigns &&
    campaigns.filter(campaign => {
      return campaign.id === campaignId;
    })[0];

  return {
    campaignId,
    campaign
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigatorSearch: navSearchParameters =>
      dispatch(navigatorSearch(navSearchParameters))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    {
      collection: 'campaigns'
    }
  ])
)(AddPeople);
