import React from 'react'
import logo from '../../img/sla-logo.png'
import { Link, NavLink } from 'react-router-dom'

const SignedOutLinks = () => {
  return (
    <ul className="right">
        <li><NavLink to="/signup">Sign Up</NavLink></li>
        <li><NavLink to="/signin">Log In</NavLink></li>
    </ul>
  )
}

export default SignedOutLinks
