import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addThanksMsg } from '../../store/actions/campaignActions';
import { Link } from 'react-router-dom';

class ThanksMsg extends Component {
  state = {
    thanksMsg: ''
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.addThanksMsg(this.props.campaign, this.state);
    this.props.history.push('/campaign-steps/' + this.props.campaign);
  };
  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Add Thank you Message</span>
            <form onSubmit={this.handleSubmit}>
              <div className="input-field">
                <label htmlFor="thanksMsg">
                  Thanks for Connecting Message:
                </label>
                <input
                  type="text"
                  id="thanksMsg"
                  onChange={this.handleChange}
                />
              </div>
              <span style={{ fontSize: '12px' }}>
                Merge tags:
                <br />
                #firstName# - First Name
                <br />
                #lastName# - Last Name
                <br />
                #company# - Company Name
                <br />
              </span>
              <br />
              <button className="btn green right">Save</button>
              <Link
                to={`/campaign-steps/${this.props.match.params.id}`}
                className="btn red lighten-1">
                Cancel
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const campaign = ownProps.match.params.id;
  return {
    campaign
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addThanksMsg: (campaign, msg) => dispatch(addThanksMsg(campaign, msg))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThanksMsg);
