import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'

class SlrMessaging extends Component {
    render() {
        const { campaign } = this.props
        return (
            <div className="container">
                <div className="card">
                    <div className="card-content">
                        <span className="card-title">SLR Campaign Messaging</span>
                        <div className="connect-messages">
                            <h5>Invitation Message:</h5>
                            {/* <ConnectMsg connectmsges={connectmsges} campaign={id}/> */}
                            {/* {inviteBtn} */}
                            <Link to={`/add-slr-connect-msg/${campaign}`}>Add Connect Message</Link>
                        </div>
                        <div className="thank-youmessages">
                            <h5>Thanks for Connecting Message:</h5>
                            {/* <ThanksMsges thanksMsg={thanksMsg} campaign={id}/>
                            
                            {thanksBtn} */}
                        </div>
                        <div className="follow-up-messages">
                            <h5>Follow Up Messages</h5>
                            {/* <SingleStep steps={steps} campaign={id}/>
                            <Link to={`/add-step/${props.id}`} className="btn-floating btn-large waves-effect waves-light blue"><i className="material-icons">add</i> Add</Link> Follow Up Message */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const campaign = ownProps.match.params.id
    return {
        campaign
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        
    ])
)(SlrMessaging)
