import { Link, Redirect } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DescriptionIcon from "@material-ui/icons/Description";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Grid from "@material-ui/core/Grid";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { connect } from "react-redux";
import firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useStripe } from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    flexGrow: 1,
    width: "90%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const Settings = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { auth } = props;
  const [products, setProducts] = useState([]);
  // Credit Card States
  const [subscribed, setSubscribed] = useState(false);
  const [remainingCredits, setRemainingCredits] = useState(null);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [creditError, setCreditError] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [keyLoading, setKeyLoading] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [showCopiedSnack, setShowCopiedSnack] = useState(false);
  const [showDeletedSnack, setShowDeletedSnack] = useState(false);

  const stripe = useStripe();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getStripeProducts = () => {
    firebase
      .firestore()
      .collection("products")
      .where("active", "==", true)
      .get()
      .then((docs) => {
        var productsArray = [];
        console.log(docs.docs.length);
        const length = docs.docs.length;
        docs.docs.forEach((item) => {
          const description = item.data().description;
          const name = item.data().name;
          firebase
            .firestore()
            .collection(`${item.ref.path}/prices`)
            .get()
            .then((price) => {
              var pricingItem = price.docs[0].data();
              pricingItem.name = name;
              pricingItem.description = description;
              pricingItem.priceKey = price.docs[0].id;

              productsArray.push(pricingItem);
              if (productsArray.length === length) {
                console.log(productsArray);
                setLoading(false);
                setProducts(productsArray);
              }
            });
        });
      });
  };

  const getCredits = () => {
    firebase
      .firestore()
      .collection("credits")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        console.log(doc);
        if (doc.exists) {
          setRemainingCredits(doc.data().credits);
        } else {
          setCreditError(true);
          setRemainingCredits(0);
        }
      })
      .catch((err) => [console.log(err)]);
  };

  const getUserProfile = async () => {
    getUserInfo();

    await firebase.auth().currentUser.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    if (decodedToken.claims.stripeRole) {
      getStripeSubscription();
      getCredits();
    } else {
      setSubscribed(false);
      getStripeProducts();
    }
  };

  const getUserInfo = () => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((snap) => {
        console.log(snap.data());
        if (snap.data().apiKey) {
          setApiKey(snap.data().apiKey);
        } else {
          setApiKey(null);
        }
      });
  };

  const issueAPIKey = () => {
    setKeyLoading(true);

    const key = uuidv4();

    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .update({
        apiKey: key,
        timeAPIKeyIssued: moment().format("X"),
        credits: 1000,
      })
      .then(() => {
        setApiKey(key);
        setShowSnack(true);
        setKeyLoading(false);
      });
  };

  const deleteApiKey = () => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .update({
        apiKey: null,
      })
      .then(() => {
        setApiKey(null);
        setShowDeletedSnack(true);
      });
  };

  const getStripeSubscription = () => {
    console.log("Getting Subscription");
    firebase
      .firestore()
      .collection("customers")
      .doc(firebase.auth().currentUser.uid)
      .collection("subscriptions")
      .where("status", "==", "active")
      .onSnapshot(async (snapshot) => {
        console.log(snapshot);
        if (snapshot.empty) {
          setSubscribed(false);
          getStripeProducts();
        } else {
          const doc = snapshot.docs[0];
          setSubscribed(true);
          setLoading(false);
          setCurrentPlan(doc.data());
        }
      });
  };

  const subscribe = async (priceKey) => {
    setLoadingPayment(true);
    var currentUser = firebase.auth().currentUser;

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(currentUser.uid)
      .collection("checkout_sessions")
      .add({
        price: priceKey,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="container">
      {loading ? (
        <div className="loading-controller">
          <CircularProgress style={{ color: "#2196F3" }} />
        </div>
      ) : (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Top Card */}
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div className="h2">Billing</div>
                    {subscribed ? (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderBottom: "solid",
                            borderBottomWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: "#fafafa",
                            flexWrap: "wrap",
                          }}
                        >
                          <CreditCardIcon
                            style={{
                              color: "#d2d2d2",
                              paddingRight: 10,
                              fontSize: 32,
                            }}
                          />
                          <div
                            style={{ textTransform: "capitalize" }}
                            className="metric-number"
                          >
                            Current Plan:{" "}
                            {currentPlan.role &&
                              currentPlan.role.replace(/_/g, " ")}
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "#039be5",
                            }}
                            className="sub-header"
                          >
                            <Button
                              onClick={async () => {
                                setLoadingPayment(true);
                                const functionRef = firebase
                                  .app()
                                  .functions("us-central1")
                                  .httpsCallable(
                                    "ext-firestore-stripe-subscriptions-createPortalLink"
                                  );
                                const { data } = await functionRef({
                                  returnUrl: window.location.origin,
                                });
                                setLoadingPayment(false);
                                window.location.assign(data.url);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              Manage Plan
                            </Button>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderBottom: "solid",
                            borderBottomWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: "#fafafa",
                            flexWrap: "wrap",
                          }}
                        >
                          <CreditCardIcon
                            style={{
                              color: "#d2d2d2",
                              paddingRight: 10,
                              fontSize: 32,
                            }}
                          />
                          <div className="metric-number">
                            Remaining Credits For Billing Period:
                          </div>
                          <div className="metric-number">
                            ({remainingCredits})
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "#039be5",
                            }}
                            className="sub-header"
                          >
                            <Button
                              onClick={() => {
                                alert(
                                  "This function has not been completed yet."
                                );
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              Top Up Credits
                            </Button>
                          </div>
                        </div>
                        {creditError && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderBottom: "solid",
                              borderBottomWidth: 1,
                              borderBottomColor: "#fafafa",
                              flexWrap: "wrap",
                            }}
                          >
                            <p style={{ textAlign: "left" }}>
                              If you have just signed up, please allow a few
                              minutes for your credits to show. Please refresh
                              the page in a few minutes.
                            </p>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderBottom: "solid",
                            borderBottomWidth: 1,
                            alignItems: "center",
                            borderBottomColor: "#fafafa",
                            flexWrap: "wrap",
                          }}
                        >
                          <CreditCardIcon
                            style={{
                              color: "#d2d2d2",
                              paddingRight: 10,
                              fontSize: 32,
                            }}
                          />
                          <div className="metric-number">
                            Used Credits For For Billing Period:
                          </div>
                          <div className="metric-number">(0)</div>
                          <div
                            style={{ textAlign: "right", color: "#039be5" }}
                            className="sub-header"
                          >
                            <Button
                              onClick={() => {
                                alert("This functionality is not built yet.");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              See Full Report
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {products.map((item) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderBottom: "solid",
                              borderBottomWidth: 1,
                              alignItems: "center",
                              borderBottomColor: "#fafafa",
                              flexWrap: "wrap",
                            }}
                          >
                            <CreditCardIcon
                              style={{
                                color: "#d2d2d2",
                                paddingRight: 10,
                                fontSize: 32,
                              }}
                            />
                            <div
                              style={{ textAlign: "left" }}
                              className="metric-number"
                            >
                              {item.name}
                              <br />
                              <span style={{ color: "#909090" }}>
                                {item.description}
                              </span>
                            </div>
                            <div
                              style={{ textAlign: "right", color: "#039be5" }}
                              className="sub-header"
                            >
                              <Button
                                disabled={loadingPayment}
                                onClick={() => {
                                  subscribe(item.priceKey);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                $ {item.unit_amount / 100}/month
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {loadingPayment ? (
                      <div className="loading-controller">
                        <CircularProgress style={{ color: "#2196F3" }} />
                      </div>
                    ) : null}
                    <div className="h2" style={{ marginTop: 20 }}>
                      Integrations
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        borderBottom: "solid",
                        borderBottomWidth: 1,
                        alignItems: "center",
                        borderBottomColor: "#fafafa",
                      }}
                    >
                      <VpnKeyIcon
                        style={{
                          color: "#d2d2d2",
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <div className="metric-number">
                        Sales Lead Automation API Key
                      </div>
                      <div
                        style={{ textAlign: "right", color: "#039be5" }}
                        className="sub-header"
                      >
                        {apiKey ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <p style={{ paddingRight: 20 }}>{apiKey}</p>
                            </div>
                            <Button
                              onClick={() => {
                                var text = apiKey;
                                navigator.clipboard.writeText(text).then(
                                  function () {
                                    setShowCopiedSnack(true);
                                  },
                                  function (err) {
                                    alert("Could not copy the text.");
                                  }
                                );
                              }}
                            >
                              <FileCopyIcon
                                style={{
                                  color: "#d2d2d2",
                                  fontSize: 24,
                                }}
                              />
                            </Button>
                            <Button
                              onClick={() => {
                                deleteApiKey();
                              }}
                            >
                              <DeleteForeverIcon
                                style={{
                                  color: "#d2d2d2",
                                  fontSize: 24,
                                }}
                              />
                            </Button>
                          </div>
                        ) : (
                          <Button
                            onClick={() => {
                              issueAPIKey();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {keyLoading ? "Loading..." : "Get Key"}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        borderBottom: "solid",
                        borderBottomWidth: 1,
                        alignItems: "center",
                        borderBottomColor: "#fafafa",
                      }}
                    >
                      <NotificationImportantIcon
                        style={{
                          color: "#d2d2d2",
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <p>
                        Please generate a new API key if you believe your key
                        was compromised.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        borderBottom: "solid",
                        borderBottomWidth: 1,
                        alignItems: "center",
                        borderBottomColor: "#fafafa",
                      }}
                    >
                      <DescriptionIcon
                        style={{
                          color: "#d2d2d2",
                          paddingRight: 10,
                          fontSize: 32,
                        }}
                      />
                      <a
                        href="./documentation"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        View the documentation.
                      </a>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
          <Snackbar
            style={{ marginTop: 50 }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={showSnack}
            autoHideDuration={4000}
            onClose={() => {
              setShowSnack(false);
            }}
            message="API Key Issued."
          />
          <Snackbar
            style={{ marginTop: 50 }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={showCopiedSnack}
            autoHideDuration={4000}
            onClose={() => {
              setShowCopiedSnack(false);
            }}
            message="API Key Copied to Clipboard."
          />
          <Snackbar
            style={{ marginTop: 50 }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={showDeletedSnack}
            autoHideDuration={4000}
            onClose={() => {
              setShowDeletedSnack(false);
            }}
            message="API Key Deleted."
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    userProfile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Settings);
