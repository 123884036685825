const initState = {
    leads: [
        {id:1, title: 'Bobs burgers', content:'funnyness'},
        {id:2, title: 'Yoshis Adventure', content:'Bargain Egg Hunt'},
        {id:3, title: 'Jurassic Park', content:'Best Movie'}
    ]
}
const leadReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_LEAD':
            console.log('created lead', action.lead)
            return state
        case 'CREATE_LEAD_ERROR':
            console.log('create lead error', action.err)
            return state
        case 'UPDATE_LEAD_STATUS':
            console.log('Updated lead status', action.contact)
            return state
        case 'UPDATE_LEAD_STATUS_ERROR':
            console.log('Update Lead Status Error', action.err)
            return state
        default:
            return state
    }
}

export default leadReducer