import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'

const LeadDetails = (props) => {
  const {lead,auth} = props
  if (!auth.uid) return <Redirect to='/signin' />
  
  if (lead) {
    return (
      <div className="container section lead-details">
          <div className="card">
              <div className="card-content">
                  <span className="card-title">{lead.title}</span>
                  <p>{lead.content}</p>
              </div>
              <div className="card-action grey lighten-4 grey-text">
                  <div>Posted by {lead.authorFirstName} {lead.authorLastName}</div>
                  <div>{moment(lead.createdAt.toDate()).calendar()}</div>
              </div>
          </div>
      </div>
    )
  }
  else {
    return (
      <div className="container center">
        <p>Loading Lead...</p>
      </div>
    )
  }
  
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id
  const leads = state.firestore.data.leads
  const lead = leads ? leads[id] : null
  return {
    lead,
    auth: state.firebase.auth
  }
}
 
export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'leads' }
  ])
)(LeadDetails) 