import React, { useState, useEffect } from 'react';
import { Select } from 'react-materialize';
import MultiSelect from 'react-select';
import { inviteUser } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link, Redirect } from 'react-router-dom';

const InviteUser  = (props) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    firstName: '',
    lastName: '',
    customers: []
  })

  const [users, setUsers] = useState([])

  useEffect(() => {
    const usersArray = []
    props.users && props.users.map(user => {
      usersArray.push({
        key: user.id,
        value: user.email,
        label: `${user.firstName} ${user.lastName}`
      })
    })

    setUsers(usersArray)
  }, [])

  const [isAgency, setIsAgency] = useState(false)

  const handleSelect = e => {
    setState({
      ...state,
      role: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    inviteUser();
    // props.inviteUser(state);
  };

  const inviteUser = () => {
    const addUserAsAdmin = firebase.functions().httpsCallable('addUserAsAdmin');

    const email = state.email.toLowerCase();
    const { role, firstName, lastName, password, customers } = state;

    addUserAsAdmin({
      email,
      role,
      password,
      firstName,
      lastName
    })
      .then(function(result) {

        firebase
          .firestore()
          .collection('invites')
          .doc(result.data.uid)
          .set({
            email,
            role,
            firstName,
            lastName,
            password,
            customers
          })
          .then(() => {
            console.log('Contact added to invites');
          })
          .catch(err => {
            console.log(err, 'Error');
          });

        firebase
          .firestore()
          .collection('users')
          .doc(result.data.uid)
          .set({
            email,
            role,
            firstName,
            lastName,
            customers,
            initials: firstName[0] + lastName[0]
          })
          .then(() => {
            console.log('Contact added to users');
          })
          .catch(err => {
            console.log(err, 'Error');
          });

        alert('User added');
      })
      .then(() => {
        props.history.push('/users');
      })
      .catch(error => {
        alert(error.message);
      });
  }

  const handleChange = e => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    });
  };

  useEffect(() => {
    if (state.role === 'agency') {
      setIsAgency(true)
    } else {
      setIsAgency(false)
      setState({
        ...state,
        customers: []
      })
    }
    return () => {
      setIsAgency(false)
    };
  }, [state.role])

  const handleMultiChange = (options, e) => {
    const customersArray = options.map(option => {
      return option.value
    })
    console.log('option:', customersArray)
    setState({
      ...state,
      [e.name]: customersArray
    });
  };

  if (!props.auth.uid) return <Redirect to="/signin" />;

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Invite User</span>
          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <label htmlFor="firstName">First Name</label>
              <input
                required
                type="text"
                id="firstName"
                onChange={handleChange}
              />
            </div>
            <div className="input-field">
              <label htmlFor="lastName">Last Name</label>
              <input
                required
                type="text"
                id="lastName"
                onChange={handleChange}
              />
            </div>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                required
                type="text"
                id="email"
                onChange={handleChange}
              />
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                required
                type="text"
                id="password"
                onChange={handleChange}
              />
            </div>
            <div className="input-field">
              <Select required id="role" onChange={handleSelect}>
                <option value="" default>
                  Choose Role
                </option>
                <option value="admin">Admin</option>
                <option value="agent">Agent</option>
                <option value="customer">Customer</option>
                <option value="agency">Agency</option>
              </Select>
            </div>

            {
              isAgency &&
              <div className="input-field">
                <MultiSelect
                  options={users}
                  isMulti
                  id="customers"
                  name="customers"
                  placeholder="Choose customers"
                  onChange={handleMultiChange}>
                  </MultiSelect>
              </div>
            }

            <button className="btn" type="submit">
              Submit
            </button>
          </form>
          <div style={{ position: 'fixed', bottom: '10px', left: '30px' }}>
              <Link className="btn red lighten-1" to="/users/">
                {'< Back'}
              </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    inviteUser: invite => dispatch(inviteUser(invite))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: 'users' }])
)(InviteUser);
