export const createLiCampaign = campaign => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to db
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    firestore
      .collection('campaigns')
      .add({
        ...campaign,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId,
        hasContacts: false,
        allContactsInvited: true,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: 'CREATE_CAMPAIGN', campaign });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_CAMPAIGN_ERROR', err });
      });
  };
};

export const editLiCampaign = campaign => {
  console.log('campaign:', campaign);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to db
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const editorId = getState().firebase.auth.uid;
    firestore
      .collection('campaigns')
      .doc(campaign.campaignId)
      .update({
        campaignName: campaign.campaignName,
        sessionCookie: campaign.sessionCookie,
        profileUrl: campaign.profileUrl,
        lastEditedAt: new Date(),
        editorFirstName: profile.firstName,
        editorLastName: profile.lastName,
        editorId
      })
      .then(() => {
        dispatch({ type: 'EDIT_CAMPAIGN', campaign });
      })
      .catch(err => {
        dispatch({ type: 'EDIT_CAMPAIGN_ERROR', err });
      });
  };
};

export const createLiSlrCampaign = campaign => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    firestore
      .collection('slrcampaigns')
      .add({
        ...campaign,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: 'CREATE_SLR_CAMPAIGN', campaign });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_SLR_CAMPAIGN_ERROR', err });
      });
  };
};

export const addReplyMsg = replyMsg => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    firestore
      .collection('replies')
      .add({
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        createdAt: new Date(),
        contact: replyMsg.contact,
        replyMsg: replyMsg.replyMsg,
        replyReceived: 'Yes',
        replySentBy: profile.email
      })
      .then(() => {
        dispatch({ type: 'ADD_REPLY_MSG', replyMsg });
      })
      .catch(err => {
        dispatch({ type: 'ADD_REPLY_ERROR', err });
      });
  };
};

export const addStep = campaign => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    firestore
      .collection('steps')
      .add({
        ...campaign,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId,
        createdAt: new Date()
      })
      .then(doc => {
        dispatch({ type: 'ADDED_STEP', campaign });
      })
      .catch(err => {
        dispatch({ type: 'ADD_STEP_ERROR', err });
      });
  };
};

export const editFollowUp = (msg, id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('steps')
      .doc(id)
      .update({
        msg
      })
      .then(() => {
        dispatch({ type: 'STEP_MSG_EDIT', msg });
      })
      .catch(err => {
        dispatch({ type: 'STEP_MSG_EDIT_ERROR', err });
      });
  };
};

export const addConnectMsg = msg => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.id;
    firestore
      .collection('connectmsges')
      .add({
        ...msg,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: 'ADDED_CONNECT_MSG', msg });
      })
      .catch(err => {
        dispatch({ type: 'CONNECT_MSG_ERROR', err });
      });
  };
};

export const addSlrConnectMsg = msg => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.id;
    firestore
      .collection('slrconnectmsg')
      .add({
        ...msg,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: 'ADD_SLR_CONNECT_MSG', msg });
      })
      .catch(err => {
        dispatch({ type: 'ADD_SLR_CONNECT_MSG_ERROR', err });
      });
  };
};

export const editConnectMsg = (msg, id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const profile = getState().firebase.profile;

    firestore
      .collection('connectmsges')
      .doc(id)
      .update({
        connectmsg: msg
      })
      .then(() => {
        dispatch({ type: 'CONNECT_MSG_EDIT', msg });
      })
      .catch(err => {
        dispatch({ type: 'CONNECT_MSG_EDIT_ERROR', err });
      });
  };
};

export const connectionDidAccept = contact => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('contacts')
      .doc(contact)
      .update({
        didAcceptConnection: 'Yes'
      })
      .then(() => {
        dispatch({ type: 'CONNECTION_ACCEPTED', contact });
      })
      .catch(err => {
        dispatch({ type: 'CONNECTED_ACCEPTED_ERROR', err });
      });
  };
};

export const addThanksMsg = (campaign, msg) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('thanksmsg')
      .add({
        ...msg,
        campaign
      })
      .then(() => {
        dispatch({ type: 'THANKS_MSG_ADDED', msg });
      })
      .catch(err => {
        dispatch({ type: 'THANKS_MSG_ADDED_ERROR', err });
      });
  };
};

export const editThanksMsg = (msg, id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('thanksmsg')
      .doc(id)
      .update({
        thanksMsg: msg
      })
      .then(() => {
        dispatch({ type: 'THANKS_MSG_EDIT', msg });
      })
      .catch(err => {
        dispatch({ type: 'THANKS_MSG_EDIT_ERROR', err });
      });
  };
};

export const navigatorSearch = ({
  searchURL,
  campaign,
  linkedInSessionCookie,
  phantomUrl,
  numberOfProfiles,
  searchTitle
}) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('navigatorsearch')
      .add({
        searchURL,
        campaign,
        linkedInSessionCookie,
        phantomUrl,
        numberOfProfiles,
        searchTitle,
        createdOn: new Date()
      })
      .then(doc => {
        console.log(doc, 'Doc');
        dispatch({ type: 'NAV_SEARCH_ADDED', searchURL });
      })
      .catch(err => {
        dispatch({ type: 'NAV_SEARCH_ADDED_ERROR', err });
      });
  };
};
