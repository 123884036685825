import React from 'react';
import { Link } from 'react-router-dom';
import linkedinlogo from '../../img/linkedinlogo.png';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal } from 'react-materialize';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connectionDidAccept } from '../../store/actions/campaignActions';
import firebase from 'firebase/app';
import moment from 'moment';

const Contact = ({ contact, invitation, thanksmsg, props, user }) => {
  // String Replacement for Merge Tags
  let mapObj = {
    '#firstName#': contact.firstName,
    '#lastName#': contact.lastName,
    '#company#': contact.company,
    '#industry#': contact.industry
  };
  let newCon = invitation.replace(
    /#firstName#|#lastName#|#company#|#industry#/gi,
    function(matched) {
      return mapObj[matched];
    }
  ); // This is the modified invitation message with the persons actual name
  if (newCon === '') {
    newCon = 'No Message Set';
  }

  let newTx = thanksmsg.replace(
    /#firstName#|#lastName#|#company#|#industry#/gi,
    function(matched) {
      return mapObj[matched];
    }
  ); // This is the modified invitation message with the persons actual name
  if (newTx === '') {
    newTx = 'No Message Set';
  }

  function handleCopy() {
    // console.log('Copied!')
  }

  function invitedContact(contact) {
    firebase
      .firestore()
      .collection('contacts')
      .doc(contact)
      .update({
        connectSent: 'Yes',
        invitationDate: new Date(),
        connectSentBy: user,
        step: 2
      })
      .then(() => {
        console.log('Success marking invite as done ');
      })
      .catch(err => {
        console.log('Error', err);
      });
  }

  // TODO: The below firebase call actually exists (and needs to be updated) in the campaignActions redux store, but this whole thing first needs to be changed to a class based component also I DONT HAVE TIME :D
  function handleComplete(contact, campaign) {
    let newDate = new Date();
    const stepsDbRef = firebase
      .firestore()
      .collection('steps')
      .where('campaign', '==', campaign)
      .orderBy('createdAt', 'asc')
      .limit(1);
    stepsDbRef
      .get()
      .then(snap => {
        if (snap.empty) {
          console.log('No Such Doc with that campaign');
          return;
        }
        snap.forEach(doc => {
          let step = doc.data();
          let nextDay = parseInt(step.days, 10);
          let date = new Date();
          newDate = date.setDate(date.getDate() + nextDay);
        });
      })
      .then(() => {
        firebase
          .firestore()
          .collection('contacts')
          .doc(contact)
          .update({
            thankYouSent: 'Yes',
            followUpStep: 1,
            nextStepDate: newDate,
            thankYouSentDate: new Date(),
            thankYouSentBy: user,
            sendThankYouMsgOn: null,
            followUpMsgs: null,
            step: 4
          })
          .then(() => {
            console.log(
              'Success added a new next step date with ',
              moment(newDate).format()
            );
          })
          .catch(err => {
            console.log('Error', err);
          });
      });
  }

  function handleAccept(contact) {
    // TODO: THE BELOW SHOULD ALSO BE HANDLED BY ACITONS AND REDUCERS!
    //console.log(connectionDidAccept)
    //connectionDidAccept(contact)
    firebase
      .firestore()
      .collection('contacts')
      .doc(contact)
      .update({
        didAcceptConnection: 'Yes',
        didAcceptConnectionBy: user,
        connectionDate: new Date()
      })
      .then(() => {
        console.log('Updated Contact');
      })
      .catch(err => {
        console.log('Error: ', err);
      });
  }

  // Check if connect is completed
  let connectBtn = '';
  if (contact.connectSent === 'Yes' || contact.didAcceptConnection === 'Yes') {
    connectBtn = (
      <span style={{ color: 'green' }}>
        <i className="material-icons">check_circle</i> Invite Sent
      </span>
    );
  } else if (contact.didAcceptConnection != 'Yes') {
    connectBtn = <a className="btn">Connect</a>;
  }

  let thanksBtn = '';
  if (contact.didAcceptConnection === 'Yes' && contact.thankYouSent !== 'Yes') {
    thanksBtn = (
      <Modal
        header="Send thanks for Connecting Message"
        trigger={<button className="btn">Thanks Msg</button>}>
        <p>{newTx}</p>
        <CopyToClipboard text={newTx} onCopy={handleCopy}>
          <a className="btn waves-effect waves-light" id="copybtn">
            Copy
          </a>
        </CopyToClipboard>
        <br />
        <br />
        <a className="btn" href={contact.liProfile} target="_blank">
          Go To Profile
        </a>
        <br />
        <br />
        <a
          className="btn waves-effect waves-light"
          onClick={() => handleComplete(contact.id, contact.campaign)}>
          Mark as Completed
        </a>
      </Modal>
    );
  } else if (contact.thankYouSent == 'Yes') {
    thanksBtn = (
      <span style={{ color: 'green' }}>
        <i className="material-icons">check_circle</i> Thank You Sent
      </span>
    );
  } else {
    thanksBtn = (
      <span>
        <i className="material-icons">info</i> - Not Connected
      </span>
    );
  }

  let followUpBtn = '';
  if (contact.didAcceptConnection == 'Yes') {
    followUpBtn = (
      <Link className="btn" to={`/followup/${contact.id}/${contact.campaign}`}>
        Follow Up
      </Link>
    );
  } else {
    followUpBtn = (
      <span>
        <i className="material-icons">info</i> - Not Connected
      </span>
    );
  }

  let replyBtn = '';
  if (contact.replyReceived == 'Yes') {
    replyBtn = (
      <span style={{ color: 'green' }}>
        <i className="material-icons">check_circle</i> Reply <br />
        Received
      </span>
    );
  } else {
    replyBtn = (
      <Link className="btn" to={`/add-reply/${contact.id}`}>
        Reply
      </Link>
    );
  }

  let acceptBtn = '';
  if (contact.didAcceptConnection != 'Yes') {
    acceptBtn = (
      <button
        className="btn"
        onClick={() => {
          handleAccept(contact.id);
        }}>
        <i className="material-icons">check_box_outline_blank</i>
      </button>
    );
  } else {
    acceptBtn = <i className="material-icons green-text">check_box</i>;
  }

  return (
    <tr>
      <td>{contact.firstName}</td>
      <td>{contact.lastName}</td>

      <td>
        <a href={contact.liProfile} target="_blank">
          <img
            src={linkedinlogo}
            alt="Linked In Profile"
            width="40"
            target="_blank"
          />
        </a>
      </td>
      <td>LI Reply</td>
      <td>
        <Modal header="Send Connection Request" trigger={connectBtn}>
          <p>{newCon}</p>
          <CopyToClipboard text={newCon} onCopy={handleCopy}>
            <a className="btn waves-effect waves-light" id="copybtn">
              Copy
            </a>
          </CopyToClipboard>
          <br />
          <br />
          <a className="btn" href={contact.liProfile} target="_blank">
            Go To Profile
          </a>
          <br />
          <br />
          <a
            className="btn waves-effect waves-light"
            onClick={() => invitedContact(contact.id)}>
            Mark as Completed
          </a>
        </Modal>
      </td>
      <td>{acceptBtn}</td>
      <td>{thanksBtn}</td>
      <td>{followUpBtn}</td>
      <td>{replyBtn}</td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  // const connectmsges = state.firestore.ordered.connectmsges
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    connectionDidAccept: contact => dispatch(connectionDidAccept(contact))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Contact);
