import React from 'react';
import { Link } from 'react-router-dom';

const User = ({ user, role }) => {
  return (
    <tr>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td style={{ textTransform: 'capitalize' }}>{user.role}</td>
      <td>
        <Link
          to={{
            pathname: `/userdetail/${user.id}`,
            state: {
              role,
            },
          }}
        >
          <button className="btn">View / Edit</button>
        </Link>
      </td>
    </tr>
  );
};

export default User;
