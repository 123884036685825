import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { addReplyMsg } from '../../store/actions/campaignActions';
import moment from 'moment';

class AddReply extends Component {
  state = {
    profile: '',
    campaign: '',
    replyMsg: '',
    contact: this.props.id
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.addReplyMsg(this.state);
    this.props.history.push('/campaigns/');
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  render() {
    const { replies } = this.props;
    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <span className="card-title">Add Reply</span>
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="replyMsg">Reply URL</label>
              <input type="url" id="replyMsg" onChange={this.handleChange} />
              <button className="btn" type="submit">
                Save
              </button>
            </form>
            <h5>Reply history</h5>
            {replies &&
              replies.map(reply => {
                return (
                  <div className="card" key={reply.id}>
                    <div className="card-content">
                      {moment(reply.createdAt.toDate()).calendar()}
                      <br />
                      Logged By: {reply.authorFirstName}
                      <br />
                      <a className="btn" href={reply.replyMsg}>
                        View Thread
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // const connectmsges = state.firestore.ordered.connectmsges
  const id = ownProps.match.params.id;
  const replies = state.firestore.ordered.replies;
  return {
    id,
    replies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addReplyMsg: replyMsg => dispatch(addReplyMsg(replyMsg))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
    { collection: 'replies', where: ['contact', '==', props.id] }
  ])
)(AddReply);
